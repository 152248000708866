import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Tag } from './Tag';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getApiURL, getInvitationStyles, getRoleNameById } from 'utils';
import { InvitationStatus, OrgInvitation } from 'types/invitationType';

interface InviteeRowProps {
  data: OrgInvitation;
}

export const InviteeRow = ({ data }: InviteeRowProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const onDeleteInvitation = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Organization/DeleteOrganizationInvitationRequest/${data.id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Box sx={{ background: 'white', borderRadius: '20px' }} p={2} mb={2}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Typography>
            {data.firstName} {data.lastName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{data.email}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{getRoleNameById(data.roleId)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Tag
            label={InvitationStatus[data.status]}
            color={getInvitationStyles(data.status)?.color}
            background={getInvitationStyles(data.status)?.background}
          />
          {/* <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
            disableRipple
          >
            <EditOutlinedIcon
              sx={{
                padding: '10px',
                borderRadius: '50px',
                color: '#388e3c',
                background: '#e8f5e9',
              }}
            />
          </IconButton> */}
          {/* <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
            disableRipple
          >
            <DeleteOutlineOutlinedIcon
              sx={{
                padding: '10px',
                borderRadius: '50px',
                color: '#d32f2f',
                background: '#ffebee',
              }}
            />
          </IconButton> */}
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs={1}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDeleteInvitation();
            }}
            disableRipple
          >
            <DeleteOutlineOutlinedIcon sx={{ color: '#d32f2f' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
