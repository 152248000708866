import { useContext } from 'react';
import { SnackbarContext, SnackbarContextActions } from '.';

export const useSnackbar = (): SnackbarContextActions => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};
