import useFileUploader from 'react-use-file-uploader';
import { useState, useEffect } from 'react';

export const useUploadFiles = () => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const { open, files, isLoading, formData, clear } = useFileUploader({
    accept: 'document',
    multiple: true,
  });

  useEffect(() => {
    if (files.length > 0) {
      setFilesToUpload([...filesToUpload, ...files.map((file) => file.file)]);
    }
  }, [files]);

  const removeFile = (index: number) => {
    setFilesToUpload(filesToUpload.filter((file, i) => i !== index));
  }

  const handleClear = () => {
    setFilesToUpload([]);
    clear();
  }


  return { selectFile: open, files: filesToUpload, removeFile, isLoading, formData, clear: handleClear };
};