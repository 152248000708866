import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DatePicker, TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { getUserProfileSelector, profileRequestData } from 'state/profile/profileSlice';
import { showContactEditDialog, toggleContactEditDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { UserProfile } from 'types/profileType';
import { getApiURL } from 'utils';
import { useEffect } from 'react';

export const ContactEditDialog = () => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const userProfile = getUserProfileSelector();
  const open = showContactEditDialog();
  const onClose = () => dispatch(toggleContactEditDialog(false));

  const { handleSubmit, control, reset, setValue } = useForm<UserProfile>({
    defaultValues: {
      title: '',
      firstName: '',
      lastName: '',
      birthdate: null,
      phoneNumber: '',
      email: '',
      location: '',
      linkedin: '',
      facebook: '',
      twitter: '',
      instagram: '',
    },
  });

  useEffect(() => {
    if (userProfile) {
      setValue('title', userProfile?.title);
      setValue('firstName', userProfile?.firstName);
      setValue('lastName', userProfile?.lastName);
      setValue('birthdate', userProfile?.birthdate ? moment(userProfile?.birthdate) : null);
      setValue('phoneNumber', userProfile?.phoneNumber);
      setValue('email', userProfile?.email);
      setValue('location', userProfile?.location);
      setValue('linkedin', userProfile?.linkedin);
      setValue('facebook', userProfile?.facebook);
      setValue('twitter', userProfile?.twitter);
      setValue('instagram', userProfile?.instagram);
    }
  }, [open, userProfile]);

  const updateDescription = async (data: UserProfile) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/UserProfile/UpdateUserProfileRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      user &&
        getAccessTokenSilently().then((token) => dispatch(profileRequestData({ userId: user.sub as string, token })));
      onClose();
    }
  };

  const onSubmit = handleSubmit((data: UserProfile) => {
    updateDescription(data);
    reset();
    onClose();
  });

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} maxWidth="md" fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {t('profile.info.edit')}
          <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField name="title" label={t('profile.jobTitle')} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="firstName" label={t('profile.firstName')} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                name="birthdate"
                label={t('profile.birthdate')}
                control={control}
                views={['year', 'month', 'day']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="lastName" label={t('profile.lastName')} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="email" label={t('email')} control={control} disabled />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="phoneNumber" label={t('phone')} control={control} maxLength={10} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="location" label={t('location')} control={control} />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <TextField
                name="linkedin"
                label="Linkedin"
                control={control}
                startAdornment="https://www.linkedin.com/"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="facebook"
                label="Facebook"
                control={control}
                startAdornment="https://www.facebook.com/"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="twitter" label="Twitter" control={control} startAdornment="https://www.twitter.com/" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="instagram"
                label="Instagram"
                control={control}
                startAdornment="https://www.instagram.com/"
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="outlined" onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button type="submit">{t('button.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
