import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Checkbox, DatePicker, TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { profileRequestData } from 'state/profile/profileSlice';
import { showExperienceEditDialog, toggleExperienceEditDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { Experience } from 'types/profileType';
import { useEffect } from 'react';

interface ExperienceEditDialogProps {
  experience?: Experience;
  setExperience?: (exp: Experience) => void;
}

export const ExperienceEditDialog = ({ experience, setExperience }: ExperienceEditDialogProps) => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showExperienceEditDialog();
  const onClose = () => {
    setExperience && setExperience({});
    dispatch(toggleExperienceEditDialog(false));
  };

  const { handleSubmit, control, reset, setValue } = useForm<Experience>({
    defaultValues: {
      dateStart: null,
      dateEnd: null,
      isPresent: false,
      companyName: '',
      role: '',
      taskDescription: '',
      location: '',
    },
  });

  useEffect(() => {
    if (experience) {
      setValue('id', experience.id);
      setValue('dateStart', experience.dateStart ? moment(experience.dateStart) : null);
      setValue('dateEnd', experience.dateEnd ? moment(experience.dateEnd) : null);
      setValue('isPresent', experience.isPresent);
      setValue('companyName', experience.companyName);
      setValue('role', experience.role);
      setValue('taskDescription', experience.taskDescription);
      setValue('location', experience.location);
    }
  }, [open]);

  const postData = async (data: Experience) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(
        `/UserProfile/${experience?.id ? `UpdateUserExperienceRequest/${experience?.id}` : 'AddUserExperienceRequest'}`
      ),
      {
        method: experience?.id ? 'PATCH' : 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      user &&
        getAccessTokenSilently().then((token) => dispatch(profileRequestData({ userId: user.sub as string, token })));
    }
  };

  const onSubmit = handleSubmit((exp: Experience) => {
    postData(exp);
    reset();
    onClose();
  });

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {experience?.id ? t('profile.experience.edit') : t('profile.experience.add')}
          <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <DatePicker
                name="dateStart"
                control={control}
                label={t('profile.experience.startDate')}
                views={['month', 'year']}
                format="MM/YYYY"
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                name="dateEnd"
                control={control}
                label={t('profile.experience.endDate')}
                views={['month', 'year']}
                format="MM/YYYY"
              />
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Checkbox sx={{ mt: '20px' }} name="isPresent" control={control} label={t('present')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="companyName"
                control={control}
                label={t('profile.experience.company')}
                placeholder="Enter a company"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="role"
                control={control}
                label={t('profile.experience.role')}
                placeholder="Enter a role"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="taskDescription"
                control={control}
                label={t('profile.experience.task')}
                placeholder="Enter task description"
                multiline
                minRows={2}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="location"
                control={control}
                label={t('profile.experience.location')}
                placeholder={t('placeholder.location')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="outlined" onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button type="submit">{t('button.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
