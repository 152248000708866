import { useEffect, useState } from 'react';
import { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IStripeElementProviderProps } from './types';
import { LoadingAnimation } from 'components/Animation';
import { updateDonatePaymentIntent } from 'state/donation/donationSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from 'state/hooks';
import { getApiURL } from 'utils';

const stripePromise = loadStripe(window._env_.STRIPE_PUBLISHABLE_KEY);

export const StripeElementProvider: FC<IStripeElementProviderProps> = ({ children, paymentTypeId, email }) => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);

  useEffect(() => {
    if (email && !paymentIntentId && !clientSecret) {
      // Create PaymentIntent as soon as the page loads
      getAccessTokenSilently().then((token) => {
        fetch(
          // Test: 'http://localhost:4000/stripe/create-intent',
          getApiURL(`/service/stripe/create-intent`),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            body: JSON.stringify({ paymentTypeId, email }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.client_secret);
            setPaymentIntentId(data.paymentIntentId);
          });
      });
    }
  }, [email, paymentIntentId, clientSecret]);

  useEffect(() => {
    if (paymentIntentId && paymentTypeId) {
      getAccessTokenSilently().then((token) => {
        dispatch(updateDonatePaymentIntent({ paymentIntentId, paymentTypeId, token }));
      });
    }
  }, [paymentTypeId, paymentIntentId]);

  if (!clientSecret) {
    return <LoadingAnimation />;
  }

  const options = {
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};
