import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getApiURL } from 'utils';
import { debounce } from 'lodash';

interface LocationAutocompleteProps {
  value: string;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: string | null) => void;
  inputValue: string;
  onInputChange: (event: React.SyntheticEvent<Element, Event>, value: string) => void;
  label?: string;
  marginBottom?: number;
}

export const LocationAutocomplete = ({
  value,
  onChange,
  inputValue,
  onInputChange,
  label,
  marginBottom,
}: LocationAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<any[]>([]);

  const fetchData = async (location: string) => {
    if (!location) return;

    const API_ENDPOINT = getApiURL(`/AutoComplete/AutoCompleteLocationRequest?PartialLocation=${location}`);

    const response = await axios.get(API_ENDPOINT);
    const options = response.data.autoCompleteLocationsResponse.map((option: any) => option);
    setOptions(options);
  };

  const debouncedFetch = useRef(debounce(fetchData, 500)).current;

  useEffect(() => {
    debouncedFetch(inputValue);

    return () => {
      debouncedFetch.cancel();
    };
  }, [inputValue, debouncedFetch]);

  return (
    <Box component="div" mb={marginBottom ? marginBottom : 0} width="100%">
      {label && <Typography mb="5px">{label}</Typography>}
      <Autocomplete
        options={options}
        value={value}
        onChange={(event, newValue) => {
          onChange(event, newValue ? `${newValue.mainText}, ${newValue.secondaryText}` : null);
        }}
        inputValue={inputValue}
        onInputChange={onInputChange}
        fullWidth
        freeSolo
        getOptionLabel={(option: any) =>
          typeof option === 'string' ? option : `${option.mainText}, ${option.secondaryText}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnOutlinedIcon color="primary" />
                </InputAdornment>
              ),
            }}
            placeholder={t('placeholder.location')}
            fullWidth
          />
        )}
        renderOption={(props, option: any) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon color="primary" />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {option.mainText}
                  </Box>
                  <Typography variant="body2">{option.secondaryText}</Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Box>
  );
};
