import { Button } from '@mui/material';
import { useAppDispatch } from 'state/hooks';
import { toggleResumeReuploadDialog } from 'state/dialogs/dialogsSlice';
import { ResumeReuploadDialog } from 'components/Dialog/ProfileReuploadDialog/ResumeReuploadDialog';

export const ResetProfile = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Button variant="outlined" onClick={() => dispatch(toggleResumeReuploadDialog(true))} fullWidth>
        Update your profile using your CV
      </Button>
      <ResumeReuploadDialog />
    </>
  );
};
