import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { theme } from 'theme';

type ResponsiveType = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

interface SectionProps {
  sx?: object;
  title?: string;
  bigTitle?: boolean;
  children?: React.ReactNode;
  marginBottom?: number;
  flexDirection?: any;
  justifyContent?: any;
  alignItems?: any;
  gap?: number;
  borderRadius?: string;
  background?: string;
  px?: number | ResponsiveType;
  py?: number | ResponsiveType;
  noPadding?: boolean;
  noBorder?: boolean;
  onClick?: () => void;
  editable?: boolean;
  edit?: boolean;
  onEdit?: () => void;
  onAdd?: () => void;
  onMouseLeave?: () => void;
}

export const Section = ({
  sx,
  title,
  bigTitle = false,
  children,
  flexDirection = 'column',
  justifyContent = 'flex-start ',
  alignItems = 'stretch',
  gap = 0,
  borderRadius = '20px',
  background = 'white',
  marginBottom = 0,
  px = 3,
  py = 2,
  noPadding = false,
  noBorder = false,
  onClick,
  editable = false,
  edit = false,
  onEdit,
  onAdd,
  onMouseLeave,
}: SectionProps) => {
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        background,
        borderRadius,
        mb: marginBottom,
        px: noPadding ? 0 : px,
        py: noPadding ? 0 : py,
        boxShadow: noBorder ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)',
        cursor: onClick ? 'pointer' : 'default',
        border: noBorder ? 'none' : `1px solid ${theme.palette.grey[400]}`,
        '&:hover': {
          border: onClick
            ? `1px solid ${theme.palette.primary.main}`
            : noBorder
            ? 'none'
            : `1px solid ${theme.palette.grey[400]}`,
          boxShadow: onClick ? '0 2px 4px rgba(48, 152, 153, 0.1)' : noBorder ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
      }}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      {title && (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography fontSize={bigTitle ? 24 : 18} fontWeight={600}>
            {title}
          </Typography>
          {editable && (
            <Stack flexDirection="row">
              {onAdd && (
                <IconButton sx={{ bottom: '4px', left: '4px', alignSelf: 'center' }} color="primary" onClick={onAdd}>
                  <AddOutlinedIcon />
                </IconButton>
              )}
              {onEdit && (
                <IconButton sx={{ bottom: '4px', left: '4px' }} color="primary" onClick={onEdit}>
                  {edit ? <EditOffOutlinedIcon /> : <EditOutlinedIcon />}
                </IconButton>
              )}
            </Stack>
          )}
        </Stack>
      )}
      <Box sx={{ display: 'flex', justifyContent, flexDirection, alignItems, gap }}>{children}</Box>
    </Box>
  );
};
