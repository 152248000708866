import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';
import { Conversation, Message } from 'types/chatTypes';
import {
  GET_CONVERSATIONS,
  GET_CONVERSATION_MESSAGES,
  GET_CONVERSATION_NEXT_PAGE_MESSAGES,
  CREATE_CONVERSATION,
  SEND_MESSAGE,
  UPDATE_CONVERSATION,
  RECEIVE_MESSAGE,
  LOOK_FOR_CONVERSATION_BY_RECIPIENT_ID,
  READ_MESSAGES,
  DOWNLOAD_FILE,
  DELETE_FILE_MESSAGE,
  DELETE_MESSAGE
} from './actionTypes';
import { UserProfile } from 'types/profileType';
import { isEmpty } from 'lodash';
import { shallowEqual } from 'react-redux';
// Thunks - async actions
export const getConversationsData = createAction(GET_CONVERSATIONS, (payload: { userId: string, token: string }) => {
  return {
    payload,
  };
});


export const getConversationNextPageMessagesData = createAction(GET_CONVERSATION_NEXT_PAGE_MESSAGES, (payload: { conversationId: string, nextToken: string }) => {
  return {
    payload,
  };
});

export const creatConversation = createAction(CREATE_CONVERSATION, (payload: { userIds: string[], token: string }) => {
  return {
    payload,
  };
});

export const updateConversation = createAction(UPDATE_CONVERSATION, (payload: { conversationId: string, latestMessage: string }) => {
  return {
    payload,
  };
});

export const deleteMessage = createAction(DELETE_MESSAGE, (payload: { messageId: string, createdAt: string }) => {
  return {
    payload,
  };
});

export const getConversationMessagesData = createAction(
  GET_CONVERSATION_MESSAGES,
  (payload: { conversationId: string, limit?: number }) => {
    return {
      payload,
    };
  }
);

export const sendMessage = createAction(
  SEND_MESSAGE,
  (payload: {
    messagePayload: {
      conversationId: string,
      senderId: string,
      recipientId: string,
      content: string,
      messageType: string,
    },
    files: File[],
    token: string,
    recipientEmail: string,
  }) => {
    return {
      payload,
    };
  }
);

export const receiveMessage = createAction(
  RECEIVE_MESSAGE,
  (payload: {
    message: Message,
    token: string,
    actionType: "create" | "update" | "delete"
  }) => {
    return {
      payload,
    };
  }
);

export const deleteFileMessage = createAction(
  DELETE_FILE_MESSAGE,
  (payload: {
    messageId: string,
    createdAt: string,
    token: string,
    key: string,
  }) => {
    return {
      payload,
    };
  }
);

export const lookForConversationByRecipientId = createAction(
  LOOK_FOR_CONVERSATION_BY_RECIPIENT_ID,
  (payload: {
    recipientId: string,
    token: string,
  }) => {
    return {
      payload,
    };
  }
);


export const readMessages = createAction(
  READ_MESSAGES,
  (payload: { conversationId: string }) => {
    return {
      payload,
    };
  }
);

export const downloadFile = createAction(
  DOWNLOAD_FILE,
  (payload: { key: string, token: string, name: string, messageId: string, createdAt: string }) => {
    return {
      payload,
    };
  }
);


export interface ChatState {
  conversations: Record<string, Conversation>,
  fetched: boolean,
  currentConversationId?: string,
  currentRecipientId?: string,
  senderId?: string,
  contactUsers: Record<string, UserProfile>,
  scrollPosition: number | null,
  messagesLoading: boolean,
}

const initialState: ChatState = {
  conversations: {},
  fetched: false,
  currentConversationId: undefined,
  currentRecipientId: undefined,
  senderId: undefined,
  contactUsers: {},
  scrollPosition: 0,
  messagesLoading: false,
};

interface SetMessageActionPayload {
  conversationId: string;
  messages: Message[];
  nextToken: string | null;
}


export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<Record<string, Conversation>>) => {
      state.conversations = action.payload;
      state.fetched = true;
    },
    setConversation: (state, action: PayloadAction<Conversation>) => {
      const conversation = action.payload;
      state.conversations[conversation.conversationId] = conversation;
    },
    setMessages: (state, action: PayloadAction<SetMessageActionPayload>) => {
      const { conversationId, messages, nextToken } = action.payload;
      const conversation = state.conversations[conversationId] || {};
      let unreadMessages = 0;
      messages.forEach((message) => {
        if (message.senderId !== state.senderId && !message.isRead) {
          unreadMessages++;
        }
      });

      if (!isEmpty(conversation)) {
        conversation.messages = messages;
        conversation.unreadMessages = unreadMessages;
        conversation.nextToken = nextToken;
      }
    },
    setNextPageMessages: (state, action: PayloadAction<SetMessageActionPayload>) => {
      const { conversationId, messages, nextToken } = action.payload;

      const conversation = state.conversations[conversationId] || {};

      if (!isEmpty(conversation) && conversation.messages) {
        conversation.messages = [...conversation.messages, ...messages];
        conversation.nextToken = nextToken;
      }
    },
    appendMessage: (state, action: PayloadAction<Message>) => {
      const { conversationId, isRead, recipientId } = action.payload;
      const conversation = state.conversations[conversationId] || {};

      if (!isEmpty(conversation) && conversation.messages) {

        conversation.messages = [action.payload, ...conversation.messages,];

        if (typeof conversation.unreadMessages === 'number' && recipientId === state.senderId) {
          conversation.unreadMessages = isRead ? conversation.unreadMessages : conversation.unreadMessages + 1;
        }

      }
    },
    removeMessage: (state, action: PayloadAction<Message>) => {
      const { conversationId, isRead, recipientId } = action.payload;
      const conversation = state.conversations[conversationId] || {};

      if (!isEmpty(conversation) && conversation.messages) {

        conversation.messages = conversation.messages.filter((message) => message.messageId !== action.payload.messageId);

        if (typeof conversation.unreadMessages === 'number' && recipientId === state.senderId) {
          conversation.unreadMessages = isRead ? conversation.unreadMessages : conversation.unreadMessages - 1;
        }

      }
    },
    setCurrentConversationId: (state, action: PayloadAction<string>) => {
      state.currentConversationId = action.payload;
      const conversation = state.conversations[action.payload] || {};
      const senderId = state.senderId;

      if (!isEmpty(conversation) && conversation.userIds && senderId) {
        const recipientId = conversation.userIds?.find((member) => member !== senderId);
        state.currentRecipientId = recipientId;
      }
    },
    setSenderId: (state, action: PayloadAction<string>) => {
      state.senderId = action.payload;
    },
    setCurrentRecipientId: (state, action: PayloadAction<string>) => {
      state.currentRecipientId = action.payload;
    },
    setContactUsers: (state, action: PayloadAction<Record<string, UserProfile>>) => {
      state.contactUsers = action.payload;
    },
    setMessageScrollPosition: (state, action: PayloadAction<number | null>) => {
      state.scrollPosition = action.payload;
    },
    setMessagesLoading: (state, action: PayloadAction<boolean>) => {
      state.messagesLoading = action.payload;
    },
    updateMessage: (state, action: PayloadAction<{ conversationId: string, messageId: string, messageData: Message }>) => {
      const { conversationId, messageId, messageData } = action.payload;
      if (state.conversations[conversationId] && state.conversations[conversationId].messages) {

        let messageIndex = -1;
        state.conversations[conversationId].messages?.find((message, i) => {
          if (message.messageId === messageId) {
            messageIndex = i;
            return true;
          } else {
            return false
          }
        });
        if (messageIndex !== -1) {
          /* eslint-disable-next-line */
          // @ts-ignore
          state.conversations[conversationId].messages[messageIndex] = messageData;
          state.conversations[conversationId] = { ...state.conversations[conversationId] };
        }
      }
    }

  }
});

// Actions
export const {
  setConversations,
  setMessages,
  setNextPageMessages,
  appendMessage,
  removeMessage,
  setCurrentConversationId,
  setSenderId,
  setCurrentRecipientId,
  setContactUsers,
  setMessageScrollPosition,
  setMessagesLoading,
  updateMessage
} = chatSlice.actions;


// Selectors
export const getConversations = (): Record<string, Conversation> => useAppSelector((state) => state.chat.conversations);
export const getConversation = (conversationId: string): Conversation => useAppSelector((state) => state.chat.conversations[conversationId]);
export const getCurrentConversation = (): Conversation => {
  const conversationId = useAppSelector((state) => state.chat.currentConversationId);
  return useAppSelector((state) => state.chat.conversations[conversationId || '']);
}
export const getConversationsFetched = (): boolean => useAppSelector((state) => state.chat.fetched);
export const getConversationMessages = (): Message[] => {
  const conversation = useAppSelector((state) => state.chat.conversations[state.chat.currentConversationId || ''] || {}, shallowEqual);
  return conversation?.messages || [];
};
export const getCurrentConversationId = (): string => useAppSelector((state) => state.chat.currentConversationId || '');
export const getCurrentRecipientId = (): string => useAppSelector((state) => state.chat.currentRecipientId || '');
export const getSenderId = (): string => useAppSelector((state) => state.chat.senderId || '');
export const getContactUsers = (): Record<string, UserProfile> => useAppSelector((state) => state.chat.contactUsers);
export const getTotalUnreadMessages = (): number => {
  const conversations = useAppSelector((state) => state.chat.conversations);
  let totalUnreadMessages = 0;
  Object.keys(conversations).forEach((key) => {
    totalUnreadMessages += conversations[key].unreadMessages || 0;
  });
  return totalUnreadMessages;
}
export const getScrollPosition = (): number | null => useAppSelector((state) => state.chat.scrollPosition);
export const getMessagesLoading = (): boolean => useAppSelector((state) => state.chat.messagesLoading);


