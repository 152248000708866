import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchSwipeUser, resetSwipeUser } from './apis';
import { setPending, setSwipeUser } from './swipeUserSlice';
import { GetSwipeUserRequest, ResetSwipeUserRequest, SwipeUser } from './types';
import { GET_SWIPE_USER, RESET_SWIPE_USER } from './actionTypes';

function* getSwipeUserSaga(action: GetSwipeUserRequest) {
  try {
    yield put(setPending(true));

    const response: SwipeUser = yield call(fetchSwipeUser, {
      token: action.payload.token,
      jobId: action.payload.jobId,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
    });

    yield put(setSwipeUser({ ...response, currentIndex: response.results.length - 1 }));
    yield put(setPending(false));
  } catch (e) {
    console.log(e);
    yield put(setPending(false));
  }
}

function* resetSwipeUserSaga(action: ResetSwipeUserRequest) {
  try {
    yield put(setPending(true));
    yield call(resetSwipeUser, {
      token: action.payload.token,
      jobId: action.payload.jobId,
    });

    const response: SwipeUser = yield call(fetchSwipeUser, {
      token: action.payload.token,
      jobId: action.payload.jobId,
      pageNumber: 1,
      pageSize: 25,
    });

    yield put(setSwipeUser({ ...response, currentIndex: response.results.length - 1 }));
    yield put(setPending(false));
  } catch (e) {
    console.log(e);
    yield put(setPending(false));
  }
}

export function* swipeUserSaga() {
  yield all([takeLatest(GET_SWIPE_USER, getSwipeUserSaga), takeLatest(RESET_SWIPE_USER, resetSwipeUserSaga)]);
}
