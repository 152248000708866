import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LanguageSwitcher } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { ReactComponent as JobSvg } from '../assets/svg/search-job-2.svg';

export const Login = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  return (
    <Stack flexDirection={{ xs: 'column', md: 'row' }} height="100%">
      <LanguageSwitcher sx={{ position: 'fixed', top: '15px', right: '15px', zIndex: 1 }} />
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          height: { xs: '50%', md: '100%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography zIndex={1}>{t('login.individual')}</Typography>
        <Stack flexDirection="row" zIndex={1} mb={0.5}>
          <Typography fontSize={30} fontWeight={600}>
            Work
          </Typography>
          <Typography fontSize={30} fontWeight={600} color={theme.palette.primary.main}>
            inder
          </Typography>
        </Stack>
        <JobSvg style={{ position: 'absolute' }} />
        <Button onClick={() => navigate('/')}> {t('login.lookingJob')}</Button>
      </Box>
      <Box
        sx={{
          background: theme.palette.primary.main,
          width: { xs: '100%', md: '50%' },
          height: { xs: '50%', md: '100%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography zIndex={1}>{t('login.business')}</Typography>
        <Stack flexDirection="row" mb={0.5} zIndex={1}>
          <Typography fontSize={30} fontWeight={600}>
            Work
          </Typography>
          <Typography fontSize={30} fontWeight={600} color="white">
            inder
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          sx={{
            color: 'white !important',
            border: '1px solid white !important',
            '&:hover': {
              color: `${theme.palette.primary.main} !important`,
              background: 'white',
            },
          }}
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${window.location.protocol}//${window.location.host}/admin`,
              },
            })
          }
        >
          {t('login.lookingTalent')}
        </Button>
      </Box>
    </Stack>
  );
};
