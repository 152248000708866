import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_DONATE_OPTIONS, UPDATE_PAYMENT_INTENT } from './actionTypes';
import { fetchDonateOptions, updatePaymentIntent } from './apis';
import { FetchDonateOptionsRequest, UpdatePaymentIntentRequest } from './types';
import { DonateOption } from 'types/donateType';
import { setDonateOptions, setSelectedDonateOption } from './donationSlice';

function* getDonateOptionsSaga(action: FetchDonateOptionsRequest) {
  try {
    const response: DonateOption[] = yield call(fetchDonateOptions, {
      token: action.payload.token,
    });

    const selectedOption = response.find((option) => option.preselect) || null;
    if (selectedOption) {
      yield put(setSelectedDonateOption(selectedOption));
    }
    yield put(setDonateOptions(response));
  } catch (e) {
    console.log(e);
  }
}

function* updatePaymentIntentSaga(action: UpdatePaymentIntentRequest) {
  try {
    yield call(updatePaymentIntent, {
      token: action.payload.token,
      paymentIntentId: action.payload.paymentIntentId,
      paymentTypeId: action.payload.paymentTypeId,
    });
  } catch (e) {
    console.log(e);
  }
}

export function* donationSaga() {
  yield all([
    takeLatest(GET_DONATE_OPTIONS, getDonateOptionsSaga),
    takeLatest(UPDATE_PAYMENT_INTENT, updatePaymentIntentSaga),
  ]);
}
