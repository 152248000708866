import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogTitle } from '@mui/material';
import { useAppDispatch } from 'state/hooks';
import { profileResetData } from 'state/profile/profileSlice';
import { showResumeReuploadDialog, toggleResumeReuploadDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { StepForm, UploadResume } from '.';
import { UserProfile } from 'types/profileType';

export const ResumeReuploadDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const open = showResumeReuploadDialog();
  const onClose = () => dispatch(toggleResumeReuploadDialog(false));

  const steps = [
    'Upload CV',
    t('createProfile.step2'),
    t('createProfile.step3'),
    t('createProfile.step4'),
    t('createProfile.step5'),
    t('createProfile.step6'),
  ];

  const defaultValues = {
    title: '',
    firstName: user ? user.given_name : '',
    lastName: user ? user.family_name : '',
    birthdate: null,
    email: user ? user.email : '',
    phoneNumber: '',
    location: '',
    description: '',
    skills: [],
    languages: [],
    educations: [],
    experiences: [],
    matchJob: true,
    sexe: 0,
  };

  const { handleSubmit, control, setValue, watch } = useForm<UserProfile>({ defaultValues });

  const [activeStep, setActiveStep] = useState<number>(0);

  const onSubmit = handleSubmit(async (data: UserProfile) => {
    user &&
      getAccessTokenSilently().then((token) => {
        dispatch(profileResetData({ userId: user.sub as string, token, user: data }));
        onClose();
      });
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: '20px' } }}
      fullWidth
      maxWidth={activeStep < 1 ? 'sm' : 'md'}
    >
      <DialogTitle>
        Regenerate your profile - {steps[activeStep]}
        <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
      </DialogTitle>
      {activeStep === 0 ? (
        <UploadResume setActiveStep={setActiveStep} onClose={onClose} setProfile={setValue} />
      ) : (
        <StepForm
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          control={control}
          watch={watch}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  );
};
