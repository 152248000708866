import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Avatar, IconButton, Popover, MenuList, MenuItem, Stack, Card, Typography } from '@mui/material';
import { LoadingAnimation } from 'components/Animation';
import { useChat } from 'components/Chat/useChat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Message, Metadata } from '../types/chatTypes';
import { theme } from 'theme';

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
`;

interface FileMessageProps {
  message: Message;
  isFromSender: boolean;
  setScrollPosition: () => void;
}
export const FileMessage = ({ message, isFromSender, setScrollPosition }: FileMessageProps) => {
  const { t } = useTranslation();
  const { isSending } = message;
  const { title } = message.metadata as Metadata;
  const { downloadFile, removeFileMessage } = useChat();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleDeleteFile = () => {
    if (message.messageType === 'file' && message.content) {
      removeFileMessage({ key: message.content, messageId: message.messageId, createdAt: message.createdAt as string });
      setScrollPosition();
    }
  };

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? 'popup' : undefined;

  return (
    <Stack flexDirection="row-reverse" alignItems="center" gap={0.5} maxWidth="70%">
      {isSending && (
        <LoadingWrapper>
          <LoadingAnimation />
        </LoadingWrapper>
      )}
      <Card
        key={message.messageId}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          background: isFromSender ? theme.palette.primary.light : 'lightgray',
          p: 1,
          cursor: 'pointer',
        }}
        onClick={() =>
          message.content &&
          downloadFile({
            key: message.content,
            name: title,
            messageId: message.messageId,
            createdAt: message.createdAt as string,
          })
        }
      >
        <Avatar sx={{ width: 30, height: 30, bgcolor: theme.palette.primary.main }}>
          <DescriptionOutlinedIcon />
        </Avatar>
        <Typography overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" mr={0.5}>
          {title}
        </Typography>
      </Card>
      {isFromSender && (
        <IconButton color="primary" onClick={handleMenuClick} size="small">
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem onClick={handleDeleteFile}>{t('chat.delete')}</MenuItem>
        </MenuList>
      </Popover>
    </Stack>
  );
};
