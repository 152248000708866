import axios from 'axios';
import { GetSwipeUserPayload, ResetSwipeUserPayload } from './types';
import { getApiURL } from 'utils';

export const fetchSwipeUser = async (payload: GetSwipeUserPayload) => {
  const { data } = await axios.get(
    getApiURL(`/Search/SearchUserProfileIndexByJobRequest/${payload.jobId}`, {
      PageNumber: payload.pageNumber,
      PageSize: payload.pageSize,
    }),
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );
  return data;
};

export const resetSwipeUser = async (payload: ResetSwipeUserPayload) => {
  return axios.delete(getApiURL('/Swipe/ResetSwipeUserForJobRequest'), {
    headers: {
      Authorization: `Bearer ${payload.token}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ jobId: payload.jobId }),
  });
};
