import { IconButton, Stack } from '@mui/material';
import { Section } from 'components';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export const SocialMedia = () => {
  return (
    <Section marginBottom={3}>
      <Stack flexDirection="row" justifyContent="space-evenly">
        <IconButton color="primary">
          <FacebookIcon />
        </IconButton>
        <IconButton color="primary">
          <EmailOutlinedIcon />
        </IconButton>
        <IconButton color="primary">
          <LinkedInIcon />
        </IconButton>
        <IconButton color="primary">
          <InstagramIcon />
        </IconButton>
        <IconButton color="primary">
          <TwitterIcon />
        </IconButton>
      </Stack>
    </Section>
  );
};
