import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { showNoteDialog, toggleNoteDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';

interface NoteDialogProps {
  organizationNote: string;
  setOrganizationNote: (note: string) => void;
  onAddNote: (str: string) => void;
}

export const NoteDialog = ({ organizationNote, setOrganizationNote, onAddNote }: NoteDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   setNote(message);
  // }, [message]);

  const open = showNoteDialog();
  const onClose = () => dispatch(toggleNoteDialog(false));

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        Note
        <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <TextField
          name="note"
          value={organizationNote}
          onChange={(e) => {
            setOrganizationNote(e.target.value);
          }}
          multiline
          minRows={5}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onAddNote(organizationNote);
            onClose();
          }}
          fullWidth
        >
          {t('button.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
