import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchSwipeJob, resetSwipeJob } from './apis';
import { setPending, setSwipeJob } from './swipeJobSlice';
import { FetchSwipeJobRequest, ResetSwipeJobRequest, SwipeJob } from './types';
import { GET_SWIPE_JOB, RESET_SWIPE_JOB } from './actionTypes';

function* getSwipeJobSaga(action: FetchSwipeJobRequest) {
  try {
    yield put(setPending(true));

    const response: SwipeJob = yield call(fetchSwipeJob, {
      token: action.payload.token,
      userId: action.payload.userId,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
    });

    yield put(setSwipeJob({ ...response, currentIndex: response.results.length - 1 }));
    yield put(setPending(false));
  } catch (e) {
    console.log(e);
    yield put(setPending(false));
  }
}

function* resetSwipeJobSaga(action: ResetSwipeJobRequest) {
  try {
    yield put(setPending(true));
    yield call(resetSwipeJob, {
      token: action.payload.token,
      userId: action.payload.userId,
    });

    const response: SwipeJob = yield call(fetchSwipeJob, {
      token: action.payload.token,
      userId: action.payload.userId,
      pageNumber: 1,
      pageSize: 25,
    });

    yield put(setSwipeJob({ ...response, currentIndex: response.results.length - 1 }));
    yield put(setPending(false));
  } catch (e) {
    console.log(e);
    yield put(setPending(false));
  }
}

export function* swipeJobSaga() {
  yield all([takeLatest(GET_SWIPE_JOB, getSwipeJobSaga), takeLatest(RESET_SWIPE_JOB, resetSwipeJobSaga)]);
}
