import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components/';
import { DescriptionEditDialog } from 'components/Dialog/JobEditDialog';
import { useAppDispatch } from 'state/hooks';
import { getJobDescription, setSelectedJobId } from 'state/job/jobSlice';
import { toggleApplyDialog } from 'state/dialogs/dialogsSlice';
import { toggleDescriptionEditDialog } from 'state/dialogs/jobDialogsSlice';
import { getUserProfileSelector } from 'state/profile/profileSlice';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SendIcon from '@mui/icons-material/Send';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useChat } from 'components/Chat/useChat';

export const Description = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const jobDescription = getJobDescription();
  const editable: boolean = location.pathname.startsWith('/admin');

  const { lookForConversationByRecipientId, conversationsFetched } = useChat();

  const handleClickSendMessage = () => {
    if (jobDescription.userProfileId && conversationsFetched) {
      lookForConversationByRecipientId(jobDescription.userProfileId);
      navigate(`/message`);
    }
  };

  return (
    <Section title={jobDescription.title} bigTitle>
      <Typography fontSize={15} mb={2}>
        {jobDescription.companyName}
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={5}>
        <BusinessCenterIcon fontSize="small" />
        {jobDescription.isFullTime && <Tag label={t('jobType.fullTime')} color="#388e3c" background="#e8f5e9" />}
        {jobDescription.isPartTime && <Tag label={t('jobType.partTime')} color="#f57c00" background="#fff3e0" />}
        {jobDescription.isContract && <Tag label={t('jobType.contract')} color="#d32f2f" background="#ffebee" />}
        {jobDescription.isInternship && <Tag label={t('jobType.internship')} color="#1976d2" background="#e3f2fd" />}
      </Stack>
      {!editable && (
        <Stack flexDirection="row" justifyContent="space-between" mb={5}>
          <Button
            onClick={() => {
              const path = window.location.pathname;
              const id = path.split('/').pop();

              id && dispatch(setSelectedJobId(id));
              dispatch(toggleApplyDialog(true));
            }}
            endIcon={<SendIcon />}
            disabled={!jobDescription}
          >
            {t('button.applyNow')}
          </Button>
          <Button variant="outlined" startIcon={<EmailOutlinedIcon />} onClick={handleClickSendMessage}>
            Send a message
          </Button>
        </Stack>
      )}
      <Stack flexDirection="row" gap={1}>
        <Typography fontSize={18} fontWeight={600}>
          {t('jobInfo.description')}
        </Typography>
        {editable && (
          <IconButton
            sx={{ bottom: '4px', left: '4px' }}
            color="primary"
            onClick={() => dispatch(toggleDescriptionEditDialog(true))}
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
      </Stack>
      <Typography dangerouslySetInnerHTML={{ __html: jobDescription.description as string }} />
      <DescriptionEditDialog />
    </Section>
  );
};
