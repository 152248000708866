import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { TextField } from 'components/Form';
import { UserOrg } from 'types/organizationType';
import { theme } from 'theme';

interface ContactProps {
  userOrg: UserOrg;
  setUserOrg: (org: UserOrg) => void;
}

export const Contact = ({ userOrg, setUserOrg }: ContactProps) => {
  const { t } = useTranslation();

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row' }} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color={theme.palette.grey[600]}>
          * Required information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="firstName"
          label={`${t('profile.firstName')} *`}
          value={userOrg.firstName}
          onChange={(e) =>
            setUserOrg({
              ...userOrg,
              firstName: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="lastName"
          label={`${t('profile.lastName')} *`}
          value={userOrg.lastName}
          onChange={(e) =>
            setUserOrg({
              ...userOrg,
              lastName: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField name="email" label={t('email')} value={userOrg.email} disabled />
      </Grid>
    </Grid>
  );
};
