import { blue, green, orange, red } from '@mui/material/colors';

export const getApplicantStyles = (status: number) => {
  switch (status) {
    case 0:
      return { color: orange[700], background: orange[50] };
    case 1:
      return { color: blue[700], background: blue[50] };
    case 2:
      return { color: green[700], background: green[50] };
    case 3:
      return { color: red[700], background: red[50] };
  }
};

type BulkAction = {
  value: string;
  label: string;
};

export const bulkActions: BulkAction[] = [
  // { value: 'interview', label: 'Send Interview' },
  { value: 'accept', label: 'Accept' },
  { value: 'reject', label: 'Reject' },
  // { value: 'sendMessages', label: 'Send Messages' },
  // { value: 'downloadCV', label: 'Download CV' },
];

export const getLabelByValue = (value: string) => {
  const action = bulkActions.find((action) => action.value === value);
  return action?.label;
};
