import { UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { JobDescription } from 'types/jobType';
import { formatExperienceRange } from 'utils';

interface ReviewProps {
  watch: UseFormWatch<JobDescription>;
  setActiveStep: (num: number) => void;
  setIsReview: (boo: boolean) => void;
}

export const Review = ({ watch, setActiveStep, setIsReview }: ReviewProps) => {
  const { t } = useTranslation();

  const experienceMin = watch('experienceMin');
  const experienceMax = watch('experienceMax');

  return (
    <>
      <Section
        title={t('createJob.step2')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(1);
        }}
        editable
        marginBottom={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Title</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('title')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Company name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('companyName')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Salary</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {watch('salaryMin')}$ - {watch('salaryMax')}$ {watch('salaryType') === 1 ? 'per hour' : 'per year'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Workplace Type</Typography>
          </Grid>
          <Grid item xs={9} display="flex" gap={1}>
            {watch('isRemote') && <Tag label="Remote" color="#388e3c" background="#e8f5e9" />}
            {watch('isHybrid') && <Tag label="Hybrid" color="#f57c00" background="#fff3e0" />}
            {watch('isOnSite') && <Tag label="On Site" color="#d32f2f" background="#ffebee" />}
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Location</Typography>
          </Grid>
          <Grid container item xs={9} display="flex" alignItems="start" gap={1}>
            {watch('locations')?.map((item, index) => (
              <Tag key={index} label={item as string} />
            ))}
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Job Type</Typography>
          </Grid>
          <Grid item xs={9} display="flex" gap={1}>
            {watch('isFullTime') && <Tag label={t('jobType.fullTime')} color="#388e3c" background="#e8f5e9" />}
            {watch('isPartTime') && <Tag label={t('jobType.partTime')} color="#f57c00" background="#fff3e0" />}
            {watch('isContract') && <Tag label={t('jobType.contract')} color="#d32f2f" background="#ffebee" />}
            {watch('isInternship') && <Tag label={t('jobType.internship')} color="#1976d2" background="#e3f2fd" />}
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Experience required</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{formatExperienceRange({ experienceMin, experienceMax })}</Typography>
          </Grid>
        </Grid>
      </Section>
      <Section
        title={t('createJob.step3')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(2);
        }}
        editable
        marginBottom={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Description</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography dangerouslySetInnerHTML={{ __html: watch('description') as string }} />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('profile.skill.title')}</Typography>
          </Grid>
          <Grid container item xs={9} display="flex" alignItems="start" gap={1}>
            {watch('skills')?.map((item, index) => (
              <Tag key={index} label={item as string} />
            ))}
          </Grid>
        </Grid>
      </Section>
    </>
  );
};
