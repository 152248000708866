import { useTranslation } from 'react-i18next';
import { Avatar, Stack, Typography } from '@mui/material';
import { Tag } from 'components/Tag';

import { JobShort } from 'types/jobType';

interface SwipeJobProps {
  job: JobShort;
}

export const SwipeJob = ({ job }: SwipeJobProps) => {
  const { t } = useTranslation();

  const skills = String(job.skills);
  const skillList = skills.split(' ');

  return (
    <>
      <Avatar
        sx={{
          width: '80px',
          height: '80px',
          borderRadius: '20px',
        }}
        // alt={user.name}
        // src={user.picture}
        variant="rounded"
      />
      <Stack alignItems="center">
        <Typography sx={{ fontWeight: 'bold' }} variant="h6" textAlign="center">
          {job.title}
        </Typography>
        <Typography variant="subtitle1">{job.companyName}</Typography>
      </Stack>
      {job.locations.length !== 0 && <Typography ml={0.5}>{job?.locations[0].name}</Typography>}
      <Stack flexDirection="row" justifyContent="center" flexWrap="wrap" gap={1}>
        {job.isFullTime && <Tag label={t('jobType.fullTime')} color="#388e3c" background="#e8f5e9" />}
        {job.isPartTime && <Tag label={t('jobType.partTime')} color="#f57c00" background="#fff3e0" />}
        {job.isContract && <Tag label={t('jobType.contract')} color="#d32f2f" background="#ffebee" />}
        {job.isInternship && <Tag label={t('jobType.internship')} color="#1976d2" background="#e3f2fd" />}
      </Stack>
      <Stack alignItems="center">
        <Typography maxHeight={80} textOverflow="ellipsis" overflow="hidden" align="center">
          {job.description}
        </Typography>
        <Typography>[...]</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="center" flexWrap="wrap" gap={1} mt={1}>
        {skillList.slice(0, 6).map((skill, i) => skill && <Tag key={i} label={skill} />)}
        {skillList.length > 6 && <Tag label="..." />}
      </Stack>
    </>
  );
};
