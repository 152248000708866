import { combineReducers } from 'redux';
import { jobSlice } from './job/jobSlice';
import { profileSlice } from './profile/profileSlice';
import { organizationSlice } from './organization/organizationSlice';
import { dialogsSlice } from './dialogs/dialogsSlice';
import { jobDialogsSlice } from './dialogs/jobDialogsSlice';
import { swipeUserSlice } from './swipeUser/swipeUserSlice';
import { swipeJobSlice } from './swipeJob/swipeJobSlice';
import { donationSlice } from './donation/donationSlice';
import { chatSlice } from './chat/chatSlice';
import { appSlice } from './app/appSlice';
import { adminSlice } from './admin/adminSlice';

export const rootReducer = combineReducers({
  app: appSlice.reducer,
  admin: adminSlice.reducer,
  job: jobSlice.reducer,
  profile: profileSlice.reducer,
  organization: organizationSlice.reducer,
  swipeJob: swipeJobSlice.reducer,
  swipeUser: swipeUserSlice.reducer,
  dialogs: dialogsSlice.reducer,
  jobDialogs: jobDialogsSlice.reducer,
  donation: donationSlice.reducer,
  chat: chatSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
