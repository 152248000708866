import React, { useEffect, useRef, useState } from 'react';
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@mui/material';
import { getApiURL } from 'utils';
import { useAuth0 } from '@auth0/auth0-react';

type CustomAvatarProps = MuiAvatarProps & {
  src?: string;
  editable?: boolean;
  large?: boolean;
};

export const Avatar = ({ src, editable, large = false, ...props }: CustomAvatarProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const fileInput = useRef<HTMLInputElement | null>(null);

  const [avatar, setAvatar] = useState<File>();

  useEffect(() => {
    postData().catch(console.error);
  }, [avatar]);

  const postData = async () => {
    if (avatar) {
      const formData = new FormData();
      formData.append('file', avatar, avatar.name);
      formData.append('type', 'UserProfilePicture');

      const token = await getAccessTokenSilently();
      const res = await fetch(getApiURL('/File/UploadFileRequest'), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then((res) => res.json());

      console.log('res: ', res);
    }
  };

  return (
    <>
      <MuiAvatar
        {...props}
        sx={{
          width: large ? '130px' : '100px',
          height: large ? '130px' : '100px',
          border: '5px solid white',
          boxShadow: '#C0C0C0 0px 0px 0px 1px',
          cursor: editable ? 'pointer' : 'default',
        }}
        src={avatar ? URL.createObjectURL(avatar) : src}
        onClick={() => editable && fileInput.current?.click()}
      />
      <input
        ref={fileInput}
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.files && setAvatar(e.target.files[0]);
        }}
        style={{ display: 'none' }}
      />
    </>
  );
};
