import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Stack } from '@mui/material';
import { Section, Tag } from 'components';
import { LocationEditDialog } from 'components/Dialog/JobEditDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleLocationEditDialog } from 'state/dialogs/jobDialogsSlice';
import { getJobDescription } from 'state/job/jobSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { Location } from 'types/jobType';

export const Locations = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const jobDescription = getJobDescription();
  const editable: boolean = location.pathname.startsWith('/admin');

  const [isEditable, setIsEditable] = useState(false);

  const deleteLocation = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Job/DeleteJobLocationRequest/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobId: jobDescription.id }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Section
      title={t('jobInfo.location')}
      editable={editable}
      edit={isEditable}
      onAdd={() => dispatch(toggleLocationEditDialog(true))}
      onEdit={() => setIsEditable(!isEditable)}
      marginBottom={3}
    >
      <Stack flexDirection="row" flexWrap="wrap" gap={1}>
        {jobDescription.locations &&
          jobDescription.locations.length !== 0 &&
          (jobDescription.locations as Location[]).map((location) => (
            <Tag
              key={location.id}
              label={location.name}
              color="#1976d2"
              background="#e3f2fd"
              icon={
                isEditable ? (
                  <IconButton
                    type="button"
                    size="small"
                    color="error"
                    onClick={() => deleteLocation(location.id as string)}
                    disableRipple
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                ) : undefined
              }
            />
          ))}
      </Stack>
      <LocationEditDialog />
    </Section>
  );
};
