import { Box, Typography } from '@mui/material';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Control, Controller, FieldValues } from 'react-hook-form';

type MuiDatePickerProps = DatePickerProps<moment.Moment> & {
  sx?: object;
  label?: string;
  name?: string;
  control?: Control<FieldValues, any>;
  marginBottom?: number;
};

export const DatePicker = ({ sx, label, name, control, marginBottom, ...props }: MuiDatePickerProps) => {
  return (
    <Box component="div" mb={marginBottom ? marginBottom : 0}>
      {label && <Typography mb="5px">{label}</Typography>}
      {control && name ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <MuiDatePicker {...props} sx={{ ...sx, width: '100%' }} value={value} onChange={onChange} />
          )}
        />
      ) : (
        <MuiDatePicker {...props} sx={{ ...sx, width: '100%' }} />
      )}
    </Box>
  );
};
