import axios from 'axios';
import { getApiURL } from 'utils';
import { GetSwipeJobPayload, ResetSwipeJobPayload } from './types';

export const fetchSwipeJob = async (payload: GetSwipeJobPayload) => {
  const { data } = await axios.get(
    getApiURL(`/Job/SearchJobByUserRequest/${payload.userId}`, {
      PageNumber: payload.pageNumber,
      PageSize: payload.pageSize,
    }),
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );
  return data;
};

export const resetSwipeJob = async (payload: ResetSwipeJobPayload) => {
  return axios.delete(getApiURL('/Swipe/ResetSwipeJobForUserRequest'), {
    headers: {
      Authorization: `Bearer ${payload.token}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ userProfileId: payload.userId }),
  });
};
