import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Loading } from 'components';
import { ConfirmationDialog } from 'components/Dialog';
import { Description, Information, Options, Review } from './Sections';
import { useAppDispatch } from 'state/hooks';
import { toggleConfirmationDialog } from 'state/dialogs/dialogsSlice';
import { getUserOrgSelector } from 'state/organization/organizationSlice';
import { getApiURL } from 'utils';
import { JobDescription } from 'types/jobType';

export const CreateJob = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userOrg = getUserOrgSelector();

  const defaultValues = {
    title: '',
    companyName: userOrg.organization?.name,
    locations: [userOrg.organization?.location],
    skills: [],
    salaryMin: undefined,
    salaryMax: undefined,
    salaryType: 1,
    isFullTime: false,
    isPartTime: false,
    isContract: false,
    isInternship: false,
    isHybrid: false,
    isOnSite: false,
    isRemote: false,
    jobLevel: '',
    experienceMin: undefined,
    experienceMax: undefined,
    description: '',
  };

  const { handleSubmit, control, setValue, watch, reset } = useForm<JobDescription>({ defaultValues });

  const steps = [t('createJob.step1'), t('createJob.step2'), t('createJob.step3'), t('createJob.step4')];

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isReview, setIsReview] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    if (activeStep === steps.length - 1) {
      setIsReview(true);
    }

    if (activeStep === 1) {
      dispatch(
        toggleConfirmationDialog({
          open: true,
          message: t('dialog.question.lost'),
          onConfirmation: () => {
            setActiveStep(0);
            setIsReview(false);
            window.scrollTo(0, 0);
          },
        })
      );
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const createJob = async (data: JobDescription) => {
    setPending(true);
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Job/CreateJobRequest'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      reset();
      navigate(`/admin/job?jobId=${res.id}`);
    }
    setPending(false);
  };

  const onSubmit = handleSubmit(async (data: JobDescription) => {
    createJob(data);
  });

  if (pending) {
    return <Loading condition={pending} />;
  }

  return (
    <Container
      sx={{
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
      maxWidth="md"
    >
      <Stack flexDirection="row" justifyContent="space-between" mb={7}>
        <Stack>
          <Typography variant="h6" textTransform="uppercase">
            {t('pages.createJob')}
          </Typography>
          <Typography variant="h4">
            {steps.map((item, index) => {
              if (index === activeStep) return item;
            })}
          </Typography>
        </Stack>
        {activeStep === 0 && <Button onClick={() => window.history.back()}>{t('button.back')}</Button>}
      </Stack>
      {activeStep === 0 && <Options handleNext={handleNext} setValue={setValue} />}
      <form onSubmit={onSubmit}>
        {activeStep === 1 && <Information control={control} watch={watch} />}
        {activeStep === 2 && <Description control={control} watch={watch} />}
        {activeStep === 3 && <Review watch={watch} setActiveStep={setActiveStep} setIsReview={setIsReview} />}
        {activeStep !== 0 && (
          <Stack flexDirection="row" justifyContent="space-between" mt={6}>
            <Button type="button" variant="outlined" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              {t('button.back')}
            </Button>
            <Stack flexDirection="row" gap={1}>
              {activeStep < steps.length - 1 && (
                <Button type="button" onClick={handleNext}>
                  {t('button.next')}
                </Button>
              )}
              {isReview && activeStep !== steps.length - 1 && (
                <Button type="button" onClick={() => setActiveStep(steps.length - 1)}>
                  Review
                </Button>
              )}
            </Stack>
            {activeStep === steps.length - 1 && <Button type="submit">{t('button.create')}</Button>}
          </Stack>
        )}
      </form>
      <ConfirmationDialog />
    </Container>
  );
};
