import { BaseSyntheticEvent, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { About, Contact, Educations, Experiences, Review } from 'CreateProfile/Sections';
import { UserProfile } from 'types/profileType';
import { getProfileGenerateSelector, getProfileUploadSelector } from 'state/profile/profileSlice';

interface StepFormProps {
  steps: string[];
  activeStep: number;
  setActiveStep: (num: number) => void;
  control: Control<UserProfile, any>;
  watch: UseFormWatch<UserProfile>;
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

export const StepForm = ({ steps, activeStep, setActiveStep, control, watch, onSubmit }: StepFormProps) => {
  const { t } = useTranslation();
  const generate = getProfileGenerateSelector();
  const upload = getProfileUploadSelector();

  const [isReview, setIsReview] = useState<boolean>(false);

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        {activeStep === 1 && <Contact control={control} />}
        {activeStep === 2 && <About control={control} watch={watch} />}
        {activeStep === 3 && <Experiences control={control} />}
        {activeStep === 4 && <Educations control={control} />}
        {activeStep === 5 && <Review watch={watch} setActiveStep={setActiveStep} setIsReview={setIsReview} />}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          onClick={() => {
            setActiveStep(activeStep - 1);
            window.scrollTo(0, 0);
          }}
        >
          {t('button.back')}
        </Button>
        {activeStep < steps.length - 1 && (
          <Button
            type="button"
            onClick={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          >
            {t('button.next')}
          </Button>
        )}
        {activeStep === steps.length - 1 && <Button type="submit">{t('button.confirm')}</Button>}
      </DialogActions>
    </form>
  );
};
