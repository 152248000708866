import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { TextField } from 'components/Form';
import { Organization } from 'types/organizationType';

interface AboutProps {
  control: Control<Organization, any>;
}

export const About = ({ control }: AboutProps) => {
  const { t } = useTranslation();

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row' }} container spacing={3}>
      <Grid item xs={12}>
        <TextField name="aboutUs" control={control} label="About Us" multiline minRows={10} />
      </Grid>
    </Grid>
  );
};
