import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { TextEditor } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { getJobDescription, getJobDescriptionData } from 'state/job/jobSlice';
import { showDescriptionEditDialog, toggleDescriptionEditDialog } from 'state/dialogs/jobDialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';

export const DescriptionEditDialog = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showDescriptionEditDialog();
  const jobDescription = getJobDescription();
  const onClose = () => {
    setDescription('');
    dispatch(toggleDescriptionEditDialog(false));
  };

  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (jobDescription) {
      setDescription(jobDescription.description ?? '');
    }
  }, [open, jobDescription]);

  const updateDescription = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Job/UpdateJobRequest/${jobDescription.id}`), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ description }),
    });

    if (response.ok) {
      jobDescription.id && dispatch(getJobDescriptionData({ jobId: jobDescription.id }));
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        Job description
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <TextEditor value={description} onChange={(e) => setDescription(e)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button onClick={updateDescription}>{t('button.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};
