import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container } from '@mui/material';
import { ReactComponent as NotFound } from '../assets/svg/not-found.svg';

export const PageNotFound = () => {
  const { isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      maxWidth="sm"
    >
      <NotFound onClick={() => navigate('/login')} style={{ cursor: 'pointer' }} />
      {isAuthenticated && (
        <Button
          sx={{ position: 'fixed', top: '15px', right: '15px', zIndex: 1 }}
          onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })}
          variant="outlined"
        >
          {t('button.logOut')}
        </Button>
      )}
    </Container>
  );
};
