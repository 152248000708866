import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import { Section } from 'components';
import { JobUploadDialog } from 'components/Dialog';
import { useAppDispatch } from 'state/hooks';
import { toggleJobUploadDialog } from 'state/dialogs/dialogsSlice';
import { JobDescription } from 'types/jobType';

interface OptionsProps {
  handleNext: () => void;
  setValue: UseFormSetValue<JobDescription>;
}

export const Options = ({ handleNext, setValue }: OptionsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Grid container spacing={5} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <Section>
          <Typography mt={4} mb={3} textAlign="center">
            {t('createJob.text.option1')}
          </Typography>
          <Button sx={{ mb: 4 }} onClick={() => dispatch(toggleJobUploadDialog(true))} fullWidth>
            {t('button.upload')}
          </Button>
        </Section>
      </Grid>
      <Grid item xs={12} md={6}>
        <Section>
          <Typography mt={4} mb={3} textAlign="center">
            {t('createJob.text.option2')}
          </Typography>
          <Button sx={{ mb: 4 }} variant="outlined" onClick={handleNext} fullWidth>
            {t('button.begin')}
          </Button>
        </Section>
      </Grid>
      <JobUploadDialog setShowForm={handleNext} setJobValue={setValue} />
    </Grid>
  );
};
