import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Typography } from '@mui/material';
import { Section } from 'components';
import { theme } from 'theme';

export const DeclineInvitation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
      }}
    >
      <Section px={{ xs: 2, sm: 6 }} py={{ xs: 2, sm: 4 }} gap={2}>
        <Stack
          sx={{ cursor: 'pointer' }}
          flexDirection="row"
          zIndex={1}
          alignSelf="center"
          onClick={() => navigate('/admin')}
        >
          <Typography fontSize={30} fontWeight={600}>
            Work
          </Typography>
          <Typography fontSize={30} fontWeight={600} color={theme.palette.primary.main}>
            inder
          </Typography>
        </Stack>
        <Typography fontWeight={600}>Invitation Declined </Typography>
        <Typography>
          We acknowledge receipt of your decision to decline our invitation to join our organization.
        </Typography>
        <Typography>
          Thank you for considering our invitation. Should your circumstances change or should you wish to explore
          potential opportunities with us in the future, please feel free to reach out.
        </Typography>
        <Typography>If you have any questions or concerns, please do not hesitate to contact us. </Typography>
        <Typography>Best regards</Typography>
      </Section>
      <Typography
        sx={{ color: theme.palette.grey[700] }}
        fontSize="12px"
        textAlign={{ xs: 'center', md: 'start' }}
        ml={{ xs: 0, md: 3 }}
        my={1}
      >
        {t('footerRight')}
      </Typography>
    </Container>
  );
};
