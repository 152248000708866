import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiURL, getApplicantStyles } from 'utils';
import { Tag } from 'components';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { ApplicationStatus } from 'types/applicantType';
import { theme } from 'theme';

export const Application = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [applications, setApplications] = useState<any>([]);

  const fetchApplicationHistory = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(`/Application/GetApplicationsByUserRequest`, {
        PageNumber: 1,
        PageSize: 25,
      }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then((res) => res.json());

    console.log('response: ', response);
    setApplications(response.results);
  };

  useEffect(() => {
    fetchApplicationHistory();
  }, []);

  return (
    <Container sx={{ py: 4 }}>
      <Typography fontSize={18} fontWeight={600} mb={3}>
        Application history
      </Typography>
      <Box
        sx={{
          background: theme.palette.primary.light,
          p: 2,
          borderRadius: '15px',
          mb: 2,
        }}
      >
        <Grid container>
          <Grid item xs={4.5}>
            <Typography fontWeight={600}>Job Title</Typography>
          </Grid>
          <Grid item xs={4.5}>
            <Typography fontWeight={600}>Company</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={600}>Date</Typography>
          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={1}>
            <Typography fontWeight={600}>Status</Typography>
          </Grid>
        </Grid>
      </Box>
      <Stack gap={2}>
        {applications.length > 0 &&
          applications.map((app: any) => (
            <Box key={app.applicationId} sx={{ background: 'white', p: 2, borderRadius: '15px' }}>
              <Grid container>
                <Grid item xs={4.5}>
                  <Typography>{app.jobTitle}</Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Typography>{app.companyName}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{moment(app.applicationDate).format('MM/DD/YYYY')}</Typography>
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs={1}>
                  <Tag
                    label={ApplicationStatus[app.applicationStatus]}
                    color={getApplicantStyles(app.applicationStatus)?.color}
                    background={getApplicantStyles(app.applicationStatus)?.background}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
      </Stack>
    </Container>
  );
};
