import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BannerProvider } from 'components/Banner';
import { SnackbarProvider } from 'components/Snackbar';
import { setupStore } from './state/store';
// import reportWebVitals from './reportWebVitals';
import 'assets/localization/i18next';
import { App } from './App';
import { theme } from 'theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const store = setupStore();

root.render(
  <Provider store={store}>
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={window._env_.AUTH0_DOMAIN}
          clientId={window._env_.AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.href,
            audience: window._env_.AUTH0_AUDIENCE,
          }}
        >
          <BannerProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <React.StrictMode>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </React.StrictMode>
            </LocalizationProvider>
          </BannerProvider>
        </Auth0Provider>
      </ThemeProvider>
    </SnackbarProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
