import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Badge, Box, Fab, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Display, Tag } from 'components';
import { TextField } from 'components/Form';
import { FileMessage } from 'Message/FileMessage';
import { TextMessage } from 'Message/TextMessage';
import { useChat } from 'components/Chat/useChat';
import { useUploadFiles } from './useUploadFiles';
import { useAppDispatch } from 'state/hooks';
import { getUserProfileSelector } from 'state/profile/profileSlice';
import { getScrollPosition, setMessageScrollPosition } from 'state/chat/chatSlice';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { theme } from 'theme';

export const Chat = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isLoading } = useAuth0();
  const userProfile = getUserProfileSelector();
  const isMobile = useMediaQuery('(max-width:900px)');

  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [selectIndex, setSelectIndex] = useState<number | null>(null);

  // const handleMenuClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const scrollPosition = getScrollPosition();
  const {
    sendMessage,
    senderId,
    sender,
    senderAuthInfo,
    currentRecipientId,
    currentConversationId,
    currentConversationMessages,
    changeCurrentConversation,
    conversations,
    contactUsers,
    currentConversation,
    getConversationNextPageMessages,
    messagesLoading,
    totalUnreadMessages,
  } = useChat();
  const { selectFile, files, removeFile, clear } = useUploadFiles();

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textareaRef = useRef<any>(null);

  const handleSetScrollPosition = () => {
    if (textareaRef.current) {
      dispatch(setMessageScrollPosition(textareaRef.current.scrollTop));
    }
  };

  useEffect(() => {
    if (!textareaRef.current || !currentConversation) return () => null;

    const onTextareaScroll = () => {
      const scrollTop = Math.abs(textareaRef.current.scrollTop);
      const scrollHeight = textareaRef.current.scrollHeight;
      const height = textareaRef.current.getBoundingClientRect().height;

      if (scrollTop >= scrollHeight - height - 10 && currentConversation?.nextToken) {
        getConversationNextPageMessages();
        dispatch(setMessageScrollPosition(textareaRef.current.scrollTop));
      }
    };

    textareaRef.current.addEventListener('scroll', onTextareaScroll);

    return () => textareaRef.current?.removeEventListener('scroll', onTextareaScroll);
  }, [currentConversation]);

  useEffect(() => {
    if (typeof scrollPosition === 'number' && textareaRef.current && textareaRef.current.scrollTo) {
      textareaRef.current.scrollTo({
        top: scrollPosition,
      });
      // dispatch(setMessageScrollPosition(null));
    }
  }, [currentConversationMessages]);

  const handleSendMessage = () => {
    if (senderId && currentRecipientId) {
      dispatch(setMessageScrollPosition(null));
      sendMessage(message.trim(), files);
      files && clear();
      setMessage('');
    }
  };

  if (isMobile) {
    return null;
  }

  return (
    <>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            right: 15,
            bottom: 60,
            width: 300,
            maxHeight: '70vh',
            borderRadius: '20px',
            background: 'white',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          }}
        >
          <Stack
            sx={{ borderRadius: '12px 12px 0  0' }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid lightgray"
            p={1}
          >
            <Typography fontWeight={600} m={1}>
              Messages
            </Typography>
            <Stack flexDirection="row">
              <IconButton onClick={() => navigate('/message')} size="small">
                <MoreHorizOutlinedIcon color="primary" />
              </IconButton>
              <IconButton onClick={() => setOpen(false)} size="small">
                <ExpandMoreIcon color="primary" />
              </IconButton>
            </Stack>
          </Stack>
          <Stack m={1}>
            {Object.values(conversations).map((groupe, index) => (
              <Box
                key={groupe.conversationId}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '10px',
                  '&:hover': {
                    background: theme.palette.primary.light,
                  },
                }}
                onClick={() => changeCurrentConversation(groupe.conversationId)}
                onMouseEnter={() => setSelectIndex(index)}
                onMouseLeave={() => setSelectIndex(null)}
              >
                <Display
                  title={
                    (contactUsers[groupe.recipientId]?.firstName || '') +
                    ' ' +
                    (contactUsers[groupe.recipientId]?.lastName || '')
                  }
                  value={groupe.messages?.length ? groupe.messages[0].content || '' : ''}
                  src={contactUsers[groupe.recipientId]?.avatarLink}
                  alert={groupe.unreadMessages}
                  select={selectIndex === index}
                  p={1}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      {currentConversationId && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: open ? 335 : 120,
            bottom: 0,
            width: 350,
            height: 450,
            p: 0,
            background: 'white',
            borderRadius: '12px 12px 0 0',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          }}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid lightgray"
            p={1}
          >
            <Display
              title={`${contactUsers[currentRecipientId]?.firstName || ''} ${
                contactUsers[currentRecipientId]?.lastName || ''
              }`}
              src={contactUsers[currentRecipientId]?.avatarLink}
              smallAvatar
            />
            <Stack flexDirection="row">
              <IconButton onClick={() => navigate('/message')} size="small">
                <MoreHorizOutlinedIcon color="primary" />
              </IconButton>
              <IconButton onClick={() => changeCurrentConversation('')} size="small">
                <CloseIcon color="primary" />
              </IconButton>
            </Stack>
          </Stack>
          <Stack ref={textareaRef} flexDirection="column-reverse" overflow="auto" flexGrow={1} p={1} gap={0.5}>
            {currentConversationMessages.map((msg) => {
              if (msg.messageType === 'file') {
                return (
                  <Stack
                    key={msg.messageId}
                    flexDirection={msg.senderId == senderId ? 'row-reverse' : 'row'}
                    alignItems="end"
                    gap={1}
                  >
                    <Avatar
                      sx={{ width: 30, height: 30, bgcolor: theme.palette.primary.main }}
                      src={
                        msg.senderId == senderId
                          ? sender?.avatarLink || senderAuthInfo?.picture
                          : contactUsers[currentRecipientId]?.avatarLink
                      }
                    />
                    <FileMessage
                      key={msg.messageId}
                      message={msg}
                      isFromSender={msg.senderId === senderId}
                      setScrollPosition={handleSetScrollPosition}
                    />
                  </Stack>
                );
              }
              return (
                <Stack
                  key={msg.messageId}
                  flexDirection={msg.senderId == senderId ? 'row-reverse' : 'row'}
                  alignItems="end"
                  gap={1}
                >
                  <Avatar
                    sx={{ width: 30, height: 30, bgcolor: theme.palette.primary.main }}
                    src={
                      msg.senderId == senderId
                        ? sender?.avatarLink || senderAuthInfo?.picture
                        : contactUsers[currentRecipientId]?.avatarLink
                    }
                  />
                  <TextMessage
                    key={msg.messageId}
                    message={msg}
                    isFromSender={msg.senderId === senderId}
                    setScrollPosition={handleSetScrollPosition}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Stack flexDirection="column" borderTop="1px solid lightgray">
            <Stack flexDirection="row" px={1} pt={1} gap={1} overflow="scroll">
              {files.map((file, i) => (
                <Tag
                  key={i}
                  style={{ whiteSpace: 'nowrap', flexShrink: 0 }}
                  label={file.name}
                  icon={
                    <IconButton type="button" size="small" color="error" onClick={() => removeFile(i)} disableRipple>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                />
              ))}
            </Stack>
            <Stack flexDirection="row" alignItems="end" gap={1} px={1} pb={2} pt={1}>
              <IconButton onClick={selectFile}>
                <DriveFolderUploadIcon color="primary" />
              </IconButton>
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  e.key === 'Enter' && handleSendMessage();
                }}
                placeholder="Aa"
                size="small"
                fullWidth
                multiline
                maxRows={5}
              />

              <IconButton onClick={handleSendMessage}>
                <SendIcon color="primary" />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      )}
      <Fab
        sx={{ position: 'fixed', right: 30, bottom: 30 }}
        variant="circular"
        color="primary"
        onClick={() => {
          !userProfile ? navigate('/createProfile') : setOpen(!open);
        }}
      >
        <Badge color="secondary" badgeContent={totalUnreadMessages}>
          <ChatOutlinedIcon />
        </Badge>
      </Fab>
    </>
  );
};
