import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Section } from 'components/Section';
import { AboutEditDialog } from 'components/Dialog/ProfileEditDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleAboutEditDialog } from 'state/dialogs/dialogsSlice';
import { getVisitProfileSelector } from 'state/profile/profileSlice';

interface AboutProps {
  editable: boolean;
}

export const About = ({ editable }: AboutProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const visitProfile = getVisitProfileSelector();

  return (
    <Section
      title={t('profile.about.title')}
      marginBottom={3}
      editable={editable}
      onEdit={() => dispatch(toggleAboutEditDialog(true))}
    >
      <Typography>{visitProfile.description}</Typography>
      <AboutEditDialog description={visitProfile.description} />
    </Section>
  );
};
