import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, Divider, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { Section, Display } from 'components';
import { Avatar } from 'components/Form';
import { ContactEditDialog } from 'components/Dialog/ProfileEditDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleContactEditDialog } from 'state/dialogs/dialogsSlice';
import { getVisitProfileSelector } from 'state/profile/profileSlice';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import { getGender } from 'utils';

interface ContactProps {
  editable: boolean;
}

export const Contact = ({ editable }: ContactProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const visitProfile = getVisitProfileSelector();

  const [checked, setChecked] = useState(visitProfile?.matchJob ?? false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Box sx={{ background: 'white', borderRadius: '20px', mb: { xs: 2, sm: 3 } }}>
      <Stack p={3} alignItems="center">
        <Avatar alt={visitProfile?.firstName} src={visitProfile?.avatarLink || ''} editable />
        <Typography fontSize={18} fontWeight={600} mt={2}>
          {visitProfile?.firstName} {visitProfile?.lastName}
        </Typography>
        <Typography>{visitProfile?.title}</Typography>
      </Stack>
      <Divider />
      <Section
        title={t('profile.info.title')}
        onEdit={() => dispatch(toggleContactEditDialog(true))}
        editable={editable}
        noBorder
      >
        <Display title={t('email')} value={visitProfile?.email} icon={<EmailOutlinedIcon />} marginBottom />
        <Display title={t('location')} value={visitProfile?.location} icon={<LocationOnRoundedIcon />} marginBottom />
        <Display title={t('phone')} value={visitProfile?.phoneNumber} icon={<LocalPhoneOutlinedIcon />} marginBottom />
        <Display
          title={t('birth')}
          value={moment(visitProfile?.birthdate).format('YYYY')}
          icon={<CakeOutlinedIcon />}
          marginBottom
        />
        <Display title={t('profile.gender')} value={getGender(visitProfile?.sexe)} icon={<WcOutlinedIcon />} />
      </Section>
      <Divider />
      <Stack padding={3}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} />}
          label={checked ? t('profile.match') : t('profile.noMatch')}
        />
      </Stack>
      <ContactEditDialog />
    </Box>
  );
};
