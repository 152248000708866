import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { GetSwipeUserPayload, ResetSwipeUserPayload, SwipeUser } from './types';
import { GET_SWIPE_USER, RESET_SWIPE_USER } from './actionTypes';
import { useAppSelector } from 'state/hooks';

// Thunks - async actions
export const getSwipeUserData = createAction(GET_SWIPE_USER, (payload: GetSwipeUserPayload) => {
  return {
    payload,
  };
});

export const resetSwipeUserData = createAction(RESET_SWIPE_USER, (payload: ResetSwipeUserPayload) => {
  return {
    payload,
  };
});

export interface SwipeUserState {
  pending: boolean;
  swipeUser: SwipeUser;
}

const initialState: SwipeUserState = {
  pending: false,
  swipeUser: { count: 0, results: [], showReset: false, totalPages: 1, currentIndex: -1 },
};

export const swipeUserSlice = createSlice({
  name: 'swipeUser',
  initialState,
  reducers: {
    setSwipeUser: (state, action: PayloadAction<SwipeUser>) => {
      state.swipeUser = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
  },
});

// Actions
export const { setSwipeUser, setPending } = swipeUserSlice.actions;

// Selectors
export const getSwipeUserSelector = (): SwipeUser => useAppSelector((state) => state.swipeUser.swipeUser);
