import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Section } from 'components';
import { PageNotFound } from 'PageNotFound';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import { getApiURL, getRoleNameById } from 'utils';
import { MemberInvitation } from 'types/invitationType';
import { theme } from 'theme';

export const OrgInvitation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentUrl = new URL(window.location.href);
  const invitationId = currentUrl.searchParams.get('invitationid');

  const { user, getAccessTokenSilently } = useAuth0();

  const [data, setData] = useState<MemberInvitation | null>(null);

  const fetchInvitationData = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Organization/GetOrganizationInvitationRequest/${invitationId}`), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();

    if (response.ok) {
      setData(res);
    }
  };

  useEffect(() => {
    fetchInvitationData();
  }, []);

  const onAcceptInvitation = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(`/OrganizationAccount/AcceptOrganizationInvitationRequest/${data?.organizationInvitation.id}`),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: data?.organizationInvitation.firstName,
          lastName: data?.organizationInvitation.lastName,
          email: data?.organizationInvitation.email,
        }),
      }
    );

    if (response.ok) {
      navigate('/admin');
    }
  };

  const onDeclineInvitation = async () => {
    const response = await fetch(
      getApiURL(`/OrganizationAccount/DeclineOrganizationInvitationRequest/${data?.organizationInvitation.id}`),
      {
        method: 'PATCH',
      }
    );

    if (response.ok) {
      navigate('/invitation/decline');
    }
  };

  if (data?.organizationInvitation.email !== user?.email) {
    return <PageNotFound />;
  } else {
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          gap: 6,
        }}
      >
        <Section px={{ xs: 2, sm: 6 }} py={{ xs: 2, sm: 4 }} gap={3}>
          <Stack
            sx={{ cursor: 'pointer' }}
            flexDirection="row"
            zIndex={1}
            alignSelf="center"
            onClick={() => navigate('/admin')}
          >
            <Typography fontSize={30} fontWeight={600}>
              Work
            </Typography>
            <Typography fontSize={30} fontWeight={600} color={theme.palette.primary.main}>
              inder
            </Typography>
          </Stack>
          <Typography>
            Hi {data?.organizationInvitation.firstName} {data?.organizationInvitation.lastName},
          </Typography>
          <Typography>
            <b>{data?.organization.name}</b> has invite you to be{' '}
            {['CEO', 'Director'].includes(getRoleNameById(data?.organizationInvitation.roleId) || '') ? 'a ' : 'an '}
            <b>{getRoleNameById(data?.organizationInvitation.roleId)}</b> on their Workinder account.
          </Typography>
          <Typography>
            To accept this prestigious invitation, please click <b>Accept</b> below. We look forward to your leadership
            and strategic vision in advancing {data?.organization.name}' success and innovation. Welcome to the team.
          </Typography>
          <Button endIcon={<TrendingFlatRoundedIcon />} onClick={onAcceptInvitation}>
            Accept Invitation
          </Button>
          <Button sx={{ mb: 3 }} variant="outlined" onClick={onDeclineInvitation}>
            Decline
          </Button>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, background: theme.palette.grey[400], p: 2 }}>
            <Typography fontSize={12}>
              Companies around the world use Workinder, a platform that leverages artificial intelligence to
              revolutionize recruitment and talent management.
            </Typography>
            <Typography fontSize={12}>
              To learn more about {data?.organization.name} please visite our website - {data?.organization.website}
            </Typography>
          </Box>
        </Section>
        <Typography
          sx={{ color: theme.palette.grey[700] }}
          fontSize="12px"
          textAlign={{ xs: 'center', md: 'start' }}
          ml={{ xs: 0, md: 3 }}
          my={1}
        >
          {t('footerRight')}
        </Typography>
      </Container>
    );
  }
};
