import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_VISIT_PROFILE, PROFILE_CREATE, PROFILE_RESET, PROFILE_REQUEST } from './actionTypes';
import { createProfile, getProfileById, resetProfile } from './apis';
import { ProfileCreate, ProfileRefresh, ProfileRequest } from './types';
import { UserProfile } from 'types/profileType';
import { setProfilePending, setUserProfile, setVisitProfile } from './profileSlice';

function* resetProfileSaga(action: ProfileRefresh) {
  try {
    yield put(setProfilePending(true));

    const response: UserProfile = yield call(resetProfile, {
      userId: action.payload.userId,
      token: action.payload.token,
      user: action.payload.user,
    });

    yield put(setUserProfile(response));
  } catch (e) {
    console.log(e);
  }
}

function* createProfileSaga(action: ProfileCreate) {
  try {
    yield put(setProfilePending(true));

    const response: UserProfile = yield call(createProfile, {
      userId: action.payload.userId,
      token: action.payload.token,
      user: action.payload.user,
    });

    // yield call(assignRoles, {
    //   userId: action.payload.userId,
    //   token: action.payload.token,
    //   roles: ['rol_i73KtRIaetZOGeto'],
    // });

    yield put(setUserProfile(response));
    action.payload.navigate && action.payload.navigate(`/profile?profileId=${response.id}`);
  } catch (e) {
    yield put(setProfilePending(false));
    console.log(e);
    action.payload.showSnackbar &&
      action.payload.showSnackbar('Error, please verify all information is enter correctly...', 'error');
    console.log(e);
  }
}

function* getProfileSaga(action: ProfileRequest) {
  try {
    yield put(setProfilePending(true));

    const response: UserProfile = yield call(getProfileById, {
      userId: action.payload.userId,
      token: action.payload.token,
    });

    // const roles: Role[] = yield call(getUserRoles, {
    //   userId: action.payload.userId,
    //   token: action.payload.token,
    // });

    // console.log('roles: ', roles);

    yield put(setUserProfile(response));
  } catch (e) {
    console.log(e);
  }
}

// function* getRolesSaga(action: RolesRequest) {
//   try {
//     const response: Role[] = yield call(getAllRoles, {
//       token: action.payload.token,
//     });

//     yield put(setRoles(response));
//   } catch (e) {
//     console.log(e);
//   }
// }

function* getVisitProfileSaga(action: ProfileRequest) {
  try {
    // yield put(setProfilePending(true));

    const response: UserProfile = yield call(getProfileById, {
      userId: action.payload.userId,
      token: action.payload.token,
    });

    yield put(setVisitProfile(response));
    yield put(setProfilePending(false));
  } catch (e) {
    console.log(e);
  }
}

export function* profileSaga() {
  yield all([takeLatest(PROFILE_CREATE, createProfileSaga)]);
  yield all([takeLatest(PROFILE_RESET, resetProfileSaga)]);
  yield all([takeLatest(PROFILE_REQUEST, getProfileSaga)]);
  yield all([takeLatest(GET_VISIT_PROFILE, getVisitProfileSaga)]);
  // yield all([takeLatest(GET_ROLES, getRolesSaga)]);
}
