import axios from 'axios';
import { ProfilePayload } from './types';
import { UserProfile } from 'types/profileType';
import { getApiURL } from 'utils';

// export const assignRoles = async (payload: RolesPayload) => {
//   const { data } = await axios.post(getApiURL(`/users/${payload.userId}/roles`), JSON.stringify(payload.roles), {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${payload.token}`,
//     },
//   });
//   return data;
// };

// export const getAllRoles = async (payload: RolesPayload) => {
//   const { data } = await axios.get(getApiURL('/roles'), {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${payload.token}`,
//     },
//   });
//   return data;
// };

// export const getUserRoles = async (payload: RolesPayload) => {
//   const { data } = await axios.get(`https://recruitment-stage.paniclake.ca/users/${payload.userId}/roles`, {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${payload.token}`,
//     },
//   });
//   return data;
// };

export const createProfile = async (payload: ProfilePayload) => {
  const { data } = await axios.post(
    getApiURL(`/UserProfile/CreateUserProfileRequest/${payload.userId}`),
    JSON.stringify(payload.user),
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return data;
};

export const resetProfile = async (payload: ProfilePayload) => {
  const { data } = await axios.patch(getApiURL(`/UserProfile/ResetUserProfileRequest`), JSON.stringify(payload.user), {
    headers: {
      Authorization: `Bearer ${payload.token}`,
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const getProfileById = async (payload: ProfilePayload) => {
  const { data } = await axios.get(
    getApiURL(`/UserProfile/GetUserProfileByIdRequest/${payload.userId}`, {
      IncludeEducations: true,
      IncludeExperiences: true,
      IncludeSkills: true,
      IncludeLanguages: true,
      IncludeFiles: true,
      IncludeOrganization: true,
      IncludeMatches: true,
    }),
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );
  return data;
};

export const getOtherUserProfiles = async ({ userIds, token }: { userIds: string[]; token: string }) => {
  const pageSize = 5;
  const resultArray = [];
  for (let i = 0; i < userIds.length; i += pageSize) {
    resultArray.push(userIds.slice(i, i + pageSize));
  }

  const userProfileRequests = resultArray.map(async (batchUserIds) => {
    const { data } = await axios.get(
      getApiURL(`/UserProfile/GetAllUserProfileRequest`, {
        IncludeEducations: true,
        IncludeExperiences: true,
        IncludeSkills: true,
        IncludeLanguages: true,
        IncludeFiles: true,
        PageSize: pageSize,
        PageNumber: 1, // Set the appropriate page number for each batch
        ids: JSON.stringify(batchUserIds).replace(/[[\]"]+/g, ''),
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  });

  const userData = await Promise.all(userProfileRequests);

  let result: UserProfile[] = [];
  userData.forEach(({ results }) => {
    result = [...result, ...results];
  });

  const filteredResult = result.filter((userProfile) => userProfile.id);

  /* eslint-disable-next-line */
  // @ts-ignore
  const uniqueUserProfiles = filteredResult.reduce((acc: Record<string, UserProfile>, curr) => {
    if (curr.id) {
      acc[curr.id] = curr;
      return acc;
    }
  }, {});

  return uniqueUserProfiles;
};
