import Amplify from '@aws-amplify/core';
import { config, subscribeMessages } from './apis';
import { receiveMessage } from 'state/chat/chatSlice';
import {
  getConversationsData,
  getConversations as getConversationsSelector,
  getConversationMessagesData,
  getConversationsFetched,
  getCurrentRecipientId,
  getCurrentConversationId,
  sendMessage,
  getConversationMessages,
  getCurrentConversation,
  setCurrentConversationId,
  lookForConversationByRecipientId,
  getTotalUnreadMessages,
  getContactUsers,
  getConversationNextPageMessagesData,
  getMessagesLoading,
  downloadFile,
  deleteFileMessage,
  deleteMessage
} from 'state/chat/chatSlice';

import { getUserProfileSelector } from 'state/profile/profileSlice';
import { useAppDispatch } from 'state/hooks';
import { useAuth0 } from '@auth0/auth0-react';

export const useChat = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const conversationsFetched = getConversationsFetched();
  const currentRecipientId = getCurrentRecipientId();
  const currentConversationId = getCurrentConversationId();
  const currentConversation = getCurrentConversation();
  const conversationMessages = getConversationMessages();
  const conversations = getConversationsSelector();
  const userProfile = getUserProfileSelector();
  const totalUnreadMessages = getTotalUnreadMessages();
  const contactUsers = getContactUsers();
  const messagesLoading = getMessagesLoading();

  const init = (userId: string) => {
    // Initialize Amplify
    Amplify.configure(config);

    // Set up subscription
    const subscription = subscribeMessages(userId, (data) => {
      getAccessTokenSilently().then((token) => {
        dispatch(receiveMessage({ message: data, token, actionType: data.actionType }));
      });
    });
    return subscription;
  };

  const getConversations = (userId: string) => {
    if (userId || userProfile.id) {
      getAccessTokenSilently().then((token) => {
        userProfile.id && dispatch(getConversationsData({ userId: userId || userProfile.id, token }));
      });
    }
  };

  const handleConversationMessages = () => {
    if (!currentConversationId) {
      return;
    }
    dispatch(getConversationMessagesData({ conversationId: currentConversationId }));
  };

  const handleConversationNextPageMessages = () => {
    if (!currentConversationId || !currentConversation.nextToken) {
      return;
    }
    dispatch(
      getConversationNextPageMessagesData({
        conversationId: currentConversationId,
        nextToken: currentConversation.nextToken,
      })
    );
  };

  const handleSendMessage = (message = "", files: File[]) => {
    getAccessTokenSilently().then((token) => {
      if (userProfile.id && currentRecipientId) {

        const recipientEmail = contactUsers[currentRecipientId]?.email || "";

        const messagePayload = {
          conversationId: currentConversationId,
          senderId: userProfile.id,
          recipientId: currentRecipientId,
          content: message,
          messageType: 'text',
        };

        dispatch(
          sendMessage({
            messagePayload,
            files,
            token,
            recipientEmail
          })
        );
      }
    });


  };

  const handleSetCurrentConversationId = (conversationId: string) => {
    dispatch(setCurrentConversationId(conversationId));
  };

  const handleLookForConversationByRecipientId = (recipientId: string) => {
    if (conversationsFetched) {
      getAccessTokenSilently().then((token) => {
        dispatch(lookForConversationByRecipientId({ recipientId, token }));
      });
    }
  };

  const handleDownloadFile = ({ key, name, messageId, createdAt }: { key: string, name: string, messageId: string, createdAt: string }) => {
    getAccessTokenSilently().then((token) => {
      dispatch(downloadFile({ key, token, name, messageId, createdAt }));
    });
  }

  const handleRemoveFileMessage = ({ messageId, createdAt, key }: { messageId: string, createdAt: string, key: string }) => {
    getAccessTokenSilently().then((token) => {
      dispatch(deleteFileMessage({ key, token, messageId, createdAt }));
    });
  }

  const handleRemoveMessage = ({ messageId, createdAt }: { messageId: string, createdAt: string }) => {
    dispatch(deleteMessage({ messageId, createdAt }));
  }

  const senderInitials = (userProfile.firstName ? userProfile.firstName[0] : "") + (userProfile.lastName ? userProfile.lastName[0] : "");

  return {
    init,
    conversations,
    getConversations,
    getConversationMessages: handleConversationMessages,
    getConversationNextPageMessages: handleConversationNextPageMessages,
    currentRecipientId,
    senderId: userProfile.id,
    sender: { ...userProfile, initials: senderInitials },
    senderAuthInfo: user,
    conversationsFetched,
    sendMessage: handleSendMessage,
    currentConversationId,
    currentConversation,
    currentConversationMessages: conversationMessages,
    changeCurrentConversation: handleSetCurrentConversationId,
    lookForConversationByRecipientId: handleLookForConversationByRecipientId,
    totalUnreadMessages,
    contactUsers,
    messagesLoading,
    downloadFile: handleDownloadFile,
    removeFileMessage: handleRemoveFileMessage,
    removeMessage: handleRemoveMessage,
  };
};
