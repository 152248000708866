import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Box, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel } from '@mui/material';

type CustomInputProps = MuiCheckboxProps & {
  sx?: object;
  name: string;
  label?: string;
  control: Control<FieldValues, any>;
  marginBottom?: number;
};

export const Checkbox = ({ sx, name, label, control, marginBottom, ...props }: CustomInputProps) => {
  return (
    <Box component="div" mb={marginBottom ? marginBottom : 0}>
      <FormControlLabel
        sx={{ ...sx }}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => (
              <MuiCheckbox {...props} checked={value} onChange={(e) => onChange(e.target.checked)} disableRipple />
            )}
          />
        }
        label={label}
      />
    </Box>
  );
};
