import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchAllJobs, fetchAllJobsById, fetchJobDescriptionById } from './apis';
import { setJobDescription, setJobPending, setJobShortList } from './jobSlice';
import { FetchAllJobsByIdRequest, FetchAllJobsRequest, FetchJobDescriptionRequest, JobList } from './types';
import { GET_ALL_JOBS, GET_ALL_JOBS_ADMIN, GET_JOB_DESCRIPTION } from './actionTypes';
import { JobDescription } from 'types/jobType';

function* getJobDescriptionSaga(action: FetchJobDescriptionRequest) {
  try {
    const response: JobDescription = yield call(fetchJobDescriptionById, {
      jobId: action.payload.jobId,
    });

    yield put(setJobDescription(response));
  } catch (e) {
    console.log(e);
  }
}

function* getAllJobsSaga(action: FetchAllJobsRequest) {
  try {
    yield put(setJobPending(true));

    const response: JobList = yield call(fetchAllJobs, {
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      filter: action.payload.filter,
      location: action.payload.location,
      isFullTime: action.payload.isFullTime,
      isPartTime: action.payload.isPartTime,
      isContract: action.payload.isContract,
      isInternship: action.payload.isInternship,
      salaryMin: action.payload.salaryMin,
      salaryMax: action.payload.salaryMax,
    });

    yield put(setJobShortList(response));
    yield put(setJobPending(false));
  } catch (e) {
    console.log(e);
  }
}

function* getAllJobsByIdSaga(action: FetchAllJobsByIdRequest) {
  try {
    yield put(setJobPending(true));

    const response: JobList = yield call(fetchAllJobsById, {
      token: action.payload.token,
      organizationId: action.payload.organizationId,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      filter: action.payload.filter,
    });

    yield put(setJobShortList(response));
    yield put(setJobPending(false));
  } catch (e) {
    console.log(e);
  }
}

export function* jobSaga() {
  yield all([takeLatest(GET_JOB_DESCRIPTION, getJobDescriptionSaga)]);
  yield all([takeLatest(GET_ALL_JOBS, getAllJobsSaga)]);
  yield all([takeLatest(GET_ALL_JOBS_ADMIN, getAllJobsByIdSaga)]);
}
