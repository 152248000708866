import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';

interface AdminState {
  member: number;
  applicant: number;
}

const initialState: AdminState = {
  member: 1,
  applicant: 0,
};

export const adminSlice = createSlice({
  name: 'adminState',
  initialState,
  reducers: {
    setMember(state, action: PayloadAction<number>) {
      state.member = action.payload;
    },
  },
});

export const { setMember } = adminSlice.actions;

export const getAdminState = (): AdminState => useAppSelector((state) => state.admin);
