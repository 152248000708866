import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { Tag } from 'components';
import { LocationAutocomplete } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { showLocationEditDialog, toggleLocationEditDialog } from 'state/dialogs/jobDialogsSlice';
import { getJobDescription, getJobDescriptionData } from 'state/job/jobSlice';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { getApiURL } from 'utils';
import { theme } from 'theme';

export const LocationEditDialog = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showLocationEditDialog();
  const jobDescription = getJobDescription();
  const onClose = () => {
    setLocation('');
    setLocations([]);
    dispatch(toggleLocationEditDialog(false));
  };

  const [location, setLocation] = useState('');
  const [locations, setLocations] = useState<string[]>([]);

  const updateLocation = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Job/AddJobLocationsRequest'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobId: jobDescription.id, names: locations }),
    });

    if (response.ok) {
      jobDescription.id && dispatch(getJobDescriptionData({ jobId: jobDescription.id }));
      onClose();
    }
  };

  const onLocationAdd = () => {
    setLocations([...locations, location]);
    setLocation('');
  };

  const onLocationDelete = (location: string) => {
    setLocations((current) => current.filter((item) => item !== location));
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        {t('profile.language.add')}
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <LocationAutocomplete
            value={location}
            onChange={(e, newValue) => setLocation(newValue as string)}
            inputValue={location}
            onInputChange={(e, newValue) => setLocation(newValue)}
          />
          <IconButton
            sx={{ background: theme.palette.grey[400] }}
            color="primary"
            onClick={() => {
              onLocationAdd();
              setLocation('');
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" flexWrap="wrap">
          {locations.map((location, index) => (
            <Tag
              key={index}
              sx={{ m: '10px 5px 0 5px' }}
              label={location}
              color="#1976d2"
              background="#e3f2fd"
              icon={
                <IconButton
                  type="button"
                  size="small"
                  color="error"
                  onClick={() => onLocationDelete(location)}
                  disableRipple
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button type="button" onClick={updateLocation} disabled={!locations.length}>
          {t('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
