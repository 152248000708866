import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Stack } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'components/Snackbar';
import { useAppDispatch } from 'state/hooks';
import { showInterviewDialog, toggleInterviewDialog } from 'state/dialogs/jobDialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import moment, { Moment } from 'moment';
import { getApiURL } from 'utils';

interface InterviewDialogProps {
  applicationId: string;
}

type Interview = {
  name: string;
  date: Moment;
  meetingUrl: string;
};

export const InterviewDialog = ({ applicationId }: InterviewDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const open = showInterviewDialog();
  const onClose = () => dispatch(toggleInterviewDialog(false));

  const [interview, setInterview] = useState<Interview>({
    name: '',
    date: moment(),
    meetingUrl: '',
  });

  const sendInterview = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/SendInterviewApplicationStatusRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ InterviewRequests: [{ ...interview, applicationId }] }),
    });

    if (response.ok) {
      showSnackbar('Schedule Interview send', 'success');
      window.location.reload();
    } else {
      showSnackbar('Cannot schedule meeting at the moment, try again later', 'error');
    }

    setInterview({ name: '', date: moment(), meetingUrl: '' });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        SCHEDULE INTERVIEW
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <TextField
            label="Title"
            value={interview.name}
            onChange={(e) => setInterview({ ...interview, name: e.target.value })}
            required
          />
          <TextField
            label="Meeting URL"
            value={interview.meetingUrl}
            onChange={(e) => setInterview({ ...interview, meetingUrl: e.target.value })}
            required
          />
          <Stack flexDirection="row" gap={2} alignItems="stretch">
            <DatePicker
              label="Date"
              value={interview.date}
              onChange={(date) => setInterview({ ...interview, date: date ?? moment() })}
            />
            <TimePicker
              label="Time"
              value={interview.date}
              onChange={(date) => setInterview({ ...interview, date: date ?? moment() })}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={sendInterview} fullWidth>
          {t('button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
