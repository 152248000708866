import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { useAppDispatch } from 'state/hooks';
import { toggleApplyDialog } from 'state/dialogs/dialogsSlice';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { JobShort } from 'types/jobType';
import { theme } from 'theme';

interface JobShortInformationProps {
  data: JobShort;
}

export const JobShortRow = ({ data }: JobShortInformationProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const jobTypes = [
    { key: 'isFullTime', label: t('jobType.fullTime') },
    { key: 'isPartTime', label: t('jobType.partTime') },
    { key: 'isContract', label: t('jobType.contract') },
    { key: 'isInternship', label: t('jobType.internship') },
  ];

  const onOpen = () => dispatch(toggleApplyDialog(true));

  return (
    <Section
      sx={{ height: '265px', overflow: 'hidden' }}
      onClick={() => {
        navigate(`/job?jobId=${data?.id}`);
        window.scrollTo(0, 0);
      }}
    >
      <Stack flexDirection={{ xs: undefined, sm: 'row' }}>
        <Avatar
          sx={{
            mr: 2,
            height: 50,
            width: 50,
            borderRadius: '15px',
            background: theme.palette.primary.main,
            color: 'white',
          }}
          alt={data.companyName}
          src="/"
        />
        <Stack overflow="hidden">
          <Typography color={theme.palette.grey[700]} noWrap>
            {data.companyName}
          </Typography>
          <Typography fontSize={16} fontWeight={600} noWrap>
            {data.title}
          </Typography>
          {data.locations.length !== 0 && (
            <Stack flexDirection="row" mt={0.5}>
              <LocationOnRoundedIcon color="error" fontSize="small" />
              <Typography ml={0.5} color={theme.palette.grey[600]}>
                {data.locations[0].name}
              </Typography>
            </Stack>
          )}
        </Stack>
        {/* <IconButton
          sx={{ alignSelf: 'center' }}
          onClick={(e) => {
            dispatch(setSelectedJobId(data.id));
            onOpen();
            e.stopPropagation();
          }}
        >
          <SendOutlinedIcon color="secondary" />
        </IconButton> */}
      </Stack>
      <Divider sx={{ my: 2 }} flexItem />
      <Stack flexDirection="row" flexWrap="wrap" gap={1}>
        {jobTypes.map(
          (job) =>
            data[job.key as keyof JobShort] && (
              <Tag
                key={job.key}
                label={job.label}
                color={theme.palette.text.primary}
                background={theme.palette.grey[300]}
              />
            )
        )}
      </Stack>
      <Typography
        sx={{
          fontSize: 14,
          overflow: 'hidden',
          maxHeight: '135px',
          color: theme.palette.grey[600],
        }}
        dangerouslySetInnerHTML={{
          __html: data.description === '' ? 'There is no description for this job' : data.description,
        }}
      />
    </Section>
  );
};
