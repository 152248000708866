import { Ball, BallContainer } from './LoadingAnimation.styled';

export const LoadingAnimation = () => {
  return (
    <BallContainer>
      <Ball />
      <Ball />
      <Ball />
    </BallContainer>
  );
};
