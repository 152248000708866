import moment from 'moment';
import { UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { UserProfile } from 'types/profileType';

interface ReviewProps {
  watch: UseFormWatch<UserProfile>;
  setActiveStep: (num: number) => void;
  setIsReview: (boo: boolean) => void;
}

export const Review = ({ watch, setActiveStep, setIsReview }: ReviewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        title={t('createProfile.step2')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(1);
        }}
        editable
        marginBottom={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('name')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {watch('firstName')} {watch('lastName')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('profile.birthdate')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('birthdate') && moment(watch('birthdate')).format('L')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('email')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('email')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('phone')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('phoneNumber')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('location')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('location')}</Typography>
          </Grid>
        </Grid>
      </Section>
      <Section
        title={t('createProfile.step3')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(2);
        }}
        editable
        marginBottom={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Description</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('description')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('profile.language.title')}</Typography>
          </Grid>
          <Grid container item xs={9} display="flex" alignItems="start" gap={1}>
            {watch('languages')?.map((item, index) => (
              <Tag key={index} label={item as string} color="#1976d2" background="#e3f2fd" />
            ))}
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('profile.skill.title')}</Typography>
          </Grid>
          <Grid container item xs={9} display="flex" alignItems="start" gap={1}>
            {watch('skills')?.map((item, index) => (
              <Tag key={index} label={item as string} />
            ))}
          </Grid>
        </Grid>
      </Section>
      <Section
        title={t('createProfile.step4')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(3);
        }}
        editable
        marginBottom={3}
      >
        <Stack spacing={1.5} divider={<Divider />}>
          {watch('experiences')?.map((experience) => (
            <Grid
              key={experience.id}
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography fontWeight={600}>{experience.role}</Typography>
                <Typography>{experience.companyName}</Typography>
                <Typography>{experience.taskDescription}</Typography>
                <Typography>{experience.location}</Typography>
                <Stack flexDirection="row" gap={0.5}>
                  {experience.dateStart && <Typography>{moment(experience.dateStart).format('MMM YYYY')} </Typography>}
                  {(experience.dateStart && experience.dateEnd) || (experience.dateStart && experience.isPresent) ? (
                    <Typography>-</Typography>
                  ) : null}
                  {experience.dateEnd && <Typography>{moment(experience.dateEnd).format('MMM YYYY')}</Typography>}
                  {experience.isPresent && <Typography>{t('present')}</Typography>}
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Section>
      <Section
        title={t('createProfile.step5')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(4);
        }}
        editable
        marginBottom={3}
      >
        <Stack spacing={1.5} divider={<Divider />}>
          {watch('educations')?.map((education) => (
            <Grid
              key={education.id}
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography fontWeight={600}>{education.schoolName}</Typography>
                <Typography>{education.diploma} </Typography>
                <Typography>{education.subject}</Typography>
                <Stack flexDirection="row" gap={0.5}>
                  {education.dateStart && <Typography>{moment(education.dateStart).format('YYYY')} </Typography>}
                  {(education.dateStart && education.dateEnd) || (education.dateStart && education.isPresent) ? (
                    <Typography>-</Typography>
                  ) : null}
                  {education.dateEnd && <Typography>{moment(education.dateEnd).format('YYYY')}</Typography>}
                  {education.isPresent && <Typography>{t('present')}</Typography>}
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Section>
    </>
  );
};
