import { useState } from 'react';
import { Control, UseFormWatch, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { TextField, ToggleButton, LocationAutocomplete, Select } from 'components/Form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { JobDescription } from 'types/jobType';
import { theme } from 'theme';

interface InformationProps {
  control: Control<JobDescription, any>;
  watch: UseFormWatch<JobDescription>;
}

export const Information = ({ control, watch }: InformationProps) => {
  const { t } = useTranslation();

  const {
    fields: locationsFields,
    append: locationsAppend,
    remove: locationsRemove,
  } = useFieldArray({
    control,
    name: 'locations',
  });

  const [location, setLocation] = useState<string>('');

  return (
    <Section>
      <TextField name="title" label={t('jobLabel.title')} control={control} marginBottom={5} required />
      <TextField name="companyName" label={t('jobLabel.company')} control={control} marginBottom={5} required />
      <Stack flexDirection="row" alignItems="end" gap={2}>
        <TextField
          name="salaryMin"
          label={t('jobLabel.salary')}
          control={control}
          startAdornment={
            <InputAdornment position="start">
              <Typography>$</Typography>
            </InputAdornment>
          }
          placeholder="Min"
          marginBottom={5}
          textAlign="right"
          required
        />
        <TextField
          name="salaryMax"
          control={control}
          startAdornment={
            <InputAdornment position="start">
              <Typography>$</Typography>
            </InputAdornment>
          }
          placeholder="Max"
          marginBottom={5}
          textAlign="right"
          required
        />
        <Select
          name="salaryType"
          control={control}
          options={[
            { value: 1, label: t('perHour') },
            { value: 2, label: t('perYear') },
          ]}
          marginBottom={5}
          required
        />
      </Stack>
      <Typography mb="5px">Workplace Type</Typography>
      <Stack direction="row" gap={2} mb={5}>
        <ToggleButton name="isOnSite" control={control} label={t('workplaceType.onSite')} />
        <ToggleButton name="isRemote" control={control} label={t('workplaceType.remote')} />
        <ToggleButton name="isHybrid" control={control} label={t('workplaceType.hybrid')} />
      </Stack>
      <Grid container alignSelf="flex-start" mb={5}>
        <Grid item xs={12}>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <LocationAutocomplete
              label="Locations"
              value={location ?? ''}
              onChange={(e, newValue) => setLocation(newValue as string)}
              inputValue={location ?? ''}
              onInputChange={(e, newValue) => setLocation(newValue)}
            />
            <IconButton
              sx={{ background: theme.palette.grey[400], mt: 3 }}
              color="primary"
              onClick={() => {
                locationsAppend(location);
                setLocation('');
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid container item xs={12} flexDirection="row">
          {locationsFields.map((location, index) => (
            <Tag
              key={location.id}
              sx={{ m: '10px 5px 0 5px' }}
              label={watch(`locations.${index}`) as string}
              icon={
                <IconButton size="small" color="error" onClick={() => locationsRemove(index)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            />
          ))}
        </Grid>
      </Grid>
      <Typography mb="5px">Job Type</Typography>
      <Stack direction="row" gap={2} mb={5}>
        <ToggleButton name="isFullTime" control={control} label={t('jobType.fullTime')} />
        <ToggleButton name="isPartTime" control={control} label={t('jobType.partTime')} />
        <ToggleButton name="isContract" control={control} label={t('jobType.contract')} />
        <ToggleButton name="isInternship" control={control} label={t('jobType.internship')} />
      </Stack>
      <Stack flexDirection="row" alignItems="end" gap={2}>
        <TextField name="experienceMin" label="Experience" control={control} placeholder="Min" required />
        <TextField name="experienceMax" control={control} placeholder="Max" required />
      </Stack>
    </Section>
  );
};
