import { green, orange, red } from '@mui/material/colors';

export const getInvitationStyles = (status: number) => {
  switch (status) {
    case 0:
      return { color: orange[700], background: orange[50] };
    case 1:
      return { color: green[700], background: green[50] };
    case 2:
      return { color: red[700], background: red[50] };
  }
};
