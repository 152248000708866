import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Checkbox, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Tag } from './Tag';
import { toggleNoteDialog } from 'state/dialogs/dialogsSlice';
import { useAppDispatch } from 'state/hooks';
import { toggleInterviewDialog } from 'state/dialogs/jobDialogsSlice';
import NoteIcon from '@mui/icons-material/Note';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import { Applicant, ApplicationStatus } from 'types/applicantType';
import { getApplicantStyles, getApiURL } from 'utils';

interface ApplicantRowProps {
  data: Applicant;
  checked: boolean;
  setChecked: (id: string) => void;
  setSelectApplicantId: (id: string) => void;
  setOrganizationNote: (msg: string) => void;
}

export const ApplicantRow = ({
  data,
  checked,
  setChecked,
  setSelectApplicantId,
  setOrganizationNote,
}: ApplicantRowProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const [showMsg, setShowMsg] = useState(false);

  const onDownloadCV = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/File/DownloadFileByIdRequest/${data.fileInformation?.id}`), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${data.userName}_CV.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const acceptApplicants = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/AcceptApplicationsRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ApplicationIds: [data.id] }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  const rejectApplicants = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/DeclineApplicationRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ApplicationIds: [data.id] }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '20px',
      }}
      p={2}
      marginBottom={2}
      borderRadius={showMsg ? '20px 20px 0 20px' : '20px'}
      onMouseLeave={() => setShowMsg(false)}
    >
      <Grid container alignItems="center">
        <Grid container item xs={3.5} display="flex" alignItems="center" gap={1}>
          <Checkbox checked={checked} onChange={() => setChecked(data.id)} />
          <Typography>{data.userName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{data.email}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Tag
            label={ApplicationStatus[data.status]}
            color={getApplicantStyles(data.status)?.color}
            background={getApplicantStyles(data.status)?.background}
          />
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center">
          <Typography>{data.score ? `${(data.score * 100).toFixed()} %` : 'N/A'}</Typography>
        </Grid>
        <Grid item xs={2.5} display="flex" justifyContent="end" alignItems="center">
          <Tooltip title="Download CV">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onDownloadCV();
              }}
            >
              <FileDownloadRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notes">
            <IconButton
              color="primary"
              onClick={async (e) => {
                e.stopPropagation();
                setOrganizationNote(data.organizationNote ?? '');
                setSelectApplicantId(data.id);
                dispatch(toggleNoteDialog(true));
              }}
            >
              <NoteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send Interview">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setSelectApplicantId(data.id);
                dispatch(toggleInterviewDialog(true));
              }}
            >
              <VideocamRoundedIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            color="primary"
            onClick={() => setShowMsg(!showMsg)}
            disabled={data.status !== 0 && data.status !== 1}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      {showMsg && (
        <Box
          sx={{
            background: 'white',
            display: 'flex',
            gap: 1,
            position: 'absolute',
            right: '39px',
            transform: 'translateY(-42px)',
            zIndex: 1,
          }}
        >
          <Button
            sx={{ mb: 1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              acceptApplicants();
            }}
            variant="outlined"
            size="small"
          >
            Accept
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              rejectApplicants();
            }}
            variant="outlined"
            size="small"
          >
            Reject
          </Button>
        </Box>
      )}
    </Box>
  );
};
