import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { InviteeRow, UserOrgRow } from 'components';
import { InviteDialog } from 'components/Dialog';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import { useAppDispatch } from 'state/hooks';
import { toggleInviteDialog } from 'state/dialogs/jobDialogsSlice';
import { getUserOrgSelector } from 'state/organization/organizationSlice';
import { getApiURL } from 'utils';
import { UserOrg } from 'types/organizationType';
import { theme } from 'theme';

export const TeamManagement = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const userOrg = getUserOrgSelector();

  const [subordinates, setSubordinates] = useState<UserOrg[]>([]);
  const [invitees, setInvitees] = useState<any[]>([]);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [pages, setPages] = useState<number>(1);

  const PER_PAGE = 15;

  const fetchOrganizationAccount = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(`/OrganizationAccount/GetOrganizationAccountByOrganizationRequest`, {
        PageNumber: pages,
        PageSize: PER_PAGE,
        ShowInactive: showInactive,
      }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then((res) => res.json());

    setSubordinates(response.results);
  };

  useEffect(() => {
    fetchOrganizationAccount();
  }, [showInactive, pages]);

  const fetchOrganizationInvitation = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(`/Organization/GetOrganizationInvitationsByOrganizationRequest/${userOrg.organizationId}`, {
        PageNumber: pages,
        PageSize: PER_PAGE,
        ShowInactive: false,
        Status: 'Pending',
      }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then((res) => res.json());

    setInvitees(response);
  };

  useEffect(() => {
    if (userOrg.organizationId) {
      fetchOrganizationInvitation();
    }
  }, [userOrg.organizationId]);

  return (
    <Box>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" fontWeight="bold">
          Team Management
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Button endIcon={<PersonAddAlt1OutlinedIcon />} onClick={() => dispatch(toggleInviteDialog(true))}>
            Invite
          </Button>
        </Stack>
      </Stack>
      <Grid container sx={{ mb: 2, borderRadius: '20px', background: theme.palette.primary.light }} p={2}>
        <Grid item xs={4}>
          <Typography fontWeight={600}>{t('name')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>{t('email')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={600}>{t('profile.experience.role')}</Typography>
        </Grid>
      </Grid>
      {invitees.map((sub: any) => (
        <InviteeRow key={sub.id} data={sub} />
      ))}
      {invitees.length > 0 && <Divider sx={{ m: 4 }} />}
      {subordinates
        .sort((a, b) => (a.firstName || '').localeCompare(b.firstName || ''))
        .map((sub: any) => (
          <UserOrgRow key={sub.id} data={sub} />
        ))}
      <InviteDialog />
    </Box>
  );
};
