import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Home } from 'Home';
import { Swipe } from 'Swipe';
import { Match } from 'Match';
import { Job } from 'Job';
import { SmartKnowledgeHub } from 'SmartKnowledgeHub';
import { SmartJobHub } from 'SmartJobHub';
import { CreateProfile } from 'CreateProfile';
import { Profile } from 'Profile';
import { Message } from 'Message';
import { UploadResume } from 'UploadResume';
import { Support } from 'Support';
import { Application } from 'Application';
import { Login } from 'Login';
import { Donation, PaymentCompleted } from 'Donation';
import { PageNotFound } from 'PageNotFound';
import { Admin, CreateJob, Jobs, Organization, Applicant, OrgInvitation, Calendar } from 'Admin';
import { DeclineInvitation } from 'Admin/OrgInvitation/Section';
import { AdminLayout, AuthenticationGuard, Layout } from 'components';

export const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('isFirstVisit');

    if (!isFirstVisit) {
      localStorage.setItem('isFirstVisit', 'true');
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/job" element={<Job />} />
        <Route path="/support" element={<Support />} />
        <Route path="/profile" element={<AuthenticationGuard component={Profile} />} />
        <Route path="/createProfile" element={<AuthenticationGuard component={CreateProfile} />} />
        {/* <Route path="/swipe" element={<AuthenticationGuard component={Swipe} />} /> */}
        <Route path="/smartjobhub" element={<AuthenticationGuard component={SmartJobHub} />} />
        <Route path="/smartknowledgehub" element={<SmartKnowledgeHub />} />
        {/* <Route path="/match" element={<AuthenticationGuard component={Match} />} /> */}
        <Route path="/message" element={<AuthenticationGuard component={Message} />} />
        <Route path="/upload-resume" element={<AuthenticationGuard component={UploadResume} />} />
        <Route path="/donation" element={<AuthenticationGuard component={Donation} />} />
        <Route path="/donation/succeed" element={<PaymentCompleted />} />
        <Route path="/application" element={<AuthenticationGuard component={Application} />} />
      </Route>
      <Route element={<AdminLayout />}>
        <Route path="/admin" element={<AuthenticationGuard component={Admin} />} />
        <Route path="/admin/calendar" element={<AuthenticationGuard component={Calendar} />} />
        <Route path="/admin/support" element={<Support />} />
        <Route path="/admin/organization" element={<AuthenticationGuard component={Organization} />} />
        <Route path="/admin/smartknowledgehub" element={<AuthenticationGuard component={SmartKnowledgeHub} />} />
        <Route path="/admin/jobs" element={<AuthenticationGuard component={Jobs} />} />
        <Route path="/admin/job" element={<AuthenticationGuard component={Job} />} />
        <Route path="/admin/swipe" element={<AuthenticationGuard component={Swipe} />} />
        <Route path="/admin/match" element={<AuthenticationGuard component={Match} />} />
        <Route path="/admin/createJob" element={<AuthenticationGuard component={CreateJob} />} />
        <Route path="/admin/applicant" element={<AuthenticationGuard component={Applicant} />} />
        <Route path="/admin/message" element={<AuthenticationGuard component={Message} />} />
        <Route path="/admin/profile" element={<AuthenticationGuard component={Profile} />} />
      </Route>
      <Route path="/invitation" element={<AuthenticationGuard component={OrgInvitation} />} />
      <Route path="/invitation/decline" element={<AuthenticationGuard component={DeclineInvitation} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
