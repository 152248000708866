import { IconButton } from '@mui/material';
import React from 'react';

interface ToggleButtonProps {
  sx?: object;
  icon: React.ReactNode;
  toggle: () => void;
}

export const ToggleButton = ({ sx, toggle, icon }: ToggleButtonProps) => (
  <IconButton sx={{ alignSelf: 'center', ...sx }} onClick={toggle}>
    {icon}
  </IconButton>
);
