import { useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { TeamManagement } from './Section';

import { OrgDetails } from './Section/OrgDetails';

export const Organization = () => {
  const [value, setValue] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs sx={{ background: 'white', px: 3 }} value={value} onChange={handleChange}>
          <Tab label="Organization" value={1} />
          <Tab label="Team Management" value={2} />
        </Tabs>
      </Box>
      <Container sx={{ py: 4 }} maxWidth="xl">
        {value === 1 && <OrgDetails />}
        {value === 2 && <TeamManagement />}
      </Container>
    </Box>
  );
};
