import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Role, UserProfile } from 'types/profileType';
import { useAppSelector } from 'state/hooks';
import { ProfilePayload } from './types';
import { GET_VISIT_PROFILE, PROFILE_CREATE, PROFILE_RESET, PROFILE_REQUEST } from './actionTypes';

// Thunks - async actions
export const createProfileData = createAction(PROFILE_CREATE, (payload: ProfilePayload) => {
  return {
    payload,
  };
});

export const profileResetData = createAction(PROFILE_RESET, (payload: ProfilePayload) => {
  return {
    payload,
  };
});

export const profileRequestData = createAction(PROFILE_REQUEST, (payload: ProfilePayload) => {
  return {
    payload,
  };
});

export const visitProfileRequestData = createAction(GET_VISIT_PROFILE, (payload: ProfilePayload) => {
  return {
    payload,
  };
});

// export const rolesRequestData = createAction(GET_ROLES, (payload: RolesPayload) => {
//   return {
//     payload,
//   };
// });

interface ProfileState {
  pending: boolean;
  userProfile: UserProfile;
  visitProfile: UserProfile;
  generate: boolean;
  upload: any;
  roles: Role[];
  permissions: string[];
  file: any;
}

const initialState: ProfileState = {
  pending: true,
  userProfile: {},
  visitProfile: {},
  generate: false,
  upload: {},
  roles: [],
  permissions: [],
  file: {},
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfilePending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      state.userProfile = action.payload;
      state.pending = false;
    },
    setVisitProfile: (state, action: PayloadAction<UserProfile>) => {
      state.visitProfile = action.payload;
    },
    setProfileGenerate: (state, action: PayloadAction<boolean>) => {
      state.generate = action.payload;
    },
    setProfileUpload: (state, action: PayloadAction<any>) => {
      state.upload = action.payload;
    },
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
    setFile: (state, action: PayloadAction<any>) => {
      state.file = action.payload;
    },
  },
});

// Actions
export const {
  setProfilePending,
  setUserProfile,
  setVisitProfile,
  setProfileGenerate,
  setProfileUpload,
  setRoles,
  setPermissions,
  setFile,
} = profileSlice.actions;

// Selectors
export const getProfileSelector = (): ProfileState => useAppSelector((state) => state.profile);
export const getProfilePendingSelector = (): boolean => useAppSelector((state) => state.profile.pending);
export const getUserProfileSelector = (): UserProfile => useAppSelector((state) => state.profile.userProfile);
export const getVisitProfileSelector = (): UserProfile => useAppSelector((state) => state.profile.visitProfile);
export const getProfileGenerateSelector = (): boolean => useAppSelector((state) => state.profile.generate);
export const getProfileUploadSelector = (): any => useAppSelector((state) => state.profile.upload);
export const getRolesSelector = (): Role[] => useAppSelector((state) => state.profile.roles);
export const getPermissionsSelector = (): string[] => useAppSelector((state) => state.profile.permissions);
export const getFileSelector = (): any => useAppSelector((state) => state.profile.file);
