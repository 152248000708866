import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSnackbar } from 'components/Snackbar';
import { JobShort } from 'types/jobType';
import { getApiURL } from 'utils';
import { theme } from 'theme';
import moment from 'moment';

interface JobRowProps {
  data: JobShort;
  showActions?: boolean;
}

export const JobRow = ({ data, showActions = true }: JobRowProps) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();

  const deleteJob = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Job/DeleteJobRequest/${data.id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      showSnackbar('Job deleted!', 'success');
      window.location.reload();
    } else {
      showSnackbar('Cannot delete this job at the moment!', 'error');
    }
  };

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '20px',
        '&:hover': {
          background: theme.palette.primary.light,
        },
      }}
      p={2}
      mb={2}
      onClick={() => navigate(`/admin/job?jobId=${data.id}`)}
    >
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Typography>{data.title}</Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center">
          <Typography>{moment(data.created).format('MM/YYYY')}</Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/admin/applicant?jobId=${data.id}`);
            }}
            disabled={!data.applicationCount}
          >
            <Typography>{data.applicationCount ? data.applicationCount : '-'}</Typography>
          </IconButton>
        </Grid>
        {showActions && (
          <Grid item xs={3} display="flex" justifyContent="end">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/admin/job?jobId=${data.id}`);
              }}
              disableRipple
            >
              <EditOutlinedIcon sx={{ color: '#388e3c' }} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteJob();
              }}
              disableRipple
            >
              <DeleteOutlineOutlinedIcon sx={{ color: '#d32f2f' }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
