import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';
import { Organization, UserOrg } from 'types/organizationType';

// Thunks - async actions
// export const createProfileData = createAction(PROFILE_CREATE, (payload: ProfilePayload) => {
//   return {
//     payload,
//   };
// });

interface OrganizationState {
  pending: boolean;
  userOrg: UserOrg;
}

const initialState: OrganizationState = {
  pending: true,
  userOrg: {},
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrgPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.userOrg.organization = action.payload;
    },
    setUserOrg: (state, action: PayloadAction<UserOrg>) => {
      state.userOrg = action.payload;
    },
  },
});

// Actions
export const { setOrgPending, setOrganization, setUserOrg } = organizationSlice.actions;

// Selectors
export const getOrganizationSelector = (): OrganizationState => useAppSelector((state) => state.organization);
export const getUserOrgSelector = (): UserOrg => useAppSelector((state) => state.organization.userOrg);
