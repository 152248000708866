import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Pagination, Typography, Container, Box } from '@mui/material';
import { JobShortRow } from 'components';
import { ApplyDialog } from 'components/Dialog';
import { FilterJob } from './Section';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch } from 'state/hooks';
import { getAllJobsData, getJobState, setJobShortList } from 'state/job/jobSlice';
import { FilterJobs, JobShort } from '../types/jobType';
import { LocationAutocomplete, TextField } from 'components/Form';
import { LoadingAnimation } from 'components/Animation';

const defaultValue = {
  search: '',
  location: '',
  salaryMin: '',
  salaryMax: '',
  salaryType: 1,
  fullTime: false,
  partTime: false,
  contract: false,
  internship: false,
};

export const Home = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { jobShortList } = getJobState();
  const { results: jobList, count, currentPage, totalPages } = jobShortList;

  const PER_PAGE = 15;

  const [filter, setFilter] = useState<FilterJobs>(defaultValue);

  useEffect(() => {
    dispatch(
      getAllJobsData({
        pageNumber: currentPage,
        pageSize: PER_PAGE,
        cultureISO: i18n.language,
        filter: filter.search,
        location: filter.location,
        isFullTime: filter.fullTime,
        isPartTime: filter.partTime,
        isContract: filter.contract,
        isInternship: filter.internship,
        salaryMin: filter.salaryMin,
        salaryMax: filter.salaryMax,
      })
    );
  }, [i18n, jobShortList.currentPage, filter]);
  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    dispatch(setJobShortList({ ...jobShortList, currentPage: pageNumber }));
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          background: 'linear-gradient(90deg, rgba(220,226,240,1) 0%, rgba(103,155,236,1) 50%, rgba(22,53,96,1) 100%)',
          height: '200px',
          mb: 4,
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 24px',
          }}
          maxWidth="xl"
        >
          <Typography variant="h4" fontWeight="bold" color="white" alignSelf="end" mb={4}>
            Let's find your dream job .
          </Typography>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                value={filter.search}
                onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                placeholder={t('placeholder.search')}
                startAdornment={<SearchIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocationAutocomplete
                value={filter.location ?? ''}
                onChange={(e, newValue) => setFilter({ ...filter, location: newValue as string })}
                inputValue={filter.location ?? ''}
                onInputChange={(e, newValue) => setFilter({ ...filter, location: newValue })}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Grid container item alignItems="flex-start" spacing={3}>
          <Grid container item xs={12} md={9} spacing={3}>
            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography>{count ? `${count} job(s)` : <LoadingAnimation />}</Typography>
              {currentPage > 0 && totalPages > 0 && (
                <Pagination color="primary" count={totalPages} page={currentPage} onChange={handlePageChange} />
              )}
            </Grid>
            <Grid container item xs={12} spacing={3}>
              {jobList &&
                jobList.map((job: JobShort, index: number) => (
                  <Grid key={index} item xs={12} lg={6}>
                    <JobShortRow data={job} />
                  </Grid>
                ))}
            </Grid>
            {currentPage > 0 && totalPages > 0 && (
              <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
                <Pagination color="primary" count={totalPages} page={currentPage} onChange={handlePageChange} />
              </Grid>
            )}
          </Grid>
          <FilterJob filter={filter} setFilter={setFilter} />
        </Grid>
        <ApplyDialog />
      </Container>
    </div>
  );
};
