import { useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { UserMatches } from 'types/matchType';
import { theme } from 'theme';

interface MatchRowProps {
  data: UserMatches;
}

export const MatchRow = ({ data }: MatchRowProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '20px',
        '&:hover': {
          background: theme.palette.primary.light,
        },
      }}
      onClick={() => navigate(`/job?jobId=${data?.jobId}`)}
      p={2}
      mb={2}
    >
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{data.title}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{data.companyName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>2023-01-01</Typography>
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs={1}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              navigate('/message');
            }}
            disableRipple
          >
            <MessageOutlinedIcon
              sx={{
                padding: '10px',
                borderRadius: '50px',
                color: '#388e3c',
                background: '#e8f5e9',
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
