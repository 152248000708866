import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container } from '@mui/material';
import { Section } from 'components';
import moment from 'moment';
import { Calendar as Cal, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.scss'; // Import the custom styles
import { getApiURL } from 'utils';

export const Calendar = () => {
  const localizer = momentLocalizer(moment);
  const { getAccessTokenSilently, user } = useAuth0();

  const transformObject = (arr: any[]) => {
    return arr.map((obj) => ({
      title: obj.name,
      start: moment(obj.date).toDate(),
      end: moment(obj.date).add(1, 'hour').toDate(),
      meetingUrl: obj.meetingUrl,
    }));
  };

  const PER_PAGE = 25;

  const [meetings, setMeetings] = useState<any[]>([]);

  // const fetchInterview = async () => {
  //   try {
  //     const token = await getAccessTokenSilently();
  //     const response = await fetch(
  //       getApiURL(`/OrganizationAccount/GetOrganizationAccountInterviewsRequest`, {
  //         PageNumber: 1,
  //         PageSize: PER_PAGE,
  //         Date: '2024/07/16',
  //       }),
  //       {
  //         method: 'GET',
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     setMeetings(transformObject(data.results));
  //   } catch (error) {
  //     console.error('Error fetching interviews:', error);
  //   }
  // };

  const fetchInterview = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        getApiURL(`/OrganizationAccount/GetOrganizationAccountByIdRequest/${user?.sub}`, {
          IncludeOrganization: true,
          IncludeInterviewInvitations: true,
        }),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setMeetings(transformObject(data.interviewInvitations));
      }
    } catch (error) {
      console.error('Error fetching interviews:', error);
    }
  };

  useEffect(() => {
    fetchInterview();
  }, []);

  const handleSelectEvent = useCallback((event: any) => {
    const formattedUrl =
      event.meetingUrl.startsWith('http://') || event.meetingUrl.startsWith('https://')
        ? event.meetingUrl
        : `https://${event.meetingUrl}`;

    window.open(formattedUrl, '_blank', 'noopener,noreferrer');
  }, []);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        minHeight: '100vh',
        py: 3,
      }}
      maxWidth="xl"
    >
      <Section>
        <Cal
          localizer={localizer}
          events={meetings}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleSelectEvent}
        />
      </Section>
    </Container>
  );
};
