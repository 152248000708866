import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Pagination, Typography, Stack, Button, useMediaQuery } from '@mui/material';
import { SmartJobRow, ProfileGuard } from 'components';
import { ApplyDialog } from 'components/Dialog';
import { useAppDispatch } from 'state/hooks';
import { getUserProfileSelector } from 'state/profile/profileSlice';
import { getSwipeJobData, getSwipeJobSelector } from 'state/swipeJob/swipeJobSlice';
import { JobShort } from 'types/jobType';

export const SmartJobHub = () => {
  const { t, i18n } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width:900px)');
  const userProfile = getUserProfileSelector();

  const { count, results, showReset, totalPages, currentIndex } = getSwipeJobSelector();

  const [pageCount, setPageCount] = useState(0);
  const [totalResult, setTotalResult] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    if (userProfile.created) {
      getAccessTokenSilently().then((token) =>
        dispatch(
          getSwipeJobData({
            token,
            userId: user?.sub as string,
            pageNumber: currentPage,
            pageSize: PER_PAGE,
          })
        )
      );
    }
  }, [userProfile.created, user?.sub, currentPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <ProfileGuard maxWidth="lg">
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography fontSize={24} fontWeight={600}>
          Smart Job Hub
        </Typography>
        <Button>Favorite Jobs</Button>
      </Stack>
      <Grid container spacing={{ xs: 5, md: 7 }} alignItems="flex-start">
        <Grid container item md={12} spacing={2}>
          <Grid item xs={2} mt={2}>
            <Typography>{count ?? 0} job(s)</Typography>
          </Grid>
          {isDesktop && (
            <Grid
              container
              item
              xs
              mt={2}
              flexDirection="row"
              justifyContent="end"
              gap={i18n.language === 'en' ? 9 : 10.5}
              paddingRight={i18n.language === 'en' ? 5 : 4}
            >
              <Typography>{t('favorite')}</Typography>
              <Typography>{t('score')}</Typography>
              <Typography>{t('button.apply')}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {results && results?.map((job: JobShort, index: number) => <SmartJobRow key={index} data={job} />)}
          </Grid>
          {pageCount !== 0 && (
            <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
              <Pagination count={pageCount} page={currentPage} onChange={handlePageChange} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <ApplyDialog />
    </ProfileGuard>
  );
};
