import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Pagination, Typography, Stack, Select, MenuItem } from '@mui/material';
import { MatchRow, ProfileGuard } from 'components';
import { UserMatchRow } from 'components/UserMatchRow';
import { getJobShortList } from 'state/job/jobSlice';
import { getApiURL } from 'utils';
import { JobMatches, UserMatches } from 'types/matchType';
import { theme } from 'theme';

export const Match = () => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const { results } = getJobShortList();

  const [selectedJobId, setSelectedJobId] = useState<string>(results && results.length > 0 ? results[0].id : '');
  const [matches, setMatches] = useState<UserMatches[] | []>();
  const [jobMatches, setJobMatches] = useState<JobMatches[] | []>();

  const [pageCount, setPageCount] = useState(0);
  const [totalResult, setTotalResult] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        getApiURL(
          isAdmin ? `/Match/GetJobMatchesRequest/${selectedJobId}/` : `/Match/GetUserMatchesRequest/${user?.sub}/`,
          {
            PageNumber: currentPage,
            PageSize: PER_PAGE,
          }
        ),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }

      if (response.status === 204) {
        setPageCount(0);
        setTotalResult(0);
        isAdmin ? setJobMatches([]) : setMatches([]);
        throw new Error(`${response.status} - ${response.statusText}`);
      }

      const data = await response.json();

      setPageCount(data.totalPages);
      setTotalResult(data.count);
      isAdmin ? setJobMatches(data.results) : setMatches(data.results);
    };

    fetchData().catch(console.error);
  }, [selectedJobId, currentPage, user]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // if (!results.length) {
  //   return (
  //     <ProfileGuard maxWidth="md">
  //       <Typography mb={2}>{t('page.revealMatch.hasToBeMatch')}</Typography>
  //       {isAdmin && (
  //         <Button onClick={() => navigate('/admin/createJob')} startIcon={<AddIcon />}>
  //           {t('button.post')}
  //         </Button>
  //       )}
  //     </ProfileGuard>
  //   );
  // }

  return (
    <ProfileGuard maxWidth="md">
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={7}>
        <Typography variant="h5">Match</Typography>
      </Stack>
      {isAdmin && (
        <Select value={selectedJobId} onChange={(e) => setSelectedJobId(e.target.value as string)} fullWidth>
          {results.map((job) => (
            <MenuItem key={job?.id} value={job?.id}>
              {job?.title}
            </MenuItem>
          ))}
        </Select>
      )}
      <Grid container spacing={{ xs: 5, md: 7 }} alignItems="flex-start">
        <Grid container item md={12} spacing={2}>
          <Grid item xs={12} mt={2}>
            <Typography>
              {totalResult ?? 0} {isAdmin ? 'candidate' : 'job'}(s)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                mb: 2,
                borderRadius: '20px',
                background: theme.palette.primary.light,
              }}
              p={2}
            >
              <Grid item xs={5}>
                <Typography fontSize={15} fontWeight={600}>
                  {isAdmin ? 'Firstname' : t('match.title')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontSize={15} fontWeight={600}>
                  {isAdmin ? 'Lastname' : t('match.company')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontSize={15} fontWeight={600}>
                  Status
                </Typography>
              </Grid>
            </Grid>
            {matches && matches.map((data: UserMatches, index: number) => <MatchRow key={index} data={data} />)}
            {jobMatches &&
              jobMatches.map((data: JobMatches, index: number) => <UserMatchRow key={index} data={data} />)}
          </Grid>

          {pageCount !== 0 && (
            <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
              <Pagination count={pageCount} page={currentPage} onChange={handlePageChange} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </ProfileGuard>
  );
};
