import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Typography } from '@mui/material';

interface TextEditorProps {
  name?: string;
  control?: Control<FieldValues, any>;
  label?: string;
  initialValue?: string;
  marginBottom?: number;
  required?: boolean;
  readOnly?: boolean;
  value?: string;
  onChange?: (content: string) => void;
}

export const TextEditor = ({
  name,
  control,
  label,
  initialValue,
  marginBottom,
  required,
  readOnly = false,
  value,
  onChange,
}: TextEditorProps) => {
  const toolbar =
    'fullscreen | undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist | outdent indent | ' +
    'removeformat | help ';

  return (
    <Box component="div" mb={marginBottom ? marginBottom : 0} width="100%">
      {label && <Typography mb="5px">{label}</Typography>}
      {name ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Editor
              apiKey={window._env_.REACT_APP_TINY_API_KEY}
              value={field.value}
              initialValue={initialValue}
              onEditorChange={field.onChange}
              init={{
                height: 300,
                statusbar: false,
                menubar: false,
                elementpath: false,
                plugins: ['advlist', 'lists', 'help', 'fullscreen'],
                toolbar: !readOnly && toolbar,
                content_style: 'body { font-family: system-ui; font-size: 14px; }',
                editor_css: '.tox-tinymce { border-radius: 20px }',
              }}
              disabled={readOnly}
            />
          )}
          rules={{ required }}
        />
      ) : (
        <Editor
          apiKey={window._env_.REACT_APP_TINY_API_KEY}
          value={value}
          initialValue={initialValue}
          onEditorChange={onChange}
          init={{
            height: 300,
            statusbar: false,
            menubar: false,
            elementpath: false,
            plugins: ['advlist', 'lists', 'help', 'fullscreen'],
            toolbar: !readOnly && toolbar,
            content_style: 'body { font-family: system-ui; font-size: 14px; }',
            editor_css: '.tox-tinymce { border-radius: 20px }',
          }}
          disabled={readOnly}
        />
      )}
    </Box>
  );
};
