import axios from 'axios';
import { getApiURL } from 'utils';
import { FetchAllJobsByIdPayload, FetchAllJobsPayload, FetchJobDescriptionPayload } from './types';

export const fetchJobDescriptionById = async (payload: FetchJobDescriptionPayload) => {
  const { data } = await axios.get(
    getApiURL(`/Job/GetJobByIdRequest/${payload.jobId}`, { IncludeLocations: true, IncludeSkills: true })
  );
  return data;
};

export const fetchAllJobs = async (payload: FetchAllJobsPayload) => {
  const { data } = await axios.get(
    getApiURL('/Job/GetAllJobsRequest/', {
      PageNumber: payload.pageNumber,
      PageSize: payload.pageSize,
      Filter: payload.filter !== '' ? payload.filter : undefined,
      Location: payload.location !== '' ? payload.location : undefined,
      IsFullTime: payload.isFullTime ? payload.isFullTime : undefined,
      IsPartTime: payload.isPartTime ? payload.isPartTime : undefined,
      IsContract: payload.isContract ? payload.isContract : undefined,
      IsInternship: payload.isInternship ? payload.isInternship : undefined,
      SalaryMin: payload.salaryMin !== '' ? payload.salaryMin : undefined,
      SalaryMax: payload.salaryMax !== '' ? payload.salaryMax : undefined,
    })
  );
  return data;
};

export const fetchAllJobsById = async (payload: FetchAllJobsByIdPayload) => {
  const { data } = await axios.get(
    getApiURL('/Job/GetAllJobsRequest/', {
      OrganizationId: payload.organizationId,
      PageNumber: payload.pageNumber,
      PageSize: payload.pageSize,
      Filter: payload.filter !== '' ? payload.filter : undefined,
      IncludeApplicationCount: true,
    })
  );
  return data;
};
