import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';
import { JobDescription } from 'types/jobType';
import { GET_ALL_JOBS, GET_ALL_JOBS_ADMIN, GET_JOB_DESCRIPTION } from './actionTypes';
import { FetchAllJobsByIdPayload, FetchAllJobsPayload, FetchJobDescriptionPayload, JobList } from './types';

// Thunks - async actions
export const getJobDescriptionData = createAction(GET_JOB_DESCRIPTION, (payload: FetchJobDescriptionPayload) => {
  return {
    payload,
  };
});

export const getAllJobsData = createAction(GET_ALL_JOBS, (payload: FetchAllJobsPayload) => {
  return {
    payload,
  };
});

export const getAllJobsByIdData = createAction(GET_ALL_JOBS_ADMIN, (payload: FetchAllJobsByIdPayload) => {
  return {
    payload,
  };
});

export interface JobState {
  pending: boolean;
  jobShortList: JobList;
  selectedJobId: string;
  jobDescription: JobDescription;
}

const initialState: JobState = {
  pending: true,
  jobShortList: {
    results: [],
    currentPage: 1,
    totalPages: 1,
    count: 0,
  },
  selectedJobId: '',
  jobDescription: {},
};

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    setJobPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setJobShortList: (state, action: PayloadAction<JobList>) => {
      state.jobShortList = action.payload;
    },
    setSelectedJobId: (state, action: PayloadAction<string>) => {
      state.selectedJobId = action.payload;
    },
    setJobDescription: (state, action: PayloadAction<JobDescription>) => {
      state.jobDescription = action.payload;
    },
  },
});

// Actions
export const { setJobPending, setJobShortList, setSelectedJobId, setJobDescription } = jobSlice.actions;

// Selectors
export const getJobState = (): JobState => useAppSelector((state) => state.job);
export const getJobPending = (): boolean => useAppSelector((state) => state.job.pending);
export const getJobShortList = (): JobList => useAppSelector((state) => state.job.jobShortList);
export const getSelectedJobId = (): string => useAppSelector((state) => state.job.selectedJobId);
export const getJobDescription = (): JobDescription => useAppSelector((state) => state.job.jobDescription);
