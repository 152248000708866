import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { Tag } from 'components';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { getJobDescription, getJobDescriptionData } from 'state/job/jobSlice';
import { showSkillEditDialog, toggleSkillEditDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { getApiURL } from 'utils';
import { theme } from 'theme';

export const SkillEditDialog = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showSkillEditDialog();
  const jobDescription = getJobDescription();
  const onClose = () => {
    setSkill('');
    setSkills([]);
    dispatch(toggleSkillEditDialog(false));
  };

  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState<string[]>([]);

  const updateSkill = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Job/AddJobSkillsRequest/${jobDescription.id}`), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ names: skills }),
    });

    if (response.ok) {
      jobDescription.id && dispatch(getJobDescriptionData({ jobId: jobDescription.id }));
      onClose();
    }
  };

  const onSkillAdd = () => {
    setSkills([...skills, skill]);
    setSkill('');
  };

  const onSkillDelete = (skill: string) => {
    setSkills((current) => current.filter((item) => item !== skill));
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSkill('');
        setSkills([]);
        onClose();
      }}
      PaperProps={{ style: { borderRadius: '20px' } }}
      fullWidth
    >
      <DialogTitle>
        {t('profile.skill.add')}
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <TextField
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onSkillAdd();
                setSkill('');
              }
            }}
            placeholder={t('placeholder.addSkill')}
          />
          <IconButton sx={{ background: theme.palette.grey[400] }} color="primary" onClick={onSkillAdd}>
            <AddIcon />
          </IconButton>
        </Stack>

        <Stack flexDirection="row" flexWrap="wrap">
          {skills.map((skill, index) => (
            <Tag
              key={index}
              sx={{ m: '10px 5px 0 5px' }}
              label={skill}
              icon={
                <IconButton type="button" size="small" color="error" onClick={() => onSkillDelete(skill)} disableRipple>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button type="button" disabled={!skills.length} onClick={updateSkill}>
          {t('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
