import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { useAppDispatch } from 'state/hooks';
import { toggleApplyDialog } from 'state/dialogs/dialogsSlice';
import { setSelectedJobId } from 'state/job/jobSlice';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { JobShort } from 'types/jobType';
import { theme } from 'theme';

interface SmartJobRowProps {
  data: JobShort;
}

export const SmartJobRow = ({ data }: SmartJobRowProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onOpen = () => dispatch(toggleApplyDialog(true));

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  return (
    <>
      <Section
        onClick={() => {
          navigate(`/job?jobId=${data?.id}`);
          window.scrollTo(0, 0);
        }}
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
      >
        <Stack
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'center', md: 'flex-start' }}
          mr={{ xs: 0, md: 3 }}
        >
          <Avatar
            sx={{
              mr: { xs: 2, sm: 3 },
              height: 50,
              width: 50,
              borderRadius: '15px',
              background: theme.palette.primary.main,
              color: 'white',
            }}
            alt={data.companyName?.charAt(0)}
            src="/"
          />
          <Stack alignItems={{ xs: 'center', md: 'flex-start' }} gap={1}>
            <div>
              <Typography fontSize="16px">{data.title}</Typography>
              <Stack flexDirection="row">
                <Typography>{data.companyName}</Typography>
                {data.locations.length !== 0 && (
                  <Stack flexDirection="row" alignItems="center" ml={3}>
                    <LocationOnRoundedIcon color="disabled" fontSize="small" />
                    <Typography ml={0.5}>{data.locations[0].name}</Typography>
                  </Stack>
                )}
              </Stack>
            </div>
            <Stack flexDirection="row" flexWrap="wrap" gap={1}>
              {data.isFullTime && <Tag label={t('jobType.fullTime')} color="#388e3c" background="#e8f5e9" />}
              {data.isPartTime && <Tag label={t('jobType.partTime')} color="#f57c00" background="#fff3e0" />}
              {data.isContract && <Tag label={t('jobType.contract')} color="#d32f2f" background="#ffebee" />}
              {data.isInternship && <Tag label={t('jobType.internship')} color="#1976d2" background="#e3f2fd" />}
            </Stack>
          </Stack>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={10}>
          <IconButton
            sx={{ color: isFavorite ? '#fbc02d' : 'disabled' }}
            onClick={(e) => {
              e.stopPropagation();
              setIsFavorite(!isFavorite);
            }}
            disableRipple
          >
            {isFavorite ? <StarIcon fontSize="large" /> : <StarOutlineIcon fontSize="large" />}
          </IconButton>
          <Typography fontSize="16px" mx={1} noWrap>
            {(data.score * 100).toFixed()}%
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setSelectedJobId(data.id));
              onOpen();
            }}
            size="large"
            disableRipple
          >
            <SendOutlinedIcon
              sx={{
                color: '#d32f2f',
                padding: '12px',
                background: '#ffebee',
                borderRadius: '50px',
                '&:hover': {
                  color: 'white',
                  background: '#d32f2f',
                },
              }}
            />
          </IconButton>
        </Stack>
      </Section>
    </>
  );
};
