import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#679bec',
      dark: '#163560',
      light: '#dce2f0',
    },
    secondary: {
      main: '#666666',
    },
    grey: {
      900: '#121212',
      800: '#333333',
      700: '#666666',
      600: '#9E9E9E',
      500: '#C9C9C9',
      400: '#EAEAEA',
      300: '#F5F5F5',
    },
    text: {
      primary: '#212427',
    },
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  typography: {
    fontSize: 12,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#212427',
          wordBreak: 'break-word',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: 'white',
        },
        option: {
          '&:hover': {
            background: '#e3e1fc',
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          background: 'white',
          borderRadius: '15px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#C9C9C9',
          },
          '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColor: '#679bec',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          minWidth: 0,
          fontSize: 14,
          fontWeight: 600,
          borderRadius: '15px',
          textTransform: 'none',
          boxShadow: 'none',
          '&.MuiButton-contained': {
            backgroundColor: '#679bec',
            color: 'white',
            '&:hover': {
              backgroundColor: '#163560',
            },
          },
          '&.MuiButton-outlined': {
            borderColor: '#679bec',
            color: '#679bec',
            '&:hover': {
              borderColor: '#163560',
              color: '#163560',
            },
          },
          '&:disabled': {
            color: '#9E9E9E',
            backgroundColor: '#EAEAEA',
          },
        },
        sizeMedium: {
          height: '52.71px',
        },
        sizeSmall: {
          height: '45px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          fontWeight: 600,
          color: '#212427',
          textTransform: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #EAEAEA',
          padding: '20px 20px 20px 30px',
          background: 'white',
          fontWeight: 600,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px 30px',
          paddingTop: '20px !important',
          background: 'white',
          overflow: 'auto',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #EAEAEA',
          padding: '20px 30px',
          background: 'white',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '24px',
          borderRadius: '20px',
          boxShadow: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          color: '#C0C0C0',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: '#F5F5F5',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
        },
      },
    },
  },
});
