import React, { useState } from 'react';
import { Tag } from 'components/Tag';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiURL } from 'utils';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  padding: 16px 0;
`;

type ResumeAnalysis = {
  jobTitle: string;
  skills: string[];
  years: string;
};

export const UploadResume = () => {
  const [resume, setResume] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState<ResumeAnalysis>({ jobTitle: '', skills: [], years: '' });
  const { getAccessTokenSilently } = useAuth0();

  const uploadResume = async (resume: string) => {
    if (resume && !loading) {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await fetch(getApiURL('/service/gpt/resume'), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resume }),
      }).then((res) => res.json());
      setAnalysis(result);
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <label>
          Resume: <br />
          <textarea
            value={resume}
            style={{ width: 800, height: 600 }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setResume(e.currentTarget.value)}
          />
        </label>
        <br />
        <button onClick={() => uploadResume(resume)}>Submit</button>
      </div>
      {resume && !loading ? (
        <div>
          <h2>{analysis.jobTitle}</h2>
          <p>{analysis.years} work experiences</p>
          <Grid>
            {analysis.skills.map((skill, i) => (
              <Tag key={i} label={skill} color="#1976d2" background="#e3f2fd" />
            ))}
          </Grid>
        </div>
      ) : (
        <>Analysising...</>
      )}
    </div>
  );
};
export default UploadResume;
