export enum ApplicationStatus {
  Pending = 0,
  Interview = 1,
  Accepted = 2,
  Declined = 3,
}

export type Applicant = {
  id: string;
  userName: string;
  email: string;
  created: moment.Moment;
  message?: string;
  fileInformation?: File;
  status: ApplicationStatus;
  score?: number | null;
  organizationNote?: string;
};

export type File = {
  id: string;
};
