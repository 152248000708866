import { useEffect, useState } from 'react';
import moment from 'moment';
import { UseFieldArrayAppend, UseFieldArrayUpdate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { DatePicker, TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { showEducationCreateDialog, toggleEducationCreateDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { Education, UserProfile } from 'types/profileType';

interface EducationCreateDialogDialogProps {
  index?: number;
  setIndex?: (val: number | undefined) => void;
  educations?: Education[];
  educationsAppend: UseFieldArrayAppend<UserProfile, 'educations'>;
  educationsUpdate: UseFieldArrayUpdate<UserProfile, 'educations'>;
}

export const EducationCreateDialog = ({
  index,
  setIndex,
  educations,
  educationsAppend,
  educationsUpdate,
}: EducationCreateDialogDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = showEducationCreateDialog();
  const onClose = () => {
    setEducation({});
    setIndex && setIndex(undefined);
    dispatch(toggleEducationCreateDialog(false));
  };

  const [education, setEducation] = useState<Education>({});

  useEffect(() => {
    if (educations && typeof index === 'number') {
      setEducation(educations[index]);
    }
  }, [open]);

  const handleSave = () => {
    if (education) {
      if (typeof index === 'number') {
        educationsUpdate(index, education);
      } else {
        educationsAppend(education);
      }
    }
    onClose();
  };

  return (
    <Dialog open={open} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        {t('profile.education.add')}
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DatePicker
              label={t('profile.education.startDate')}
              value={education?.dateStart ? moment(education?.dateStart) : null}
              onChange={(date) => setEducation({ ...education, dateStart: date, isPresent: false })}
              views={['year']}
              format="YYYY"
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              label={t('profile.education.endDate')}
              value={education?.dateEnd ? moment(education?.dateEnd) : null}
              onChange={(date) => setEducation({ ...education, dateEnd: date, isPresent: false })}
              views={['year']}
              format="YYYY"
            />
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <FormControlLabel
              sx={{ mt: '20px' }}
              label="Present"
              control={
                <Checkbox
                  checked={education?.isPresent ?? false}
                  onChange={(e) => setEducation({ ...education, isPresent: e.target.checked, dateEnd: null })}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={education?.schoolName}
              onChange={(e) => setEducation({ ...education, schoolName: e.target.value })}
              label={t('profile.education.school')}
              placeholder="Enter a school"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={education?.diploma}
              onChange={(e) => setEducation({ ...education, diploma: e.target.value })}
              label={t('profile.education.diploma')}
              placeholder="Enter a diploma"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={education?.subject}
              onChange={(e) => setEducation({ ...education, subject: e.target.value })}
              label={t('profile.education.subject')}
              placeholder="Enter a field of study"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={education?.location}
              onChange={(e) => setEducation({ ...education, location: e.target.value })}
              label={t('profile.education.location')}
              placeholder="Enter a location"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button type="button" onClick={handleSave}>
          {t('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
