import axios from 'axios';
import { getApiURL } from 'utils';

export const fetchDonateOptions = async (payload: { token: string }) => {
  const { data } = await axios.get(
    getApiURL(`/service/donate/options`),
    // "http://localhost:4000/donate/options",
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return data;
};

export const updatePaymentIntent = async (payload: {
  token: string;
  paymentIntentId: string;
  paymentTypeId: string;
}) => {
  const { data } = await axios.put(
    getApiURL(`/service/stripe/update-intent`),
    // "http://localhost:4000/stripe/update-intent",
    {
      paymentIntentId: payload.paymentIntentId,
      paymentTypeId: payload.paymentTypeId,
    },
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return data;
};
