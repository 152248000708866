import { Card, Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PaymentCompleted = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="md">
      <Card sx={{ textAlign: 'center' }}>
        <h3>{t('donation.paymentSucceed')}</h3>
        <Button href="/" variant="contained">
          {t('button.returnHome')}
        </Button>
      </Card>
    </Container>
  );
};
