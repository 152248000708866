import styled from '@emotion/styled';
import { keyframes } from '@mui/material/styles';

const bounce = keyframes`
  0%, 100% {
    transform: translateY(6px);
  }

  50% {
    transform: translateY(-12px);
  }
`;

export const BallContainer = styled.div`
  display: inline-block;
`;

export const Ball = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: white;
  border-radius: 50%;
  animation: ${bounce} 1s infinite;

  &:nth-of-type(2) {
    animation-delay: 150ms;
  }

  &:nth-of-type(3) {
    animation-delay: 300ms;
  }
`;
