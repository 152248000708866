import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppDispatch } from 'state/hooks';
import { showConfirmationDialog, toggleConfirmationDialog } from 'state/dialogs/dialogsSlice';

export const ConfirmationDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirmationDialog = showConfirmationDialog();
  const onClose = () => dispatch(toggleConfirmationDialog({ open: false, message: '', onConfirmation: undefined }));

  return (
    <Dialog open={confirmationDialog.open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <Typography>{confirmationDialog.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onClose} variant="outlined">
          {t('button.cancel')}
        </Button>
        <Button
          type="button"
          onClick={() => {
            confirmationDialog.onConfirmation && confirmationDialog.onConfirmation();
            onClose();
          }}
        >
          {t('button.back')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
