import { Organization } from './organizationType';

export enum InvitationStatus {
  Pending = 0,
  Accepted = 1,
  Refused = 2,
}

export type OrgInvitation = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  aboutUs?: string;
  status: number;
  roleId: string;
  created?: moment.Moment;
  lastModified?: moment.Moment;
};

export type MemberInvitation = {
  organization: Organization;
  organizationInvitation: OrgInvitation;
};
