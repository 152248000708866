import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';
import { DonateOption } from 'types/donateType';
import { GET_DONATE_OPTIONS, UPDATE_PAYMENT_INTENT } from './actionTypes';

// Thunks - async actions
export const getDonateOptionsData = createAction(GET_DONATE_OPTIONS, (payload: { token: string }) => {
  return {
    payload,
  };
});

export const updateDonatePaymentIntent = createAction(
  UPDATE_PAYMENT_INTENT,
  (payload: { token: string; paymentIntentId: string; paymentTypeId: string }) => {
    return {
      payload,
    };
  }
);

export interface DonateState {
  donateOptions: DonateOption[];
  selectedDonateOption: DonateOption | null;
}

const initialState: DonateState = {
  donateOptions: [],
  selectedDonateOption: null,
};

export const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    setDonateOptions: (state, action: PayloadAction<DonateOption[]>) => {
      state.donateOptions = action.payload;
    },
    setSelectedDonateOption: (state, action: PayloadAction<DonateOption>) => {
      state.selectedDonateOption = action.payload;
    },
  },
});

// Actions
export const { setDonateOptions, setSelectedDonateOption } = donationSlice.actions;

// Selectors
export const getDonateOptions = (): DonateOption[] => useAppSelector((state) => state.donation.donateOptions);
export const getSelectedDonateOption = (): DonateOption | null =>
  useAppSelector((state) => state.donation.selectedDonateOption);
