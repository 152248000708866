import React from 'react';
import Typography from '@mui/material/Typography';
import { theme } from 'theme';

interface TagProps {
  className?: string;
  style?: object;
  label: string;
  color?: string;
  background?: string;
  sx?: object;
  icon?: JSX.Element;
}

export const Tag = ({ className, style, label, color, background, sx, icon }: TagProps) => {
  return (
    <Typography
      className={className}
      style={style}
      sx={{
        ...sx,
        width: 'max-content',
        height: 'max-content',
        fontWeight: 600,
        padding: icon ? '4px 0 4px 10px' : '4px 10px',
        color: color ? color : theme.palette.primary.main,
        background: background ? background : theme.palette.primary.light,
        borderRadius: '30px',
      }}
    >
      {label}
      {icon}
    </Typography>
  );
};
