import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Section, Tag } from 'components';
import { LanguageEditDialog } from 'components/Dialog/ProfileEditDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleLanguageEditDialog } from 'state/dialogs/dialogsSlice';
import { getVisitProfileSelector } from 'state/profile/profileSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { Language } from 'types/profileType';

interface LanguagesProps {
  editable: boolean;
}

export const Languages = ({ editable }: LanguagesProps) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const visitProfile = getVisitProfileSelector();

  const [isEditable, setIsEditable] = useState(false);

  const deleteLanguage = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/UserProfile/DeleteUserLanguageRequest/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Section
      title={t('profile.language.title')}
      onAdd={() => dispatch(toggleLanguageEditDialog(true))}
      edit={isEditable}
      onEdit={() => setIsEditable(!isEditable)}
      editable={editable}
    >
      <Stack flexDirection="row" flexWrap="wrap">
        {(visitProfile.languages as Language[])?.map((language) => (
          <Tag
            key={language.id}
            sx={{ m: '0 10px 10px 0' }}
            label={language.name as string}
            color="#1976d2"
            background="#e3f2fd"
            icon={
              isEditable ? (
                <IconButton
                  type="button"
                  size="small"
                  color="error"
                  onClick={() => deleteLanguage(language.id as string)}
                  disableRipple
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              ) : undefined
            }
          />
        ))}
      </Stack>
      <LanguageEditDialog />
    </Section>
  );
};
