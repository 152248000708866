import { Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { TextField } from 'components/Form';
import { Organization } from 'types/organizationType';

interface SocialMediaProps {
  control: Control<Organization, any>;
}

export const SocialMedia = ({ control }: SocialMediaProps) => {
  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row' }} container spacing={3}>
      <Grid item xs={12}>
        <TextField name="inkedInUrl" control={control} label="LinkedIn" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="facebookUrl" control={control} label="Facebook" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="instagramUrl" control={control} label="Instagram" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="twitterUrl" control={control} label="Twitter" />
      </Grid>
    </Grid>
  );
};
