import moment from 'moment';
import { UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { Organization, UserOrg } from 'types/organizationType';

interface ReviewProps {
  watch: UseFormWatch<Organization>;
  organizer: UserOrg;
  setActiveStep: (num: number) => void;
  setIsReview: (boo: boolean) => void;
}

export const Review = ({ watch, organizer, setActiveStep, setIsReview }: ReviewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        title={t('createProfile.step2')}
        onEdit={() => {
          setIsReview(true);
          setActiveStep(0);
        }}
        editable
        marginBottom={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('name')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {organizer.firstName} {organizer.lastName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('email')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{organizer.email}</Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Section>
      <Section
        title="Company"
        onEdit={() => {
          setIsReview(true);
          setActiveStep(1);
        }}
        editable
        marginBottom={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('name')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('phone')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('phoneNumber')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>{t('location')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('location')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Industry</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('industry')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Website</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{watch('website')}</Typography>
          </Grid>
        </Grid>
      </Section>
      <Section
        title="About"
        onEdit={() => {
          setIsReview(true);
          setActiveStep(2);
        }}
        editable
        marginBottom={3}
      >
        <Typography>{watch('aboutUs')}</Typography>
      </Section>
      <Section
        title="Social Media"
        onEdit={() => {
          setIsReview(true);
          setActiveStep(3);
        }}
        editable
        marginBottom={3}
      >
        <Stack spacing={1.5} divider={<Divider />}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography fontWeight={600}>LinkedIn</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{watch('linkedInUrl')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight={600}>Facebook</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{watch('facebookUrl')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight={600}>Instagram</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{watch('twitterUrl')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight={600}>Twitter</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{watch('instagramUrl')}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Section>
    </>
  );
};
