import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import Button from '@mui/material/Button';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface ToggleButtonProps {
  label: string;
  name?: string;
  control?: Control<FieldValues, any>;
  value?: boolean;
  onChange?: any;
}

export const ToggleButton = ({ label, name, control, value, onChange }: ToggleButtonProps) => {
  return (
    <>
      {name ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Button
              variant={field.value ? 'contained' : 'outlined'}
              sx={{ textTransform: 'none', borderRadius: '50px', height: '35px' }}
              endIcon={field.value ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
              onClick={() => field.onChange(!field.value)}
            >
              {label}
            </Button>
          )}
        />
      ) : (
        <Button
          variant={value ? 'contained' : 'outlined'}
          sx={{ textTransform: 'none', borderRadius: '50px', height: '35px' }}
          endIcon={value ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
          onClick={onChange}
        >
          {label}
        </Button>
      )}
    </>
  );
};

export default ToggleButton;
