import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = ({ ...props }) => {
  const { i18n } = useTranslation();

  const changeLanguageHandler = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('fr');
    } else {
      i18n.changeLanguage('en');
    }
  };

  return (
    <Button {...props} onClick={changeLanguageHandler}>
      {i18n.language === 'en' ? 'FR' : 'EN'}
    </Button>
  );
};
