import { StripeElementProvider } from './StripeElementProvider';
import { usePayment } from './usePayment';
import { IStripePayment } from './types';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StripePaymentForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, isLoading, message, isInitDone } = usePayment();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PaymentElement />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button type="submit" disabled={!isInitDone || isLoading} fullWidth>
            {t('button.submit')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {message && <div>{message}</div>}
        </Grid>
      </Grid>
    </form>
  );
};

export const StripePayment = ({ paymentTypeId, email }: IStripePayment) => {
  return (
    <StripeElementProvider paymentTypeId={paymentTypeId} email={email}>
      <StripePaymentForm />
    </StripeElementProvider>
  );
};
