import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ProfileGuard } from 'components';
import { SwipeContainer } from './SwipeContainer';
import { getJobShortList } from 'state/job/jobSlice';
import AddIcon from '@mui/icons-material/Add';

export const Swipe = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const { results: jobList } = getJobShortList();

  const [selectedJobId, setSelectedJobId] = useState<string>(jobList && jobList.length > 0 ? jobList[0].id : '');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedJobId(event.target.value as string);
  };

  if (isAdmin && !jobList) {
    return (
      <ProfileGuard maxWidth="sm">
        <Typography mb={2}>{t('page.smartMatch.hasToBeMatch')}</Typography>
        <Button onClick={() => navigate('/admin/createJob')} startIcon={<AddIcon />}>
          {t('button.post')}
        </Button>
      </ProfileGuard>
    );
  }

  return (
    <ProfileGuard maxWidth="sm">
      {isAdmin && jobList && jobList.length > 0 && (
        <Stack>
          <Typography fontWeight={600} mb={1}>
            Pick a job to match with user
          </Typography>
          <Select value={selectedJobId} onChange={handleChange}>
            {jobList.map((job) => (
              <MenuItem key={job?.id} value={job?.id}>
                {job?.title}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      <SwipeContainer selectedJobId={selectedJobId} />
    </ProfileGuard>
  );
};
