import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { UserAvatar } from 'components';
import { getUserProfileSelector } from 'state/profile/profileSlice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ToggleButton } from '.';
import { useLocation } from 'react-router-dom';

interface HeaderDrawerProps {
  open: boolean;
  toggle: () => void;
}

export const HeaderDrawer = ({ open, toggle }: HeaderDrawerProps) => {
  const { t } = useTranslation();
  const userProfile = getUserProfileSelector();
  const { firstName, lastName, title, created } = userProfile;

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  return (
    <>
      {open ? (
        created && (
          <Box mx={3} mb={3}>
            <UserAvatar size={130} />
            <Stack ml={1}>
              <Typography
                sx={{ wordBreak: 'break-word' }}
                fontWeight={600}
                fontSize={16}
                color={isAdmin ? 'white' : 'inherit'}
              >
                {firstName} {lastName}
              </Typography>
              <Typography fontSize={12} color={isAdmin ? 'white' : 'inherit'}>
                {isAdmin ? t('button.business') : title}
              </Typography>
            </Stack>
          </Box>
        )
      ) : (
        <Stack sx={{ m: 1.5, gap: 1 }}>
          <ToggleButton icon={<ChevronRightIcon />} toggle={toggle} />
          {created && <UserAvatar size={46} />}
        </Stack>
      )}
    </>
  );
};
