import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Stack, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Section } from 'components';
import { ConfirmationDialog } from 'components/Dialog';
import { Contact, About, Company, SocialMedia, Review } from './Sections';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import DoneIcon from '@mui/icons-material/Done';
import ShareIcon from '@mui/icons-material/Share';
import { Organization, UserOrg } from 'types/organizationType';
import { getApiURL } from 'utils';
import { useAppDispatch } from 'state/hooks';
import { setUserOrg } from 'state/organization/organizationSlice';
import { useSnackbar } from 'components/Snackbar';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    background: theme.palette.grey[500],
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  zIndex: 1,
  color: 'white',
  borderRadius: '50%',
  background: theme.palette.grey[500],
  ...(ownerState.active && {
    background: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    background: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <WorkIcon />,
    3: <DescriptionIcon />,
    4: <ShareIcon />,
    5: <DoneIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const CreateOrganization = () => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    name: '',
    industry: '',
    location: '',
    phoneNumber: undefined,
    website: '',
    linkedInUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    aboutUs: '',
    cultureAndValues: '',
    mission: '',
  };

  const { handleSubmit, control, watch } = useForm<Organization>({ defaultValues });

  const steps = ['Who are you?', 'What is your organization?', 'About Us', 'Social Media Links', 'Review'];

  const [organizer, setOrganizer] = useState<UserOrg>({
    id: user?.sub || '',
    firstName: user?.given_name || '',
    lastName: user?.family_name || '',
    email: user?.email || '',
  });
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isReview, setIsReview] = useState<boolean>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    if (activeStep === steps.length - 1) {
      setIsReview(true);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = handleSubmit(async (data: Organization) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(getApiURL('/Organization/CreateOrganizationRequest'), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organization: data, owner: organizer }),
      });

      const result = await response.json();

      if (result.created) {
        dispatch(setUserOrg(result));
        navigate('/admin');
      } else {
        showSnackbar('Failed to create organization. Please make sure the form is filled out correctly.', 'error');
        setActiveStep(0);
      }
    } catch (error) {
      showSnackbar('The organization name already exists. Please choose a different name.', 'error');
      setActiveStep(1);
    }
  });

  return (
    <Container
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      maxWidth="md"
    >
      <Button
        sx={{ position: 'fixed', right: '25px', top: '25px' }}
        variant="outlined"
        onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })}
      >
        Log Out
      </Button>
      <Typography variant="h6" textTransform="uppercase">
        CREATE YOUR ORGANIZATION
      </Typography>
      <Typography variant="h4" mb={5}>
        {steps.map((item, index) => {
          if (index === activeStep) return item;
        })}
      </Typography>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps} sx={{ mb: 8 }}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          );
        })}
      </Stepper>
      <form onSubmit={onSubmit}>
        {activeStep === 0 && (
          <Section>
            <Contact userOrg={organizer} setUserOrg={setOrganizer} />
          </Section>
        )}
        {activeStep === 1 && (
          <Section>
            <Company control={control} />
          </Section>
        )}
        {activeStep === 2 && (
          <Section>
            <About control={control} />
          </Section>
        )}
        {activeStep === 3 && (
          <Section>
            <SocialMedia control={control} />
          </Section>
        )}
        {activeStep === 4 && (
          <Review watch={watch} organizer={organizer} setActiveStep={setActiveStep} setIsReview={setIsReview} />
        )}
        <Stack flexDirection="row" justifyContent="space-between" mt={6}>
          {activeStep > 0 && (
            <Button type="button" variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
              {t('button.back')}
            </Button>
          )}
          <Stack flexDirection="row" gap={1}>
            {activeStep < steps.length - 1 && (
              <Button
                type="button"
                onClick={handleNext}
                disabled={
                  (activeStep === 1 && !watch('name')) ||
                  (activeStep === 0 && !organizer.lastName) ||
                  (activeStep === 0 && !organizer.firstName)
                }
              >
                {t('button.next')}
              </Button>
            )}
            {isReview && activeStep !== steps.length - 1 && (
              <Button type="button" onClick={() => setActiveStep(steps.length - 1)}>
                Review
              </Button>
            )}
          </Stack>
          {activeStep === steps.length - 1 && <Button type="submit">{t('button.create')}</Button>}
        </Stack>
      </form>
      <ConfirmationDialog />
    </Container>
  );
};
