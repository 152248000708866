import { useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FooterDrawer, HeaderDrawer, NavigationDrawer, ToggleButton } from './Section';
import { getAppState, toggleDrawer } from 'state/app/appSlice';
import { useAppDispatch } from 'state/hooks';
import { theme } from 'theme';

export const Drawer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = window.location.pathname.includes('admin');
  const isDesktop = useMediaQuery('(min-width:900px)');
  const { drawerOpen, drawerWidth } = getAppState();

  const drawerContent = (
    <Grid container height="100%" color={isAdmin ? 'white' : theme.palette.text.primary}>
      <Grid item xs={12}>
        {drawerOpen && (
          <Stack sx={{ cursor: 'pointer' }} flexDirection="row" justifyContent="space-between" ml={4} my={2}>
            <Stack flexDirection="row" onClick={() => navigate('/')}>
              <Typography fontSize={28} fontWeight={600}>
                Work
              </Typography>
              <Typography fontSize={28} fontWeight={600} color={isAdmin ? 'white' : theme.palette.primary.main}>
                inder
              </Typography>
            </Stack>
            <ToggleButton sx={{ mr: 2 }} icon={<ChevronLeftIcon />} toggle={() => dispatch(toggleDrawer())} />
          </Stack>
        )}
        <HeaderDrawer open={drawerOpen} toggle={() => dispatch(toggleDrawer())} />
        <NavigationDrawer open={drawerOpen} />
      </Grid>
      <FooterDrawer />
    </Grid>
  );

  return (
    <Box>
      <MuiDrawer
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderWidth: 0,
            background: isAdmin ? theme.palette.primary.main : 'white',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          },
        }}
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={drawerOpen}
        onClose={() => dispatch(toggleDrawer())}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawerContent}
      </MuiDrawer>
    </Box>
  );
};
