import { createRef, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, CardContent, Container, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Section } from 'components';
import { MatchDialog } from 'components/Dialog/MatchDialog';
import { SwipeJob } from './SwipeJob';
import { SwipeResume } from './SwipeResume';
import { useAppDispatch } from 'state/hooks';
import { toggleMatchDialog } from 'state/dialogs/dialogsSlice';
import {
  getSwipeUserData,
  getSwipeUserSelector,
  resetSwipeUserData,
  setSwipeUser,
} from 'state/swipeUser/swipeUserSlice';
import { getSwipeJobData, getSwipeJobSelector, resetSwipeJobData, setSwipeJob } from 'state/swipeJob/swipeJobSlice';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Direction } from './utils';
import { UserProfile } from 'types/profileType';
import { JobShort } from 'types/jobType';
import { getApiURL } from 'utils';

interface TinderCardInstance {
  swipe: (dir: Direction) => Promise<void>;
  restoreCard: () => Promise<void>;
}

interface SwipeContainerProps {
  selectedJobId?: string;
}

export const SwipeContainer = ({ selectedJobId }: SwipeContainerProps) => {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  const { count, results, showReset, totalPages, currentIndex } = isAdmin
    ? getSwipeUserSelector()
    : getSwipeJobSelector();

  const [swipeDirection, setSwipeDirection] = useState<Direction | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const PER_PAGE = 25;

  const childRefs = useMemo(
    () => Array.from({ length: results.length }).map(() => createRef<TinderCardInstance>()),
    [results]
  );

  useEffect(() => {
    if (user && !isLoading) {
      getAccessTokenSilently().then((token: string) =>
        dispatch(
          isAdmin
            ? getSwipeUserData({
                token,
                jobId: selectedJobId as string,
                pageNumber: currentPage,
                pageSize: PER_PAGE,
              })
            : getSwipeJobData({
                token,
                userId: user.sub as string,
                pageNumber: currentPage,
                pageSize: PER_PAGE,
              })
        )
      );
    }
  }, [selectedJobId, currentPage]);

  const onBtnSwipe = async (dir: Direction) => {
    if (currentIndex < results.length) {
      await childRefs[currentIndex].current?.swipe(dir);
    }
  };

  const onSwipe = async (direction: Direction, dataId: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Swipe/${isAdmin ? 'SwipeUserRequest' : 'SwipeJobRequest'}`), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jobId: isAdmin ? selectedJobId : dataId,
        userProfileId: isAdmin ? dataId : user?.sub,
        approved: direction === 'right' ? true : false,
      }),
    });

    const data = await response.json();
    dispatch(toggleMatchDialog({ open: data.match, jobId: data.jobId, userProfileId: data.userProfileId }));
    dispatch(
      isAdmin
        ? setSwipeUser({
            count: count - 1,
            results: results.slice(0, -1) as UserProfile[],
            currentIndex: currentIndex - 1,
            showReset: currentIndex === 0 ? true : false,
            totalPages,
          })
        : setSwipeJob({
            count: count - 1,
            results: results.slice(0, -1) as JobShort[],
            currentIndex: currentIndex - 1,
            showReset: currentIndex === 0 ? true : false,
            totalPages,
          })
    );

    setSwipeDirection(null);
  };

  const onReset = async () => {
    if (user && !isLoading) {
      getAccessTokenSilently().then((token: string) =>
        dispatch(
          isAdmin
            ? resetSwipeUserData({
                jobId: selectedJobId as string,
                token,
              })
            : resetSwipeJobData({
                userId: user.sub as string,
                token,
              })
        )
      );
    }
  };

  if (showReset) {
    return (
      <Section sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: 10 }}>
        <Stack alignItems="center" gap={1}>
          <Typography fontWeight={600}>You have browse through all job/user at the moment ...</Typography>
          <Typography fontWeight={600}>Come back later for more!</Typography>
        </Stack>
        <Button sx={{ alignContent: 'center' }} variant="text" startIcon={<ReplayIcon />} onClick={onReset}>
          Retrieve all job/user that you have pass
        </Button>
        <MatchDialog />
      </Section>
    );
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      {results &&
        results.map((item, index) => (
          // <Swiper
          //   key={index}
          //   ref={childRefs[index]}
          //   preventSwipe={['up', 'down']}
          //   swipeRequirementType="position"
          //   onSwipeRequirementFulfilled={(dir) => isDesktop && setSwipeDirection(dir)}
          //   onSwipeRequirementUnfulfilled={() => isDesktop && setSwipeDirection(null)}
          //   onCardLeftScreen={(dir) => onSwipe(dir, item.id as string)}
          //   isAdmin={isAdmin}
          //   isDesktop={isDesktop}
          // >
          <Card
            sx={{
              width: '300px',
              height: '500px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              cursor: 'grab',
              background: 'white',
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              {isAdmin ? <SwipeResume resume={item as UserProfile} /> : <SwipeJob job={item as JobShort} />}
            </CardContent>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" p={1}>
              <IconButton
                sx={{
                  padding: '10px',
                  borderRadius: '50px',
                  color: currentIndex === index && swipeDirection === 'left' ? 'white' : '#d32f2f',
                  background: currentIndex === index && swipeDirection === 'left' ? '#d32f2f' : 'transparant',
                  border: '1px solid #d32f2f',
                  '&:hover': {
                    background: '#d32f2f',
                    color: 'white',
                  },
                }}
                onClick={() => onBtnSwipe('left')}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                sx={{
                  padding: '10px',
                  borderRadius: '50px',
                  color: '#1976d2',
                  background: 'transparant',
                  border: '1px solid #1976d2',
                  '&:hover': {
                    background: '#1976d2',
                    color: 'white',
                  },
                }}
                onClick={() => navigate(isAdmin ? `/${item.id}` : `/job?jobId=${item.id}`)}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
              <IconButton
                sx={{
                  padding: '10px',
                  borderRadius: '50px',
                  color: currentIndex === index && swipeDirection === 'right' ? 'white' : '#388e3c',
                  background: currentIndex === index && swipeDirection === 'right' ? '#388e3c' : 'transparant',
                  border: '1px solid #388e3c',
                  '&:hover': {
                    background: '#388e3c',
                    color: 'white',
                  },
                }}
                onClick={() => onBtnSwipe('right')}
              >
                <CheckIcon />
              </IconButton>
            </Stack>
          </Card>
          // </Swiper>
        ))}
      <MatchDialog />
    </Container>
  );
};
