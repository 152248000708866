import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { GetSwipeJobPayload, ResetSwipeJobPayload, SwipeJob } from './types';
import { GET_SWIPE_JOB, RESET_SWIPE_JOB } from './actionTypes';
import { useAppSelector } from 'state/hooks';

// Thunks - async actions
export const getSwipeJobData = createAction(GET_SWIPE_JOB, (payload: GetSwipeJobPayload) => {
  return {
    payload,
  };
});

export const resetSwipeJobData = createAction(RESET_SWIPE_JOB, (payload: ResetSwipeJobPayload) => {
  return {
    payload,
  };
});

export interface SwipeJobState {
  pending: boolean;
  swipeJob: SwipeJob;
}

const initialState: SwipeJobState = {
  pending: false,
  swipeJob: { count: 0, results: [], showReset: false, totalPages: 1, currentIndex: -1 },
};

export const swipeJobSlice = createSlice({
  name: 'swipeJob',
  initialState,
  reducers: {
    setSwipeJob: (state, action: PayloadAction<SwipeJob>) => {
      state.swipeJob = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
  },
});

// Actions
export const { setSwipeJob, setPending } = swipeJobSlice.actions;

// Selectors
export const getSwipeJobSelector = (): SwipeJob => useAppSelector((state) => state.swipeJob.swipeJob);
