import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './lang/en.json';
import fr from './lang/fr.json';

export const resources = {
  en,
  fr,
} as const;
// change i18next default languauge

i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18next;
