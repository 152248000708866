import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Box, MenuItem, Select as MuiSelect, SelectChangeEvent, Typography } from '@mui/material';

interface MuiSelectProps {
  sx?: object;
  value?: string | number;
  onChange?: (event: SelectChangeEvent<string | number>, child: React.ReactNode) => void;
  name?: string;
  label?: string;
  control?: Control<FieldValues, any>;
  options: any[];
  startAdornment?: React.ReactNode;
  marginBottom?: number;
  required?: boolean;
}

export const Select = ({
  sx,
  name,
  value = '',
  onChange,
  label,
  control,
  options,
  startAdornment,
  marginBottom,
  required = false,
}: MuiSelectProps) => {
  return (
    <Box sx={sx} component="div" mb={marginBottom ? marginBottom : 0} width="100%">
      {label && <Typography mb="5px">{label}</Typography>}
      {control && name ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MuiSelect value={value ?? ''} onChange={onChange} startAdornment={startAdornment} fullWidth>
                <MenuItem sx={{ display: 'none' }} value={'' || undefined} disabled></MenuItem>
                {options?.map((option) => (
                  <MenuItem key={option.value ?? option} value={option.value ?? option}>
                    {option.label ?? option}
                  </MenuItem>
                ))}
              </MuiSelect>
            );
          }}
          rules={{ required }}
        />
      ) : (
        <MuiSelect value={value ?? ''} onChange={onChange} startAdornment={startAdornment} fullWidth>
          <MenuItem sx={{ display: 'none' }} value={'' || undefined} disabled></MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.value ?? option} value={option.value ?? option}>
              {option.label ?? option}
            </MenuItem>
          ))}
        </MuiSelect>
      )}
    </Box>
  );
};
