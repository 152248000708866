/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($messageId: String!) {
    getMessage(messageId: $messageId) {
      conversationId
      messageId
      senderId
      recipientId
      createdAt
      content
      messageType
      isRead
      metadata
      actionType
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $conversationId: String!
    $limit: Int
    $nextToken: String
  ) {
    listMessages(
      conversationId: $conversationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        conversationId
        messageId
        senderId
        recipientId
        createdAt
        content
        messageType
        isRead
        metadata
        actionType
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      conversationId
      createdAt
      updatedAt
      userIds
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: TableConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        conversationId
        createdAt
        updatedAt
        userIds
        __typename
      }
      nextToken
      __typename
    }
  }
`;
