import { graphqlOperation, API } from '@aws-amplify/api';
import { listMessages, listConversations } from 'graphql/queries';
import { subscribe } from 'graphql/subscriptions';
import axios from 'axios';
import {
  createMessage,
  createConversation,
  updateConversation as updateConversationData,
  updateMessages,
  updateMessage,
  deleteMessage,
} from 'graphql/mutations';
import { Message, Conversation } from '../../API';
import { getApiURL } from 'utils';

export const config = {
  aws_appsync_graphqlEndpoint: window._env_.AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: window._env_.AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: window._env_.AWS_APPSYNC_AUTHENTICATION_TYPE,
  aws_appsync_apiKey: window._env_.AWS_APPSYNC_APIKEY,
};

// eslint-disable-next-line
export async function fetchMessages(conversationId: string, limit?: number): Promise<any> {
  return await API.graphql(
    graphqlOperation(listMessages, {
      conversationId: conversationId,
      limit: limit || 25,
    })
  );
}

// eslint-disable-next-line
export async function fetchNextPageMessages(conversationId: string, nextToken: string): Promise<any> {
  return await API.graphql(
    graphqlOperation(listMessages, {
      conversationId: conversationId,
      nextToken,
      limit: 25,
    })
  );
}

interface SendMessagePayload {
  conversationId?: string | undefined;
  senderId?: string | undefined;
  recipientId?: string | undefined;
  content?: string | undefined;
  messageType?: string | undefined;
  metadata?: string | undefined;
}

export async function sendMessage({
  conversationId = '',
  senderId = '',
  recipientId = '',
  content = '',
  messageType = 'text',
  metadata = undefined,
}: SendMessagePayload): Promise<Message> {
  // eslint-disable-next-line
  // @ts-ignore
  return await API.graphql(
    graphqlOperation(createMessage, {
      input: {
        conversationId,
        senderId,
        recipientId,
        content,
        messageType,
        metadata,
      },
    })
  );
}

export async function sendMessages(messagesPayload: SendMessagePayload[]): Promise<Message[]> {
  // eslint-disable-next-line
  // @ts-ignore
  const promiseArr = messagesPayload.map(async (messagePayload) => {
    const {
      conversationId = '',
      senderId = '',
      recipientId = '',
      content = '',
      messageType = 'text',
      metadata = undefined,
    } = messagePayload;
    return API.graphql(
      graphqlOperation(createMessage, {
        input: {
          conversationId,
          senderId,
          recipientId,
          content,
          messageType,
          metadata,
        },
      })
    );
  });

  return await Promise.all(promiseArr).then((data) => {
    return data.map((item) => {
      // eslint-disable-next-line
      // @ts-ignore
      return item.data.createMessage;
    });
  });
}

export function subscribeMessages(
  userId: string,
  // eslint-disable-next-line
  next: (data: any, provider: any, value: any) => void,
  // eslint-disable-next-line
  error?: (error: any) => void
) {
  // eslint-disable-next-line
  // @ts-ignore
  return API.graphql(graphqlOperation(subscribe, { recipientId: userId })).subscribe({
    // eslint-disable-next-line
    // @ts-ignore
    next: ({ provider, value }) => {
      next(value.data.subscribe, provider, value);
    },
    error: error,
  });
}

// eslint-disable-next-line
export async function fetchConversations(userId: string): Promise<any> {
  return await API.graphql(
    graphqlOperation(listConversations, {
      filter: {
        userIds: { contains: userId },
      },
    })
  );
}

export async function addConversation(userIds: string[] = []): Promise<Conversation> {
  // eslint-disable-next-line
  // @ts-ignore
  return await API.graphql(
    graphqlOperation(createConversation, {
      input: {
        userIds,
      },
    })
  );
}

export async function updateConversation({ conversationId = '', latestMessage = '' }): Promise<Conversation> {
  // eslint-disable-next-line
  // @ts-ignore
  return await API.graphql(
    graphqlOperation(updateConversationData, {
      input: {
        conversationId,
        latestMessage,
      },
    })
  );
}

// TODO: This api is not working. Need to fix it in the future.
export async function readMessages(messages: Message[]): Promise<Message[]> {
  messages.forEach((message) => {
    message.isRead = true;
  });
  // eslint-disable-next-line
  // @ts-ignore
  return await API.graphql(
    graphqlOperation(updateMessages, {
      messages: messages,
    })
  );
}

export async function updateMessagesData(message: boolean[]): Promise<boolean> {
  // eslint-disable-next-line
  // @ts-ignore
  return await Promise.all(
    message.map((message) => {
      return API.graphql(
        graphqlOperation(updateMessage, {
          input: message,
        })
      );
    })
  )
    .then(() => true)
    .catch(() => false);
}

export const uploadChatFiles = async (payload: { token: string; files: File[] }) => {
  const formData = new FormData();
  payload.files.forEach((file) => {
    formData.append('files', file);
  });
  const { data } = await axios.post(
    getApiURL(`/service/chat/uploadFiles`),
    // `http://localhost:4000/chat/uploadFiles`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );
  return data;
};

export const getFile = async (payload: { token: string; key: string; messageId: string; createdAt: string }) => {
  return await fetch(
    getApiURL(`/service/chat/file/?key=${payload.key}&messageId=${payload.messageId}&createdAt=${payload.createdAt}`),
    // `http://localhost:4000/chat/file/?key=${payload.key}&messageId=${payload.messageId}&createdAt=${payload.createdAt}`,
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );
};

export const deleteFile = async (payload: { token: string; key: string; messageId: string; createdAt: string }) => {
  const { data } = await axios.delete(
    getApiURL(`/service/chat/file/?key=${payload.key}&messageId=${payload.messageId}&createdAt=${payload.createdAt}`),
    // `http://localhost:4000/chat/file/?key=${payload.key}&messageId=${payload.messageId}&createdAt=${payload.createdAt}`,
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    }
  );

  return data;
};

export const deleteMessageApi = async ({
  messageId,
  createdAt,
}: {
  messageId: string;
  createdAt: string;
}): Promise<Message> => {
  // eslint-disable-next-line
  // @ts-ignore
  return await API.graphql(
    graphqlOperation(deleteMessage, {
      input: {
        messageId,
        createdAt,
      },
    })
  );
};

export const sendMessageNotification = async ({
  token,
  email,
  message,
}: {
  token: string;
  email: string;
  message: string;
}) => {
  const { data } = await axios.post(
    getApiURL(`/service/chat/publish-notification`),
    // `http://localhost:4000/chat/publish-notification`
    {
      email,
      message,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
