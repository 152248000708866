import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { profileRequestData } from 'state/profile/profileSlice';
import { showLanguageEditDialog, toggleLanguageEditDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';

export const LanguageEditDialog = () => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showLanguageEditDialog();
  const onClose = () => {
    setLanguage('');
    dispatch(toggleLanguageEditDialog(false));
  };

  const [language, setLanguage] = useState('');

  const updateLanguage = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/UserProfile/AddUserLanguagesRequest'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ names: [language] }),
    });

    if (response.ok) {
      getAccessTokenSilently().then((token) => dispatch(profileRequestData({ userId: user?.sub as string, token })));
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        {t('profile.language.add')}
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <TextField
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setLanguage('');
              }
            }}
            placeholder={t('placeholder.addLanguage')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button type="button" onClick={updateLanguage} disabled={!language}>
          {t('button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
