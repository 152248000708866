import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { UploadFile } from 'components/Form';
import { useBanner } from 'components/Banner';
import { useAppDispatch } from 'state/hooks';
import { showResumeUploadDialog, toggleResumeUploadDialog } from 'state/dialogs/dialogsSlice';
import {
  getFileSelector,
  getProfilePendingSelector,
  setProfileGenerate,
  setProfilePending,
  setProfileUpload,
} from 'state/profile/profileSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';

type Resume = {
  resume?: any;
};

export const ResumeUploadDialog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { showBanner, closeBanner } = useBanner();
  const dispatch = useAppDispatch();
  const open = showResumeUploadDialog();
  const pending = getProfilePendingSelector();
  const onClose = () => dispatch(toggleResumeUploadDialog(false));
  const file = getFileSelector();

  const { handleSubmit, control, setValue, watch } = useForm<Resume>({
    defaultValues: {
      resume: undefined,
    },
  });

  const postData = async (data: FormData) => {
    dispatch(setProfilePending(true));

    const token = await getAccessTokenSilently();
    const uploadResp = await fetch(getApiURL('/service/gpt/resume/upload'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    }).then((res) => res.json());

    navigate('/');
    showBanner(t('alert.creation'), <LinearProgress />);

    const { jobId } = uploadResp;

    const timer = setInterval(async () => {
      const jobResp = await fetch(getApiURL(`/service/gpt/resume/job/${jobId}`), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());

      const { profile: response } = jobResp;

      if (jobResp.jobStatus === 'Failed') {
        showBanner(
          'Error',
          t('alert.failed'),
          <Button
            onClick={() => {
              navigate('/CreateProfile');
              closeBanner();
            }}
            size="small"
          >
            Try again
          </Button>
        );
        dispatch(setProfilePending(false));
        clearInterval(timer);
      }

      if (jobResp.jobStatus === 'Successful') {
        onClose();
        dispatch(setProfileGenerate(true));
        dispatch(setProfileUpload(response));
        showBanner(
          'Successful',
          t('alert.succeed'),
          <Button
            onClick={() => {
              navigate('/CreateProfile');
              closeBanner();
            }}
            size="small"
          >
            Continue
          </Button>
        );
        dispatch(setProfilePending(false));
        clearInterval(timer);
      }
    }, 2000);
  };

  const onSubmit = handleSubmit((data: Resume) => {
    const formData = new FormData();
    formData.append('resume', data.resume, data.resume.name);

    postData(formData).catch(console.error);
  });

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {t('pages.createProfile')}
          <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <UploadFile
            name="resume"
            label={t('apply.upload')}
            control={control}
            setValue={setValue}
            watch={watch}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" fullWidth disabled={pending}>
            {t('button.preview')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
