import { useState } from 'react';
import moment from 'moment';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Fab, IconButton, Stack, Typography } from '@mui/material';
import { Section } from 'components';
import { ExperienceCreateDialog } from 'components/Dialog/ProfileCreateDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleExperienceCreateDialog } from 'state/dialogs/dialogsSlice';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UserProfile } from 'types/profileType';

interface ExperiencesProps {
  control: Control<UserProfile, any>;
}

export const Experiences = ({ control }: ExperiencesProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onOpen = () => dispatch(toggleExperienceCreateDialog(true));

  const experiences = useWatch({
    name: 'experiences',
    control,
  });

  const {
    append: experiencesAppend,
    remove: experiencesRemove,
    update: experiencesUpdate,
  } = useFieldArray({
    control,
    name: 'experiences',
  });

  const [selectedExperienceIndex, setSelectedExperienceIndex] = useState<number | undefined>(undefined);

  return (
    <Stack flexDirection="column">
      {experiences && experiences.length === 0 && (
        <Button sx={{ alignSelf: 'center' }} onClick={onOpen} type="button" startIcon={<AddIcon />}>
          {t('profile.experience.add')}
        </Button>
      )}
      <Stack gap={3}>
        {experiences?.map((experience, index) => (
          <Section key={index} justifyContent="space-between" flexDirection="row">
            <Stack>
              <Typography fontWeight={600}>{experience.role}</Typography>
              <Typography>{experience.companyName}</Typography>
              <Typography>{experience.taskDescription}</Typography>
              <Typography>{experience.location}</Typography>
              <Stack flexDirection="row" gap={0.5}>
                {experience.dateStart && <Typography>{moment(experience.dateStart).format('MMM YYYY')} </Typography>}
                {(experience.dateStart && experience.dateEnd) || (experience.dateStart && experience.isPresent) ? (
                  <Typography>-</Typography>
                ) : null}
                {experience.dateEnd && <Typography>{moment(experience.dateEnd).format('MMM YYYY')}</Typography>}
                {experience.isPresent && <Typography>{t('present')}</Typography>}
              </Stack>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedExperienceIndex(index);
                  onOpen();
                }}
                type="button"
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton color="error" onClick={() => experiencesRemove(index)} type="button">
                <CloseOutlinedIcon />
              </IconButton>
            </Stack>
          </Section>
        ))}
      </Stack>
      {experiences && experiences.length > 0 && (
        <Fab sx={{ alignSelf: 'center', mt: 3 }} size="medium" color="primary" onClick={onOpen}>
          <AddIcon />
        </Fab>
      )}
      <ExperienceCreateDialog
        index={selectedExperienceIndex}
        setIndex={setSelectedExperienceIndex}
        experiences={experiences}
        experiencesUpdate={experiencesUpdate}
        experiencesAppend={experiencesAppend}
      />
    </Stack>
  );
};
