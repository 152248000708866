import { useState, useRef, useEffect, memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Divider, Grid, Stack, Typography, IconButton, Popover, MenuList, MenuItem } from '@mui/material';
import { Display, Section, ProfileGuard, Tag } from 'components';
import { useChat } from 'components/Chat/useChat';
import { useUploadFiles } from 'components/Chat/useUploadFiles';
import { TextField } from 'components/Form';
import { getScrollPosition, setMessageScrollPosition } from 'state/chat/chatSlice';
import { useAppDispatch } from 'state/hooks';
import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { FileMessage } from './FileMessage';
import { TextMessage } from './TextMessage';
import { theme } from 'theme';

const StyledFilesWrapper = styled.div`
  position: relative;
  padding: 0 35px;
`;

const StyledFilesContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  position: relative;
  scroll-behavior: smooth;
`;

const StyledLeftScrollButton = styled(IconButton)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 1;
`;
const StyledRightScrollButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 1;
`;

export const Message = memo(() => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const dispatch = useAppDispatch();
  const scrollPosition = getScrollPosition();
  const {
    sendMessage,
    senderId,
    sender,
    senderAuthInfo,
    currentRecipientId,
    currentConversationId,
    currentConversationMessages,
    changeCurrentConversation,
    conversations,
    contactUsers,
    currentConversation,
    getConversationNextPageMessages,
    messagesLoading,
  } = useChat();
  const { selectFile, files, removeFile } = useUploadFiles();

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textareaRef = useRef<any>(null);
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filesContainerRef = useRef<any>(null);
  const [showScrollLeft, setShowScrollLeft] = useState(false);
  const [showScrollRight, setShowScrollRight] = useState(false);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleMenuClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
  const open = Boolean(anchorEl);

  const handleSetScrollPosition = () => {
    if (textareaRef.current) {
      dispatch(setMessageScrollPosition(textareaRef.current.scrollTop));
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.addEventListener('scroll', () => {
        const scrollTop = Math.abs(textareaRef.current.scrollTop);
        const scrollHeight = textareaRef.current.scrollHeight;
        const height = textareaRef.current.getBoundingClientRect().height;

        if (scrollTop >= scrollHeight - height - 10 && currentConversation?.nextToken) {
          getConversationNextPageMessages();
          dispatch(setMessageScrollPosition(textareaRef.current.scrollTop));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (typeof scrollPosition === 'number' && textareaRef.current && textareaRef.current.scrollTo) {
      textareaRef.current.scrollTo({
        top: scrollPosition,
      });
      // dispatch(setMessageScrollPosition(null));
    }
  }, [currentConversationMessages]);

  useEffect(() => {
    if (filesContainerRef.current) {
      const isReachRightEnd =
        filesContainerRef.current.scrollLeft + filesContainerRef.current.clientWidth >=
        filesContainerRef.current.scrollWidth - 5;
      const isOverSize = filesContainerRef.current.scrollWidth > filesContainerRef.current.clientWidth;
      if (isOverSize && !isReachRightEnd) {
        setShowScrollRight(true);
      }

      if (!isOverSize) {
        setShowScrollRight(false);
        setShowScrollLeft(false);
      }
      filesContainerRef.current.addEventListener('scroll', () => {
        const isReachRightEnd =
          filesContainerRef.current.scrollLeft + filesContainerRef.current.clientWidth >=
          filesContainerRef.current.scrollWidth - 5;
        const isReachLeftEnd = filesContainerRef.current.scrollLeft <= 0;

        if (isReachLeftEnd) {
          setShowScrollLeft(false);
        }

        if (!isReachLeftEnd && !showScrollLeft) {
          setShowScrollLeft(true);
        }

        if (isReachRightEnd) {
          setShowScrollRight(false);
        }

        if (!isReachRightEnd && !showScrollRight) {
          setShowScrollRight(true);
        }
      });
    }
  }, [files]);

  const handleSendMessage = () => {
    if (senderId && currentRecipientId) {
      dispatch(setMessageScrollPosition(null));
      sendMessage(message, files);
      setMessage('');
    }
  };

  const handleDeleteConversation = (e: any) => {
    console.log('delete conversation');
  };

  return (
    <ProfileGuard>
      <Grid container spacing={{ xs: 2, md: 5 }}>
        <Grid item xs={12} sm={4}>
          <Section sx={{ height: '100%' }} noPadding>
            <Box display={{ xs: 'none', sm: 'block' }} p={2}>
              <Typography variant="h5" m={1}>
                Messages
              </Typography>
              <Stack gap={1}>
                {Object.values(conversations).map((groupe) => (
                  <Section
                    key={groupe.conversationId}
                    onClick={() => changeCurrentConversation(groupe.conversationId)}
                    noBorder
                    noPadding
                    background={groupe.conversationId === currentConversationId ? '#e3e1fc' : ''}
                  >
                    <Display
                      title={
                        (contactUsers[groupe.recipientId]?.firstName || '') +
                        ' ' +
                        (contactUsers[groupe.recipientId]?.lastName || '')
                      }
                      value={groupe.messages?.length ? groupe.messages[0].content || '' : ''}
                      src={contactUsers[groupe.recipientId]?.avatarLink}
                      alert={groupe.unreadMessages}
                      p={1}
                    />
                  </Section>
                ))}
              </Stack>
            </Box>
            <Stack display={{ xs: 'display', sm: 'none' }} p={1.5} gap={1.5} flexDirection="row">
              {Object.values(conversations).map((groupe) => (
                <Stack
                  key={groupe.conversationId}
                  sx={{
                    borderRadius: '20px',
                    background: groupe.conversationId === currentConversationId ? '#e3e1fc' : '',
                    cursor: 'pointer',
                    p: 1,
                    '&:hover': {
                      background: theme.palette.primary.light,
                    },
                  }}
                  onClick={() => changeCurrentConversation(groupe.conversationId)}
                  alignItems="center"
                >
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      color: theme.palette.primary.main,
                      borderRadius: '50px',
                    }}
                    src={contactUsers[groupe.recipientId]?.avatarLink}
                  />
                  <Typography>{contactUsers[groupe.recipientId]?.initials}</Typography>
                </Stack>
              ))}
            </Stack>
          </Section>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Section noPadding>
            <Stack m={2} flexDirection="row" justifyContent="space-between" alignItems="center">
              <Display
                title={
                  (contactUsers[currentRecipientId]?.firstName || '') +
                  ' ' +
                  (contactUsers[currentRecipientId]?.lastName || '')
                }
                // Should be last update
                // value={contactUsers[currentRecipientId]?.email || ''}
                src={contactUsers[currentRecipientId]?.avatarLink}
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuClick(e);
                }}
              >
                <MoreHorizIcon />
              </IconButton>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuList>
                  <MenuItem onClick={handleDeleteConversation}>{t('chat.delete')}</MenuItem>
                </MenuList>
              </Popover>
            </Stack>
            <Divider flexItem />
            <Stack
              ref={textareaRef}
              flexDirection="column-reverse"
              height={{ xs: 'calc(100vh - 550px)', sm: 'calc(100vh - 320px)' }}
              overflow="auto"
              p={3}
              gap={0.5}
            >
              {currentConversationMessages.map((msg) => {
                if (msg.messageType === 'file') {
                  return (
                    <Stack
                      key={msg.messageId}
                      flexDirection={msg.senderId == senderId ? 'row-reverse' : 'row'}
                      alignItems="end"
                      gap={1}
                    >
                      <Avatar
                        sx={{ bgcolor: theme.palette.primary.main }}
                        src={
                          msg.senderId == senderId
                            ? sender?.avatarLink || senderAuthInfo?.picture
                            : contactUsers[currentRecipientId]?.avatarLink
                        }
                      />
                      <FileMessage
                        key={msg.messageId}
                        message={msg}
                        isFromSender={msg.senderId === senderId}
                        setScrollPosition={handleSetScrollPosition}
                      />
                    </Stack>
                  );
                }
                return (
                  <Stack
                    key={msg.messageId}
                    flexDirection={msg.senderId == senderId ? 'row-reverse' : 'row'}
                    alignItems="end"
                    gap={1}
                  >
                    <Avatar
                      sx={{ bgcolor: theme.palette.primary.main }}
                      src={
                        msg.senderId == senderId
                          ? sender?.avatarLink || senderAuthInfo?.picture
                          : contactUsers[currentRecipientId]?.avatarLink
                      }
                    />
                    <TextMessage
                      key={msg.messageId}
                      message={msg}
                      isFromSender={msg.senderId === senderId}
                      setScrollPosition={handleSetScrollPosition}
                    />
                  </Stack>
                );
              })}
            </Stack>
            <Box m={3}>
              <StyledFilesWrapper>
                {showScrollLeft && (
                  <StyledLeftScrollButton onClick={() => (filesContainerRef.current.scrollLeft -= 150)}>
                    <ArrowCircleLeftIcon />
                  </StyledLeftScrollButton>
                )}
                <StyledFilesContainer ref={filesContainerRef}>
                  {files.map((file, i) => (
                    <Tag
                      style={{ whiteSpace: 'nowrap', flexShrink: 0 }}
                      key={i}
                      sx={{ m: '0px 5px 0 5px' }}
                      label={file.name}
                      icon={
                        <IconButton
                          type="button"
                          size="small"
                          color="error"
                          onClick={() => removeFile(i)}
                          disableRipple
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    />
                  ))}
                </StyledFilesContainer>
                {showScrollRight && (
                  <StyledRightScrollButton onClick={() => (filesContainerRef.current.scrollLeft += 150)}>
                    <ArrowCircleRightIcon />
                  </StyledRightScrollButton>
                )}
              </StyledFilesWrapper>
              <Stack flexDirection="row" alignItems="end" gap={1}>
                <IconButton onClick={selectFile}>
                  <DriveFolderUploadIcon color="primary" />
                </IconButton>
                <TextField
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === 'Enter' && handleSendMessage();
                  }}
                  endAdornment={<div style={{ display: 'flex', alignItems: 'center' }}></div>}
                  placeholder="Aa"
                  size="small"
                  fullWidth
                  multiline
                  maxRows={5}
                />
                <IconButton onClick={handleSendMessage}>
                  <SendIcon color="primary" />
                </IconButton>
              </Stack>
            </Box>
          </Section>
        </Grid>
      </Grid>
    </ProfileGuard>
  );
});
