/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      conversationId
      messageId
      senderId
      recipientId
      createdAt
      content
      messageType
      isRead
      metadata
      actionType
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      conversationId
      messageId
      senderId
      recipientId
      createdAt
      content
      messageType
      isRead
      metadata
      actionType
      __typename
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages($messages: [UpdateMessageInput]!) {
    updateMessages(messages: $messages) {
      conversationId
      messageId
      senderId
      recipientId
      createdAt
      content
      messageType
      isRead
      metadata
      actionType
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      conversationId
      messageId
      senderId
      recipientId
      createdAt
      content
      messageType
      isRead
      metadata
      actionType
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      conversationId
      createdAt
      updatedAt
      userIds
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      conversationId
      createdAt
      updatedAt
      userIds
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation($input: DeleteConversationInput!) {
    deleteConversation(input: $input) {
      conversationId
      createdAt
      updatedAt
      userIds
      __typename
    }
  }
`;
