import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { InviteDialog, InterviewDialog } from 'components/Dialog';
import { ApplicantRow } from 'components/ApplicantRow';
import { NotesDialog } from 'components/Dialog/NotesDialog';
import { NoteDialog } from 'components/Dialog/NoteDialog';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { toggleNotesDialog } from 'state/dialogs/dialogsSlice';
import { getAllJobsByIdData, getJobShortList } from 'state/job/jobSlice';
import { getUserOrgSelector } from 'state/organization/organizationSlice';
import { toggleInterviewDialog } from 'state/dialogs/jobDialogsSlice';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import AddIcon from '@mui/icons-material/Add';
import { bulkActions, getLabelByValue, getApiURL } from 'utils';
import { Applicant as ApplicantType } from 'types/applicantType';
import { debounce } from 'lodash';
import { theme } from 'theme';

export const Applicant = () => {
  const { t, i18n } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const userOrg = getUserOrgSelector();
  const jobShortList = getJobShortList();
  const { results: jobList, currentPage, totalPages, count } = jobShortList;

  const PER_PAGE = 25;

  const currentUrl = new URL(window.location.href);
  const jobId = currentUrl.searchParams.get('jobId');

  const [jobSelected, setJobSelected] = useState<string>(jobId ? jobId : '');
  const [selectApplicantId, setSelectApplicantId] = useState<string>('');
  const [applicantSelected, setApplicantSelected] = useState<string[]>([]);

  const [applicants, setApplicants] = useState<ApplicantType[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [minScore, setMinScore] = useState<number | undefined>(undefined);
  const [organizationNote, setOrganizationNote] = useState<string>('');

  const toggleApplicant = (newApplicantId: string) => {
    setApplicantSelected((oldValue) =>
      oldValue.includes(newApplicantId) ? oldValue.filter((id) => id !== newApplicantId) : [...oldValue, newApplicantId]
    );
  };

  const toggleCheckedAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAll(event.target.checked);

    const newSelectedApplicants = event.target.checked ? applicants.map((applicant) => applicant.id) : [];
    setApplicantSelected(newSelectedApplicants);
  };

  const onJobChange = (event: SelectChangeEvent) => {
    const newJobId = event.target.value as string;
    setJobSelected(newJobId);
    navigate(`/admin/applicant?jobId=${newJobId}`);
  };

  const onActionChange = (event: SelectChangeEvent) => {
    setSelectedAction(event.target.value as string);
  };

  useEffect(() => {
    if (userOrg.organizationId && userOrg.organizationId.trim()) {
      const fetchJobs = async () => {
        try {
          const token = await getAccessTokenSilently();
          dispatch(
            getAllJobsByIdData({
              token,
              organizationId: userOrg.organizationId || '',
              pageNumber: currentPage,
              pageSize: PER_PAGE,
            })
          );
        } catch (error) {
          console.error('Error fetching jobs:', error);
        }
      };

      fetchJobs();
    }
  }, [userOrg.organizationId, currentPage, i18n.language]);

  const fetchApplicants = async (score?: number) => {
    const transformScore = score && score / 100;
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(`/Application/GetApplicationByJobRequest/${jobSelected}`, {
        IncludeFiles: true,
        PageNumber: 1,
        PageSize: 20,
        MinimumScore: transformScore,
      }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 204) {
      setApplicants([]);
    } else if (response.status === 200) {
      const data = await response.json();
      setApplicants(data.results);
    } else {
      setApplicants([]);
    }
  };

  const debouncedFetchApplicants = debounce(fetchApplicants, 1500);

  useEffect(() => {
    if (jobSelected) {
      fetchApplicants();
    }
  }, [jobSelected]);

  // const sendInterview = async () => {
  //   const token = await getAccessTokenSilently();
  //   const response = await fetch(getApiURL('/Application/SendInterviewApplicationStatusRequest'), {
  //     method: 'PATCH',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       InterviewRequests: {
  //         ApplicationId: 'f41c9315-9a0d-485a-9287-ff946db84cda',
  //         Date: '2024-07-07 18:00:00',
  //         MeetingUrl: 'www.zoom.com/etertete',
  //       },
  //     }),
  //   });

  //   if (response.ok) {
  //     window.location.reload();
  //   }
  // };

  const acceptApplicants = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/AcceptApplicationsRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ApplicationIds: applicantSelected }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  const rejectApplicants = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/DeclineApplicationRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ApplicationIds: applicantSelected }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  // const onAddNotes = async (note: string) => {
  //   const token = await getAccessTokenSilently();
  //   const response = await fetch(getApiURL('/Application/CreateOrUpdateApplicationNoteRequest'), {
  //     method: 'PATCH',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ ApplicationIds: applicantSelected, Note: note }),
  //   });

  //   if (response.ok) {
  //     window.location.reload();
  //   }
  // };

  const onAddNote = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/CreateOrUpdateApplicationNoteRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ApplicationIds: [selectApplicantId], Note: organizationNote }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  const onBulkAction = () => {
    switch (selectedAction) {
      case 'interview':
        dispatch(toggleInterviewDialog(true));
        break;
      case 'accept':
        acceptApplicants();
        break;
      case 'reject':
        rejectApplicants();
        break;
      case 'sendMessages':
        dispatch(toggleNotesDialog(true));
        break;
      case 'downloadCV':
        break;
    }
  };

  return (
    <Container sx={{ py: 4 }} maxWidth="xl">
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6" textTransform="uppercase" fontWeight="bold">
          {t('jobInfo.applicant')}
        </Typography>
        <Stack flexDirection="row" alignItems="end" gap={1.5}>
          <Button onClick={() => navigate('/admin/createJob')} startIcon={<AddIcon />}>
            {t('button.post')}
          </Button>
          <Stack>
            <Typography variant="body1" fontWeight="bold" mb={0.5}>
              Job Listings
            </Typography>
            <Select
              sx={{ minWidth: '300px' }}
              value={jobList.length === 0 ? 'None' : jobSelected}
              onChange={onJobChange}
              disabled={jobList.length === 0}
            >
              {jobList.length === 0 ? (
                <MenuItem value={'None'}>None</MenuItem>
              ) : (
                jobList.map((job) => (
                  <MenuItem key={job?.id} value={job?.id}>
                    {job?.title}
                  </MenuItem>
                ))
              )}
            </Select>
          </Stack>
        </Stack>
      </Stack>
      <Stack flexDirection="row" alignItems="end" justifyContent="space-between" mb={3}>
        <div>
          <Typography variant="body1" fontWeight="bold" mb={1}>
            Bulk Actions
          </Typography>
          <Select
            sx={{
              height: '45px',
              minWidth: '200px',
            }}
            value={selectedAction}
            onChange={onActionChange}
            size="small"
          >
            {bulkActions.map((action) => (
              <MenuItem key={action.value} value={action.value}>
                {action.label}
              </MenuItem>
            ))}
          </Select>
          {selectedAction && (
            <Button sx={{ ml: 1 }} size="small" onClick={onBulkAction} disabled={applicantSelected.length === 0}>
              {getLabelByValue(selectedAction)}
            </Button>
          )}
        </div>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Button>
            <FilterAltOffOutlinedIcon />
          </Button>
          <TextField
            sx={{ width: '100px' }}
            value={minScore}
            onChange={(e) => {
              e.target.value === '' ? debouncedFetchApplicants() : debouncedFetchApplicants(Number(e.target.value));
              // e.target.value === ''
              //   ? debouncedFetchApplicants()
              //   : setApplicants(
              //       applicants.filter((app) => app.score != null && app.score * 100 > Number(e.target.value))
              //     );
            }}
            endAdornment="%"
            maxLength={2}
            placeholder="Score"
          />
        </Stack>
      </Stack>
      <Grid
        container
        sx={{
          mb: 2,
          borderRadius: '20px',
          background: theme.palette.primary.light,
        }}
        p={1}
        alignItems="center"
      >
        <Grid container item xs={3.5} display="flex" alignItems="center">
          <Checkbox sx={{ ml: 1 }} checked={checkedAll} onChange={toggleCheckedAll} />
          <Typography fontWeight={600} ml={1}>
            {t('name')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={600}>{t('email')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography fontWeight={600}>{t('jobInfo.status')}</Typography>
        </Grid>
        <Grid container item xs={2} display="flex" justifyContent="center" alignItems="center">
          <Typography fontWeight={600}>Score</Typography>
        </Grid>
      </Grid>
      {applicants.length > 0 &&
        applicants.map((applicant) => (
          <ApplicantRow
            key={applicant.id}
            data={applicant}
            checked={applicantSelected.includes(applicant.id)}
            setChecked={toggleApplicant}
            setSelectApplicantId={setSelectApplicantId}
            setOrganizationNote={setOrganizationNote}
          />
        ))}
      {selectedAction && count > 15 && (
        <Button size="small" onClick={onBulkAction} disabled={applicantSelected.length === 0}>
          {getLabelByValue(selectedAction)}
        </Button>
      )}
      <InviteDialog />
      {/* <NotesDialog onAddNotes={onAddNotes} /> */}
      <NoteDialog
        onAddNote={onAddNote}
        organizationNote={organizationNote ?? ''}
        setOrganizationNote={setOrganizationNote}
      />
      <InterviewDialog applicationId={selectApplicantId} />
    </Container>
  );
};
