import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { showSubmitGPTDialog, toggleSubmitGPTDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';

export const SubmitGPTDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = showSubmitGPTDialog();
  const onClose = () => dispatch(toggleSubmitGPTDialog(false));

  const [url, setUrl] = useState<string>('');

  const onSubmit = () => {
    console.log('subtmit: ', url);
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        Submit your amazing GPTs to GPTStore.AI
        <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <TextField label="GPT URL *" value={url} onChange={(e) => setUrl(e.target.value)} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button type="button" fullWidth disabled={!url} onClick={onSubmit}>
          {t('button.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
