export const getGender = (type: number | undefined): string => {
  if (type === 1) {
    return 'Men';
  } else if (type === 2) {
    return 'Women';
  } else if (type === 3) {
    return 'Other';
  } else {
    return 'Unknown';
  }
};
