import { useState } from 'react';
import { UseFormSetValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, DialogActions, DialogContent, LinearProgress, Stack, Typography } from '@mui/material';
import { UploadFile } from 'components/Form';
import { useSnackbar } from 'components/Snackbar';
import { UserProfile } from 'types/profileType';
import { getApiURL } from 'utils';
import { Loading } from 'components/Loading';
import { LoadingAnimation } from 'components/Animation';

type Resume = {
  resume?: any;
};

interface UploadResumeProps {
  setActiveStep: (num: number) => void;
  onClose: () => void;
  setProfile: UseFormSetValue<UserProfile>;
}

export const UploadResume = ({ setActiveStep, onClose, setProfile }: UploadResumeProps) => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();

  const { handleSubmit, control, setValue, watch } = useForm<Resume>({
    defaultValues: {
      resume: undefined,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const uploadCV = async (data: FormData) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const uploadResp = await fetch(getApiURL('/service/gpt/resume/upload'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    }).then((res) => res.json());

    const { jobId } = uploadResp;

    const timer = setInterval(async () => {
      const jobResp = await fetch(getApiURL(`/service/gpt/resume/job/${jobId}`), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());

      const { profile: response } = jobResp;

      if (jobResp.jobStatus === 'Failed') {
        setIsLoading(false);
        onClose();
        showSnackbar(<Typography>{t('alert.failed')}</Typography>, 'error', 'Error');
        clearInterval(timer);
      }

      if (jobResp.jobStatus === 'Successful') {
        setIsLoading(false);
        setProfile('title', response.title);
        setProfile('firstName', response.firstName);
        setProfile('lastName', response.lastName);
        setProfile('email', user?.email);
        setProfile('phoneNumber', response.phoneNumber);
        setProfile('birthdate', response.birthdate);
        setProfile('location', response.location);
        setProfile('description', response.aboutMe);
        setProfile('skills', response.skills);
        setProfile('languages', response.languages);
        setProfile('educations', response.educations);
        setProfile('experiences', response.experiences);
        setActiveStep(1);
        clearInterval(timer);
      }
    }, 2000);
  };

  const onSubmit = handleSubmit((data: Resume) => {
    const formData = new FormData();
    formData.append('resume', data.resume, data.resume.name);
    uploadCV(formData).catch(console.error);
  });

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <UploadFile
          name="resume"
          label={t('apply.upload')}
          control={control}
          setValue={setValue}
          watch={watch}
          disabled={isLoading}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" fullWidth disabled={isLoading}>
          {isLoading ? <LoadingAnimation /> : t('button.preview')}
        </Button>
      </DialogActions>
    </form>
  );
};
