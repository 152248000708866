import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';

interface AppState {
  drawerOpen: boolean;
  drawerWidth: number;
}

const initialState: AppState = {
  drawerOpen: true,
  drawerWidth: 250,
};

export const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen;
      state.drawerWidth = state.drawerOpen ? 250 : 70;
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
      state.drawerWidth = state.drawerOpen ? 250 : 70;
    },
  },
});

export const { toggleDrawer, setDrawerOpen } = appSlice.actions;

export const getAppState = (): AppState => useAppSelector((state) => state.app);
