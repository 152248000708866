import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Display, Section } from 'components';
import { SocialMedia } from 'Profile/Section';
import { getUserOrgSelector } from 'state/organization/organizationSlice';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import moment from 'moment';

export const OrgDetails = () => {
  const { t } = useTranslation();
  const userOrg = getUserOrgSelector();

  return (
    <Grid container spacing={2} mb={12}>
      <Grid item xs={8}>
        <Section
          title={userOrg.organization?.name}
          marginBottom={2}
          gap={3}
          onEdit={() => console.log('edit description')}
          editable
        >
          {/* {!userOrg.organization?.mission && (
      <Display
        title="Mission"
        value="Hogwarts Enterprises - Where Magic Meets Innovation. "
        icon={<LightModeRoundedIcon />}
      />
    )} */}
          <Typography>{userOrg.organization?.aboutUs}</Typography>
        </Section>
      </Grid>
      <Grid item xs={4}>
        <Section title="Org Details" onEdit={() => console.log('edit org')} editable noBorder marginBottom={2}>
          <Display
            title="Organization"
            value={userOrg.organization?.name}
            icon={<CorporateFareRoundedIcon />}
            marginBottom
          />
          <Display
            title="Industry"
            value={userOrg.organization?.industry}
            icon={<CorporateFareRoundedIcon />}
            marginBottom
          />
          <Display
            title={t('location')}
            value={userOrg.organization?.location}
            icon={<LocationOnOutlinedIcon />}
            marginBottom
          />
          {userOrg.organization?.phoneNumber && (
            <Display
              title={t('phone')}
              value={userOrg.organization?.phoneNumber}
              icon={<LocalPhoneOutlinedIcon />}
              marginBottom
            />
          )}
          <Display
            title="Website"
            value={<a href={userOrg.organization?.website}>{userOrg.organization?.website}</a>}
            icon={<OpenInNewOutlinedIcon />}
            marginBottom
          />
          <Display
            title="Creation Date"
            value={moment(userOrg.organization?.created).format('YYYY-MM-DD')}
            icon={<OpenInNewOutlinedIcon />}
          />
        </Section>
        <SocialMedia />
      </Grid>
    </Grid>
  );
};
