import { useEffect, useState } from 'react';
import { Avatar, Box, Button, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Section, Tag } from 'components';
import { TextField } from 'components/Form';
import { SubmitGPTDialog } from 'components/Dialog';
import { useAppDispatch } from 'state/hooks';
import { toggleSubmitGPTDialog } from 'state/dialogs/dialogsSlice';
import SearchIcon from '@mui/icons-material/Search';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { theme } from 'theme';

export const SmartKnowledgeHub = () => {
  const dispatch = useAppDispatch();

  const mockData = [
    {
      id: '1',
      title: 'Title 1',
      description:
        'Description 1 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mattis vitae lacus eget consequat. Vestibulum eget nulla non neque auctor mattis a id mauris. Ut vitae tempus magna. Vestibulum eget leo accumsan metus imperdiet sollicitudin. Sed eget efficitur lorem. Donec maximus et libero a fermentum. Morbi dignissim vel magna eget fermentum. Proin ullamcorper rutrum commodo.',
      categorie: 'Hard Skills',
      like: 69,
      dislike: 21,
    },
    {
      id: '2',
      title: 'Title 2',
      description:
        'Description 2 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae tempus magna. Proin ullamcorper rutrum commodo.',
      categorie: 'Others',
      like: 230,
      dislike: 100,
    },
    {
      id: '3',
      title: 'Title 3',
      description:
        'Description 3 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget leo accumsan metus imperdiet sollicitudin. Sed eget efficitur lorem. Donec maximus et libero a fermentum. Morbi dignissim vel magna eget fermentum. Proin ullamcorper rutrum commodo.',
      categorie: 'Others',
      like: 0,
      dislike: 3,
    },
    {
      id: '4',
      title: 'Title 4',
      description:
        'Description 4 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mattis vitae lacus eget consequat. Vestibulum eget nulla non neque auctor mattis a id mauris. Ut vitae tempus magna. Vestibulum eget leo accumsan metus imperdiet sollicitudin. Sed eget efficitur lorem. Donec maximus et libero a fermentum. Morbi dignissim vel magna eget fermentum. Proin ullamcorper rutrum commodo.',
      categorie: 'Personality',
      like: 456,
      dislike: 345,
    },
    {
      id: '5',
      title: 'Title 5',
      description:
        'Description 5 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mattis vitae lacus eget consequat. Vestibulum eget nulla non neque auctor mattis a id mauris. Ut vitae tempus magna. Vestibulum eget leo accumsan metus imperdiet sollicitudin. Sed eget efficitur lorem. Donec maximus et libero a fermentum. Morbi dignissim vel magna eget fermentum. Proin ullamcorper rutrum commodo.',
      categorie: 'Hard Skills',
      like: 12,
      dislike: 3,
    },
    {
      id: '6',
      title: 'Titre 6',
      description: 'Description 6 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
      categorie: 'Others',
      like: 32,
      dislike: 21,
    },
    {
      id: '7',
      title: 'Title 7',
      description:
        'Description 7 : Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mattis vitae lacus eget consequat. Vestibulum eget nulla non neque auctor mattis a id mauris. Ut vitae tempus magna. Vestibulum eget leo accumsan metus imperdiet sollicitudin. Sed eget efficitur lorem. Donec maximus et libero a fermentum. Morbi dignissim vel magna eget fermentum. Proin ullamcorper rutrum commodo.',
      categorie: 'Others',
      like: 0,
      dislike: 0,
    },
  ];

  const [mockBots, setMockBots] = useState(mockData);
  const [selectedCategorie, setSelectedCategorie] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    setMockBots(
      mockData.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  const filterByCategorie = (categorie: string) => {
    if (categorie === selectedCategorie) {
      setSelectedCategorie('');
      setMockBots(mockData);
    } else {
      setSelectedCategorie(categorie);
      setMockBots(mockData.filter((item) => item.categorie === categorie));
    }
  };

  return (
    <Container sx={{ py: 4 }} maxWidth="xl">
      <Grid container item justifyContent="space-between" spacing={{ xs: 3, lg: 5 }}>
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          flexDirection="row"
          alignContent="center"
          gap={1}
          order={{ xs: 2, lg: 1 }}
        >
          <TextField
            startAdornment={<SearchIcon />}
            placeholder="Search here..."
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() => {
              setSearch('');
              setSelectedCategorie('');
              setMockBots(mockData);
            }}
          >
            Reset
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          flexDirection="row"
          alignContent="center"
          justifyContent="end"
          gap={2}
          order={{ xs: 1, lg: 2 }}
        >
          <Button
            sx={{ width: { sm: 'fit-content' } }}
            onClick={() => (window.location.href = '/chatbot')}
            variant="outlined"
            fullWidth
          >
            Smart Interview Pratice
          </Button>
          <Button sx={{ width: { sm: 'fit-content' } }} onClick={() => dispatch(toggleSubmitGPTDialog(true))} fullWidth>
            Submit GPTs
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} order={3}>
          <Box
            sx={{
              display: 'flex',
              background: 'white',
              borderRadius: '20px',
              p: 0.25,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            {[
              {
                categorie: 'Hard Skills',
                length: mockData.filter((item) => item.categorie === 'Hard Skills').length,
              },
              {
                categorie: 'Personality',
                length: mockData.filter((item) => item.categorie === 'Personality').length,
              },
              {
                categorie: 'Soft Skills',
                length: mockData.filter((item) => item.categorie === 'Soft Skills').length,
              },
              { categorie: 'Others', length: mockData.filter((item) => item.categorie === 'Others').length },
            ].map((item) => {
              return (
                <Grid key={item.categorie} item xs={6} md={3} onClick={() => filterByCategorie(item.categorie)}>
                  <Box
                    sx={{
                      m: 0.25,
                      p: 1,
                      cursor: 'pointer',
                      border:
                        item.categorie === selectedCategorie
                          ? `1px solid ${theme.palette.primary.main}`
                          : '1px solid white',
                      borderRadius: '18px',
                      background: item.categorie === selectedCategorie ? theme.palette.primary.main : 'white',
                      '&:hover': { border: `1px solid ${theme.palette.primary.main}` },
                    }}
                  >
                    <Typography
                      color={item.categorie === selectedCategorie ? 'white' : 'inherit'}
                      textAlign="center"
                      noWrap
                    >
                      {item.categorie}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} order={4}>
          <Typography fontWeight={600} fontSize={18}>
            Results ({mockBots.length})
          </Typography>
        </Grid>
        {mockBots.map((item) => (
          <Grid key={item.id} item xs={12} md={6} order={6}>
            <Section onClick={() => (window.location.href = '/chatbot')}>
              <Stack flexDirection="row" alignItems="center" mb={2} gap={2}>
                <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    borderRadius: '15px',
                    background: 'white',
                    border: `1px solid ${theme.palette.grey[400]}`,
                    color: theme.palette.primary.main,
                  }}
                  alt={item.title}
                  src="/"
                />
                <Typography fontSize={16} fontWeight={600}>
                  {item.title}
                </Typography>
              </Stack>
              <Typography
                style={{
                  fontSize: 14,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 4,
                  minHeight: '85px',
                }}
              >
                {item.description}
              </Typography>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="end" mt={3}>
                <Tag label={item.categorie} />
                <Stack flexDirection="row" gap={1}>
                  <Stack alignItems="center">
                    <IconButton
                      sx={{ background: theme.palette.primary.main, opacity: 0.6 }}
                      size="small"
                      disableRipple
                    >
                      <ThumbUpIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Typography>{item.like}</Typography>
                  </Stack>
                  <Stack alignItems="center">
                    <IconButton sx={{ background: '#F36060', opacity: 0.6 }} size="small" disableRipple>
                      <ThumbDownIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Typography>{item.dislike}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Section>
          </Grid>
        ))}
      </Grid>
      <SubmitGPTDialog />
    </Container>
  );
};
