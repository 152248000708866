import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { getAppState } from 'state/app/appSlice';
import { theme } from 'theme';

export const FooterDrawer = () => {
  const { t, i18n } = useTranslation();
  const { logout, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const isAdmin = window.location.pathname.includes('admin');
  const { drawerOpen } = getAppState();

  const changeLanguageHandler = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en');
  };

  const onAuth = () => {
    if (isLoading) return;
    if (isAuthenticated) {
      logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
    } else {
      loginWithRedirect({ authorizationParams: { redirect_uri: window.location.href } });
    }
  };

  const items = [
    {
      icon: <LanguageOutlinedIcon fontSize="small" />,
      label: drawerOpen ? (i18n.language === 'en' ? 'Francais' : 'English') : i18n.language === 'en' ? 'FR' : 'EN',
      action: changeLanguageHandler,
    },
    !isAuthenticated &&
      !isLoading && {
        icon: isAdmin ? <WorkOutlineOutlinedIcon fontSize="small" /> : <BusinessOutlinedIcon fontSize="small" />,
        label: drawerOpen ? (isAdmin ? t('button.switchToEmployee') : t('button.switchToCompany')) : '',
        action: () => {
          if (isAdmin) {
            navigate('/');
          } else {
            loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${window.location.protocol}//${window.location.host}/admin`,
              },
            });
          }
        },
      },
    {
      icon: <SettingsOutlinedIcon fontSize="small" />,
      label: drawerOpen ? t('button.support') : '',
      action: () => navigate(isAdmin ? '/admin/support' : '/support'),
    },
    {
      icon: isAuthenticated ? <Logout fontSize="small" /> : <LoginOutlinedIcon fontSize="small" />,
      label: drawerOpen ? (isAuthenticated ? t('button.logOut') : t('button.logIn')) : '',
      action: onAuth,
    },
  ].filter((item): item is { icon: JSX.Element; label: string; action: () => void } => Boolean(item));

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: drawerOpen ? 'normal' : 'center',
      }}
      item
      xs={12}
    >
      <List sx={{ px: 3, py: 1.5 }}>
        {items.map((item, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton
              sx={{
                p: 1,
                borderRadius: '20px',
                color: isAdmin ? 'white' : theme.palette.grey[600],
              }}
              onClick={item.action}
            >
              <ListItemIcon
                sx={{
                  minWidth: 'fit-content',
                  mr: drawerOpen ? 1.5 : 0,
                  color: 'inherit',
                }}
              >
                {item.icon}
              </ListItemIcon>
              {drawerOpen && (
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: 0,
                    color: 'inherit',
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};
