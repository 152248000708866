import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import {
  Box,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

type CustomTextFieldProps = MuiTextFieldProps & {
  name?: string;
  label?: string;
  type?: string;
  control?: Control<FieldValues, any>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  textAlign?: any;
  marginBottom?: number;
  color?: string;
  maxLength?: number;
  required?: boolean;
  ref?: any;
};

export const TextField = ({
  name,
  label,
  type,
  control,
  startAdornment,
  endAdornment,
  textAlign,
  marginBottom,
  color,
  maxLength,
  required,
  ...props
}: CustomTextFieldProps) => {
  return (
    <Box component="div" mb={marginBottom ? marginBottom : 0} width="100%">
      {label && <Typography mb="5px">{label}</Typography>}
      {control && name ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <MuiTextField
              {...props}
              sx={{
                '& .MuiOutlinedInput-root:hover': {
                  '& > fieldset': {
                    borderColor: color,
                  },
                },
              }}
              type={type}
              value={value}
              onChange={onChange}
              error={!!error}
              InputProps={{
                endAdornment: endAdornment && (
                  <InputAdornment position="end">
                    {endAdornment}
                    {required && error && <ErrorIcon color="error" />}
                  </InputAdornment>
                ),
                startAdornment: startAdornment && <InputAdornment position="start">{startAdornment} </InputAdornment>,
                inputProps: {
                  style: { textAlign },
                  maxLength,
                },
              }}
              fullWidth
            />
          )}
          rules={{ required }}
        />
      ) : (
        <MuiTextField
          {...props}
          InputProps={{
            endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
            startAdornment: startAdornment && <InputAdornment position="start">{startAdornment} </InputAdornment>,
            inputProps: {
              style: { textAlign },
              maxLength,
            },
          }}
          fullWidth
        />
      )}
    </Box>
  );
};
