export const ROLES = [
  { id: 'rol_5pNH29hkXrcMJ2QG', name: 'CEO' },
  { id: 'rol_FfH0FLcrDmXUTedb', name: 'Owner' },
  { id: 'rol_wl22oKLlNXfL05Pu', name: 'Director' },
  { id: 'rol_PFyN6zZOFVwbjZce', name: 'Employee' },
];

export function getRoleNameById(roleId?: string) {
  const role = ROLES.find((role) => role.id === roleId);
  return role ? role.name : undefined;
}
