import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';

export interface JobDialogsState {
  showDescriptionEditDialog: boolean;
  showOverviewEditDialog: boolean;
  showLocationEditDialog: boolean;
  showSkillEditDialog: boolean;
  showInviteDialog: boolean;
  showInterviewDialog: boolean;
}

const initialState: JobDialogsState = {
  showDescriptionEditDialog: false,
  showOverviewEditDialog: false,
  showLocationEditDialog: false,
  showSkillEditDialog: false,
  showInviteDialog: false,
  showInterviewDialog: false,
};

export const jobDialogsSlice = createSlice({
  name: 'jobDialogs',
  initialState,
  reducers: {
    toggleDescriptionEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showDescriptionEditDialog = action.payload;
    },
    toggleOverviewEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showOverviewEditDialog = action.payload;
    },
    toggleLocationEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showLocationEditDialog = action.payload;
    },
    toggleSkillEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showSkillEditDialog = action.payload;
    },
    toggleInviteDialog: (state, action: PayloadAction<boolean>) => {
      state.showInviteDialog = action.payload;
    },
    toggleInterviewDialog: (state, action: PayloadAction<boolean>) => {
      state.showInterviewDialog = action.payload;
    },
  },
});

// Actions
export const {
  toggleDescriptionEditDialog,
  toggleOverviewEditDialog,
  toggleLocationEditDialog,
  toggleSkillEditDialog,
  toggleInviteDialog,
  toggleInterviewDialog,
} = jobDialogsSlice.actions;

// Selectors
export const showDescriptionEditDialog = (): boolean =>
  useAppSelector((state) => state.jobDialogs.showDescriptionEditDialog);
export const showOverviewEditDialog = (): boolean => useAppSelector((state) => state.jobDialogs.showOverviewEditDialog);
export const showLocationEditDialog = (): boolean => useAppSelector((state) => state.jobDialogs.showLocationEditDialog);
export const showSkillEditDialog = (): boolean => useAppSelector((state) => state.jobDialogs.showSkillEditDialog);
export const showInviteDialog = (): boolean => useAppSelector((state) => state.jobDialogs.showInviteDialog);
export const showInterviewDialog = (): boolean => useAppSelector((state) => state.jobDialogs.showInterviewDialog);
