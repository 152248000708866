import { Backdrop } from '@mui/material';
import { LoadingAnimation } from './Animation';

interface LoadingProps {
  condition?: boolean;
}

export const Loading = ({ condition = true }: LoadingProps) => {
  return (
    <Backdrop
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={condition}
    >
      <LoadingAnimation />
    </Backdrop>
  );
};
