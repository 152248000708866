import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Section, Display } from 'components/';
import { ApplyDialog } from 'components/Dialog';
import { Description, Overview, Locations, Skills } from './Section';
import { useAppDispatch } from 'state/hooks';
import { getJobDescription, getJobDescriptionData } from 'state/job/jobSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';

export const Job = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const jobDescription = getJobDescription();

  const currentUrl = new URL(window.location.href);
  const jobId = currentUrl.searchParams.get('jobId');

  useEffect(() => {
    jobId && dispatch(getJobDescriptionData({ jobId: jobId }));
  }, [jobId]);

  return (
    <Container sx={{ py: 4 }} maxWidth="xl">
      <Grid container spacing={{ xs: 2, sm: 3, lg: 5 }}>
        <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs={12}>
          <Button onClick={() => navigate(-1)}>{t('button.back')}</Button>
        </Grid>
        <Grid item xs={12} md={8.5}>
          <Description />
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Overview />
          <Locations />
          <Skills />
          <Section>
            <Typography fontWeight="600" fontSize="16px" textAlign="center">
              {jobDescription.companyName}
            </Typography>
            <Typography fontSize="14px" textAlign="center" mb="20px">
              Since July 2020
            </Typography>
            <Display title={t('phone')} value="+1 123-456-7890" icon={<PhoneInTalkIcon />} marginBottom reverse />
            <Display title={t('email')} value="company@email.com" icon={<EmailIcon />} marginBottom reverse />
            <Display title={t('website')} value="www.my-company.com" icon={<LanguageIcon />} marginBottom reverse />
            <Display title={t('location')} value="Montreal" icon={<PlaceIcon />} marginBottom reverse />
            <Button startIcon={<VisibilityIcon />} fullWidth>
              {t('jobInfo.viewOrganization')}
            </Button>
          </Section>
        </Grid>
      </Grid>
      <ApplyDialog />
    </Container>
  );
};
