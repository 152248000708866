import { useRef, useState } from 'react';
import { Control, Controller, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'theme';
import { setFile } from 'state/profile/profileSlice';
import { useAppDispatch } from 'state/hooks';

interface UploadFileProps {
  name: string;
  label?: string;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  marginBottom?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export const UploadFile = ({
  name,
  label,
  control,
  setValue,
  watch,
  marginBottom,
  required,
  disabled,
}: UploadFileProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [fileName, setFileName] = useState('');
  const fileInput = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Typography mb="5px">{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            sx={{
              marginBottom: marginBottom ? 5 : 0,
              textOverflow: 'ellipsis',
              input: { cursor: 'pointer' },
              '& .MuiOutlinedInput-root': {
                cursor: 'pointer',
                paddingLeft: 0,
              },
              '& .MuiInputAdornment-root': {
                marginRight: '22px',
                padding: '26px',
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
              },
            }}
            variant="outlined"
            value={fileName ?? ''}
            onClick={() => fileInput.current?.click()}
            fullWidth
            error={!!error}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{
                    color: 'white',
                    '&:hover': {
                      background: theme.palette.primary.main,
                    },
                    background: theme.palette.grey[600],
                    cursor: 'pointer',
                  }}
                  position="start"
                >
                  <Typography sx={{ color: 'inherit' }}>{t('button.file')}</Typography>
                </InputAdornment>
              ),
              endAdornment: watch(name) && (
                <CloseIcon
                  sx={{ marginLeft: '10px', cursor: 'pointer', '&:hover': { color: theme.palette.primary.main } }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(name, undefined);
                    setFileName('');
                  }}
                />
              ),
              readOnly: true,
            }}
            disabled={disabled}
          />
        )}
        rules={{ required }}
      />
      <input
        ref={fileInput}
        type="file"
        onChange={(e: any) => {
          setValue(name, e.target.files[0]);
          setFileName(e.target.files[0].name);
          console.log('file upload: ', e.target.files[0]);
          dispatch(setFile(e.target.files[0]));
        }}
        style={{ display: 'none' }}
      />
    </>
  );
};
