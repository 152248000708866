import { useState } from 'react';
import { Control, UseFormWatch, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import { Tag } from 'components';
import { TextField } from 'components/Form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { UserProfile } from 'types/profileType';

interface AboutProps {
  control: Control<UserProfile, any>;
  watch: UseFormWatch<UserProfile>;
}

export const About = ({ control, watch }: AboutProps) => {
  const { t } = useTranslation();

  const [skill, setSkill] = useState<string>('');
  const [language, setLanguage] = useState<string>('');

  const {
    fields: skillsFields,
    append: skillsAppend,
    remove: skillsRemove,
  } = useFieldArray({
    control,
    name: 'skills',
  });

  const {
    fields: languagesFields,
    append: languagesAppend,
    remove: languagesRemove,
  } = useFieldArray({
    control,
    name: 'languages',
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField name="description" label={t('profile.about.title')} control={control} multiline minRows={5} />
      </Grid>
      <Grid container item xs={12} md={6} alignSelf="flex-start">
        <Grid item xs={12}>
          <TextField
            label={t('profile.language.title')}
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                languagesAppend(language);
                setLanguage('');
              }
            }}
            endAdornment={
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  languagesAppend(language);
                  setLanguage('');
                }}
              >
                <AddIcon />
              </IconButton>
            }
          />
        </Grid>
        <Grid container item xs={12} flexDirection="row">
          {languagesFields.map((language, index) => (
            <Tag
              key={language.id}
              sx={{ m: '10px 5px 0 5px' }}
              label={watch(`languages.${index}`) as string}
              color="#1976d2"
              background="#e3f2fd"
              icon={
                <IconButton size="small" color="error" onClick={() => languagesRemove(index)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} alignSelf="flex-start">
        <Grid item xs={12}>
          <TextField
            label={t('profile.skill.title')}
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                skillsAppend(skill);
                setSkill('');
              }
            }}
            endAdornment={
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  skillsAppend(skill);
                  setSkill('');
                }}
              >
                <AddIcon />
              </IconButton>
            }
          />
        </Grid>
        <Grid container item xs={12} flexDirection="row">
          {skillsFields.map((skill, index) => (
            <Tag
              key={skill.id}
              sx={{ m: '10px 5px 0 5px' }}
              label={watch(`skills.${index}`) as string}
              icon={
                <IconButton size="small" color="error" onClick={() => skillsRemove(index)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
