import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Typography, IconButton, MenuList, MenuItem, Popover } from '@mui/material';
import { useChat } from 'components/Chat/useChat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Message } from '../types/chatTypes';
import { theme } from 'theme';

interface TextMessageProps {
  message: Message;
  isFromSender: boolean;
  setScrollPosition: () => void;
}
export const TextMessage = ({ message, isFromSender, setScrollPosition }: TextMessageProps) => {
  const { t } = useTranslation();
  const { removeMessage } = useChat();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleDeleteMessage = () => {
    removeMessage({ messageId: message.messageId, createdAt: message.createdAt as string });
    setScrollPosition();
  };

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? 'popup' : undefined;

  return (
    <Stack flexDirection="row-reverse" alignItems="center" gap={0.5} maxWidth="70%">
      <Card
        key={message.messageId}
        sx={{
          background: isFromSender ? theme.palette.primary.light : 'lightgray',
          px: 1.5,
          py: 1,
          borderRadius: '20px',
        }}
      >
        <Typography>{message.content}</Typography>
      </Card>
      {isFromSender && (
        <IconButton color="primary" onClick={handleMenuClick} size="small">
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem onClick={handleDeleteMessage}>{t('chat.delete')}</MenuItem>
        </MenuList>
      </Popover>
    </Stack>
  );
};
