import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Divider,
  Button,
  Stack,
} from '@mui/material';
import { Section } from 'components';
import { FilterJobs } from 'types/jobType';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

interface FilterJobProps {
  filter: FilterJobs;
  setFilter: (a: any) => void;
}

export const FilterJob = ({ filter, setFilter }: FilterJobProps) => {
  const { t } = useTranslation();

  const [showFilter, setShowFilter] = useState(true);

  const toggleShowFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  if (!showFilter) {
    return (
      <Grid item xs={12} md={3}>
        <Section flexDirection="row" alignItems="center" justifyContent="space-between" onClick={toggleShowFilter}>
          <Typography fontSize={14}>Filter</Typography>
          <FilterAltOutlinedIcon color="primary" />
        </Section>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={3}>
      <Section>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography fontSize="16px" pb={1}>
            Sort by
          </Typography>
          <FilterAltOffOutlinedIcon color="primary" onClick={toggleShowFilter} />
        </Stack>
        <Stack flexDirection="row" gap={2}>
          <Button variant="outlined" color="secondary" size="small">
            Relevancy
          </Button>
          <Button variant="outlined" color="secondary" size="small">
            Newest
          </Button>
        </Stack>
        <Divider sx={{ my: 3 }} flexItem />
        <Typography fontSize="16px" pb={1}>
          {t('filterJob.jobType')}
        </Typography>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.fullTime}
                  onChange={(e) => setFilter({ ...filter, fullTime: e.target.checked })}
                />
              }
              label={t('jobType.fullTime')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.partTime}
                  onChange={(e) => setFilter({ ...filter, partTime: e.target.checked })}
                />
              }
              label={t('jobType.partTime')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.contract}
                  onChange={(e) => setFilter({ ...filter, contract: e.target.checked })}
                />
              }
              label={t('jobType.contract')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.internship}
                  onChange={(e) => setFilter({ ...filter, internship: e.target.checked })}
                />
              }
              label={t('jobType.internship')}
            />
          </FormGroup>
        </FormControl>
        <Divider sx={{ my: 3 }} flexItem />
        <Typography fontSize="16px" pb={1}>
          {t('filterJob.salary')}
        </Typography>
        {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Min</Typography>
                <TextField
                  value={filter.salaryMin}
                  onChange={(e) => setFilter({ ...filter, salaryMin: e.target.value })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    inputProps: {
                      style: { textAlign: 'right' },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Max</Typography>
                <TextField
                  value={filter.salaryMax}
                  onChange={(e) => setFilter({ ...filter, salaryMax: e.target.value })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    inputProps: {
                      style: { textAlign: 'right' },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Select
                value={filter?.salaryType}
                onChange={(e) => setFilter({ ...filter, salaryType: e.target.value as number })}
                fullWidth
              >
                <MenuItem value={1}>{t('perHour')}</MenuItem>
                <MenuItem value={2}>{t('perYear')}</MenuItem>
              </Select>
            </Grid> */}
        <Divider sx={{ my: 3 }} flexItem />
        <Typography fontSize="16px" pb={1}>
          {t('filterJob.experience')}
        </Typography>
        <FormControl>
          <RadioGroup defaultValue="1">
            <FormControlLabel value="1" control={<Radio />} label={t('filterJob.experienceOptions.none')} />
            <FormControlLabel value="2" control={<Radio />} label={t('filterJob.experienceOptions.less3')} />
            <FormControlLabel value="3" control={<Radio />} label={t('filterJob.experienceOptions.between3and6')} />
            <FormControlLabel value="4" control={<Radio />} label={t('filterJob.experienceOptions.more6')} />
          </RadioGroup>
        </FormControl>
      </Section>
    </Grid>
  );
};
