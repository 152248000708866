import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { Section } from 'components';
import { theme } from 'theme';

export const Celebrations = () => {
  return (
    <Section>
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Avatar sx={{ width: 50, height: 50 }} color={theme.palette.secondary.main}>
          SC
        </Avatar>
        <Stack>
          <Typography>Shiming Chen</Typography>
          <Typography>September 1 - Happy Birthday!</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ m: 2 }} />
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Avatar sx={{ width: 50, height: 50 }} color={theme.palette.secondary.main}>
          HZ
        </Avatar>
        <Stack>
          <Typography>Hao Zheng</Typography>
          <Typography>September 8 - Happy Birthday!</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ m: 2 }} />
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Avatar sx={{ width: 50, height: 50, bgColor: theme.palette.primary.main }}>CR</Avatar>
        <Stack>
          <Typography>Carl Ratelle</Typography>
          <Typography>October 18 - Happy Birthday!</Typography>
        </Stack>
      </Stack>
    </Section>
  );
};
