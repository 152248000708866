import { ReactNode } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

interface DisplayProps {
  title?: string;
  value?: string | ReactNode;
  icon?: ReactNode;
  reverse?: boolean;
  marginBottom?: boolean;
  center?: boolean;
  p?: number;
  src?: string;
  alert?: number;
  select?: boolean;
  smallAvatar?: boolean;
}

export const Display = ({
  title,
  value,
  icon,
  reverse,
  marginBottom,
  center,
  p,
  src,
  alert,
  smallAvatar,
}: DisplayProps) => {
  return (
    <Stack
      direction="row"
      justifyContent={center ? 'center' : undefined}
      alignItems="center"
      mb={marginBottom ? 2.5 : 0}
      p={p}
    >
      <Avatar
        sx={{
          width: smallAvatar ? 35 : 50,
          height: smallAvatar ? 35 : 50,
          color: theme.palette.primary.main,
          mr: value || title ? (smallAvatar ? 1 : 2) : 'none',
          background: reverse ? 'white' : theme.palette.primary.light,
          borderRadius: '50px',
        }}
        src={src}
      >
        {icon}
      </Avatar>
      <Stack flexGrow={1} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" display="inline-block">
        <Typography fontWeight={600}>{title}</Typography>
        {value && (
          <Typography
            width="100%"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            display="inline-block"
            fontWeight={alert ? 600 : 400}
          >
            {value}
          </Typography>
        )}
      </Stack>
      {alert ? (
        <Avatar
          sx={{
            width: 15,
            height: 15,
            ml: 1,
            color: theme.palette.secondary.main,
            background: theme.palette.secondary.main,
            borderRadius: '50px',
            justifySelf: 'end',
          }}
        />
      ) : null}
    </Stack>
  );
};
