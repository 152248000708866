export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const GET_CONVERSATION_MESSAGES = 'GET_CONVERSATION_MESSAGES';
export const GET_CONVERSATION_NEXT_PAGE_MESSAGES = 'GET_CONVERSATION_NEXT_PAGE_MESSAGES';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const DELETE_FILE_MESSAGE = 'DELETE_FILE_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const LOOK_FOR_CONVERSATION_BY_RECIPIENT_ID = 'LOOK_FOR_CONVERSATION_BY_RECIPIENT_ID';
export const READ_MESSAGES = 'READ_MESSAGES';
