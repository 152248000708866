import moment from 'moment';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { DatePicker, Checkbox, TextField } from 'components/Form';
import { UserProfile } from 'types/profileType';

interface ContactProps {
  control: Control<UserProfile, any>;
}

export const Contact = ({ control }: ContactProps) => {
  const { t } = useTranslation();

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row' }} container spacing={3}>
      <Grid item xs={12} alignSelf="center">
        <TextField name="title" label={t('profile.jobTitle')} control={control} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="firstName" label={t('profile.firstName')} control={control} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="lastName" label={t('profile.lastName')} control={control} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="email" label={t('email')} control={control} disabled />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <Typography mb="5px">Sexe</Typography>
          <Controller
            name="sexe"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value={1} control={<Radio />} label={t('men')} />
                <FormControlLabel value={2} control={<Radio />} label={t('women')} />
                <FormControlLabel value={3} control={<Radio />} label={t('other')} />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          name="birthdate"
          label={t('profile.birthdate')}
          control={control}
          maxDate={moment()}
          views={['year', 'month', 'day']}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField name="phoneNumber" label={t('phone')} control={control} maxLength={10} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="location" label={t('location')} control={control} />
      </Grid>
      <Grid item xs={12}>
        <Checkbox name="matchJob" control={control} label={t('profile.match')} />
      </Grid>
    </Grid>
  );
};
