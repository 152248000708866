import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Grid,
  Pagination,
  Button,
  Typography,
  InputAdornment,
  Stack,
  CircularProgress,
  Container,
} from '@mui/material';
import { JobRow } from 'components';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { getAllJobsByIdData, getJobPending, getJobShortList, setJobShortList } from 'state/job/jobSlice';
import { getUserOrgSelector } from 'state/organization/organizationSlice';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { JobShort } from 'types/jobType';
import { theme } from 'theme';

export const Jobs = () => {
  const { t, i18n } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pending = getJobPending();
  const jobShortList = getJobShortList();
  const { results: jobList, count, currentPage, totalPages } = jobShortList;
  const userOrg = getUserOrgSelector();

  const [search, setSearch] = useState<string>('');
  const PER_PAGE = 15;

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      dispatch(
        getAllJobsByIdData({
          token,
          organizationId: userOrg?.organizationId ?? '',
          pageNumber: currentPage,
          pageSize: PER_PAGE,
          filter: search,
        })
      )
    );
  }, [userOrg?.organizationId, currentPage, i18n.language]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    dispatch(setJobShortList({ ...jobShortList, currentPage: pageNumber }));
    window.scrollTo(0, 0);
  };

  const handleFilter = () => {
    dispatch(setJobShortList({ ...jobShortList, currentPage: 1 }));
    getAccessTokenSilently().then((token) =>
      dispatch(
        getAllJobsByIdData({
          token,
          organizationId: userOrg?.organizationId ?? '',
          pageNumber: currentPage,
          pageSize: PER_PAGE,
          filter: search,
        })
      )
    );
  };

  return (
    <Container sx={{ pt: 4 }}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={7}>
        <Typography variant="h5">Jobs</Typography>
        <Stack flexDirection="row" gap={2}>
          <Button onClick={() => navigate('/admin/applicant')} variant="outlined">
            {t('jobInfo.applicant')}
          </Button>
          <Button onClick={() => navigate('/admin/createJob')} startIcon={<AddIcon />}>
            {t('button.post')}
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={{ xs: 5, md: 7 }} alignItems="flex-start">
        <Grid container item md={12} spacing={2}>
          <Grid item xs={12} md={4.5}>
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search job titles ..."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button onClick={handleFilter} variant="contained" fullWidth>
              {t('button.search')}
            </Button>
          </Grid>
          <Grid item xs={12} mt={2}>
            {pending ? <CircularProgress /> : <Typography>{count ? `${count} job(s)` : 'No job found!'}</Typography>}
          </Grid>
          {!pending && jobList && (
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  mb: 2,
                  borderRadius: '20px',
                  background: theme.palette.primary.light,
                }}
                p={2}
              >
                <Grid item xs={5}>
                  <Typography fontWeight={600}>{t('jobInfo.title')}</Typography>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                  <Typography fontWeight={600}>{t('jobInfo.date')}</Typography>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                  <Typography fontWeight={600}>{t('jobInfo.applicant')}</Typography>
                </Grid>
              </Grid>
              {jobList.map((data: JobShort, index: number) => (
                <JobRow key={index} data={data} />
              ))}
            </Grid>
          )}
          {count > 0 && currentPage && totalPages && (
            <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
              <Pagination color="secondary" count={totalPages} page={currentPage} onChange={handlePageChange} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
