import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Section, Display } from 'components/';
import { OverviewEditDialog } from 'components/Dialog/JobEditDialog';
import { useAppDispatch } from 'state/hooks';
import { getJobDescription } from 'state/job/jobSlice';
import { toggleOverviewEditDialog } from 'state/dialogs/jobDialogsSlice';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/Paid';
import HistoryIcon from '@mui/icons-material/History';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { formatExperienceRange } from 'utils';

export const Overview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const jobDescription = getJobDescription();
  const editable: boolean = location.pathname.startsWith('/admin');

  const workplaces = [
    jobDescription.isOnSite ? t('workplaceType.onSite') : undefined,
    jobDescription.isHybrid ? t('workplaceType.hybrid') : undefined,
    jobDescription.isRemote ? t('workplaceType.remote') : undefined,
  ];

  const workplaceString: string = workplaces.filter((location) => location !== undefined).join(' · ');

  return (
    <Section
      title={t('jobInfo.overview')}
      editable={editable}
      onEdit={() => dispatch(toggleOverviewEditDialog(true))}
      marginBottom={3}
    >
      <Display title={t('jobInfo.title')} value={jobDescription.title} icon={<PersonIcon />} marginBottom />
      <Display title="Workplace" value={workplaceString} icon={<ApartmentIcon />} marginBottom />
      <Display
        title={t('jobInfo.experience')}
        value={formatExperienceRange({
          experienceMin: jobDescription.experienceMin,
          experienceMax: jobDescription.experienceMax,
        })}
        icon={<AccessTimeIcon />}
        marginBottom
      />
      <Display title={t('jobInfo.salary')} value="100$/h" icon={<PaidIcon />} marginBottom />
      <Display
        title={t('jobInfo.date')}
        value={moment(jobDescription.created).format('YYYY-MM-DD')}
        icon={<HistoryIcon />}
      />
      <OverviewEditDialog />
    </Section>
  );
};
