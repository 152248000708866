import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid } from '@mui/material';
import { ProfileGuard } from 'components';
import { About, Educations, Skills, Contact, Experiences, Languages, SocialMedia } from './Section';
import { ResetProfile } from './Section/ResetProfile';
import { getUserProfileSelector, visitProfileRequestData } from 'state/profile/profileSlice';
import { useAppDispatch } from 'state/hooks';

export const Profile = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userProfile = getUserProfileSelector();

  const currentUrl = new URL(window.location.href);
  const profileId = currentUrl.searchParams.get('profileId');

  const editable = profileId === userProfile.id;

  useEffect(() => {
    if (profileId && userProfile.created) {
      getAccessTokenSilently().then((token) =>
        dispatch(visitProfileRequestData({ userId: profileId as string, token }))
      );
    }
  }, [profileId, userProfile.created]);

  return (
    <ProfileGuard>
      <Grid container spacing={{ xs: 2, sm: 3, lg: 5 }} flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
        <Grid item xs={12} lg={8}>
          <About editable={editable} />
          <Experiences editable={editable} />
          <Educations editable={editable} />
          <Skills editable={editable} />
          <Languages editable={editable} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Contact editable={editable} />
          <SocialMedia />
          <Button sx={{ mb: 3 }} onClick={() => navigate('/application')} fullWidth>
            Review all the positions I have applied for
          </Button>
          <ResetProfile />
        </Grid>
      </Grid>
    </ProfileGuard>
  );
};
