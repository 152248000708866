import { Control, UseFormWatch, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Stack } from '@mui/material';
import { Section, Tag } from 'components';
import { TextEditor, TextField } from 'components/Form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { JobDescription } from 'types/jobType';
import { useState } from 'react';
import { theme } from 'theme';

interface DescriptionProps {
  control: Control<JobDescription, any>;
  watch: UseFormWatch<JobDescription>;
}

export const Description = ({ control, watch }: DescriptionProps) => {
  const { t } = useTranslation();

  const [skill, setSkill] = useState<string>('');

  const {
    fields: skillsFields,
    append: skillsAppend,
    remove: skillsRemove,
  } = useFieldArray({
    control,
    name: 'skills',
  });

  return (
    <Section>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextEditor name="description" control={control} label={t('jobLabel.description')} required />
        </Grid>
        <Grid container item xs={12} alignSelf="flex-start">
          <Grid item xs={12}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <TextField
                label={t('profile.skill.title')}
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    skillsAppend(skill);
                    setSkill('');
                  }
                }}
                placeholder={t('placeholder.addSkill')}
              />
              <IconButton
                sx={{ background: theme.palette.grey[400], mt: 3 }}
                color="primary"
                onClick={() => {
                  skillsAppend(skill);
                  setSkill('');
                }}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid container item xs={12} flexDirection="row">
            {skillsFields.map((skill, index) => (
              <Tag
                key={skill.id}
                sx={{ m: '10px 5px 0 5px' }}
                label={watch(`skills.${index}`) as string}
                icon={
                  <IconButton size="small" color="error" onClick={() => skillsRemove(index)}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};
