import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'state/hooks';
import { showMatchDialog, toggleMatchDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as MatchSvg } from '../../assets/svg/completed-2.svg';

export const MatchDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const matchDialog = showMatchDialog();
  const onClose = () => dispatch(toggleMatchDialog({ open: false, jobId: '', userProfileId: '' }));
  const isAdmin = window.location.pathname.includes('admin');

  return (
    <Dialog open={matchDialog.open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        {t('dialog.title.match')}
        <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection="row">
          <Typography>{t('dialog.description.match')}</Typography>
          <MatchSvg />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate('/message')}>{t('dialog.button.messages')}</Button>
        <Button onClick={() => navigate(isAdmin ? `/${matchDialog.userProfileId}` : `/job?jobId=${matchDialog.jobId}`)}>
          {isAdmin ? t('dialog.button.matchCandidate') : t('dialog.button.matchJob')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
