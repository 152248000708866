import { useState } from 'react';
import { UseFormSetValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Loading } from 'components';
import { UploadFile } from 'components/Form';
import { useSnackbar } from 'components/Snackbar';
import { useAppDispatch } from 'state/hooks';
import { showJobUploadDialog, toggleJobUploadDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { JobDescription } from 'types/jobType';

type Description = {
  description?: any;
};

interface JobUploadDialogProps {
  setJobValue: UseFormSetValue<JobDescription>;
  setShowForm: (boo: boolean) => void;
}

export const JobUploadDialog = ({ setJobValue, setShowForm }: JobUploadDialogProps) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showJobUploadDialog();
  const onClose = () => dispatch(toggleJobUploadDialog(false));

  const { handleSubmit, control, setValue, watch, reset } = useForm<Description>({
    defaultValues: {
      description: undefined,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const postData = async (data: FormData) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const uploadResp = await fetch(getApiURL('/service/gpt/job/upload'), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      }).then((res) => res.json());

      const { jobId } = uploadResp;

      const timer = setInterval(async () => {
        const jobResp = await fetch(getApiURL(`/service/gpt/job/job/${jobId}`), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.json());

        const { job: response } = jobResp;

        if (['Failed', 'Successful'].includes(jobResp.jobStatus)) {
          if (jobResp.jobStatus === 'Failed') {
            showSnackbar('This service may not work at the moment, try again later!', 'error');
          }
          setIsLoading(false);
          reset();
          onClose();
          clearInterval(timer);
        }

        if (response) {
          setShowForm(true);
          setJobValue('title', response.jobTitle);
          setJobValue('description', response.description);
          setJobValue('isFullTime', response.isFullTime);
          setJobValue('isPartTime', response.isPartTime);
          setJobValue('isContract', response.isContract);
          setJobValue('isInternship', response.isInternship);
          setJobValue('isHybrid', response.isHybrid);
          setJobValue('isRemote', response.isRemote);
          setJobValue('isOnSite', response.isOnSite);
          setJobValue('skills', response.skills);
          setJobValue('locations', response.locations);
          setJobValue('experienceMin', response.experienceMin);
          setJobValue('experienceMax', response.experienceMax);
          setIsLoading(false);
          reset();
          onClose();
          clearInterval(timer);
        }
      }, 2000);
    } catch (err) {
      showSnackbar('This service may not work at the moment, try again later!', 'error');
      setIsLoading(false);
      reset();
      onClose();
    }
  };

  const onSubmit = handleSubmit((data: any) => {
    const formData = new FormData();
    formData.append('description', data.description, data.description.name);
    postData(formData).catch(console.error);
  });

  if (isLoading) return <Loading condition={isLoading} />;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {t('pages.createJob')}
          <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <UploadFile
            name="description"
            label={t('apply.uploadJob')}
            control={control}
            setValue={setValue}
            watch={watch}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" fullWidth disabled={isLoading}>
            {t('button.preview')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
