import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers';
import { Section } from 'components';

export const Calendar = () => {
  return (
    <Section sx={{ minHeigth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar showDaysOutsideCurrentMonth fixedWeekNumber={4} />
      </LocalizationProvider>
    </Section>
  );
};
