import { TextField } from '@mui/material';
import { Section } from 'components';
import { useState } from 'react';

export const Notes = () => {
  const [text, setText] = useState<string>('');

  return (
    <Section>
      <TextField
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `none`,
          },
        }}
        multiline
        rows={12}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="You can add your comments or notes here."
        fullWidth
      />
    </Section>
  );
};
