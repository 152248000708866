import { useEffect } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Checkbox, DatePicker, TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { profileRequestData } from 'state/profile/profileSlice';
import { showEducationEditDialog, toggleEducationEditDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { Education } from 'types/profileType';

interface EducationEditDialogProps {
  education?: Education;
  setEducation?: (edu: Education) => void;
}

export const EducationEditDialog = ({ education, setEducation }: EducationEditDialogProps) => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showEducationEditDialog();
  const onClose = () => {
    setEducation && setEducation({});
    dispatch(toggleEducationEditDialog(false));
  };

  const { handleSubmit, control, reset, setValue } = useForm<Education>({
    defaultValues: {
      dateStart: null,
      dateEnd: null,
      isPresent: false,
      schoolName: '',
      diploma: '',
      subject: '',
      location: '',
    },
  });

  useEffect(() => {
    if (education) {
      setValue('id', education.id);
      setValue('dateStart', education.dateStart ? moment(education.dateStart) : null);
      setValue('dateEnd', education.dateEnd ? moment(education.dateEnd) : null);
      setValue('isPresent', education.isPresent);
      setValue('schoolName', education.schoolName);
      setValue('diploma', education.diploma);
      setValue('subject', education.subject);
      setValue('location', education.location);
    }
  }, [open]);

  const postData = async (data: Education) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(
        `/UserProfile/${education?.id ? `UpdateUserEducationRequest/${education?.id}` : 'AddUserEducationRequest'}`
      ),
      {
        method: education?.id ? 'PATCH' : 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      user &&
        getAccessTokenSilently().then((token) => dispatch(profileRequestData({ userId: user.sub as string, token })));
    }
  };

  const onSubmit = handleSubmit((edu: Education) => {
    postData(edu);
    reset();
    onClose();
  });

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {education?.id ? t('profile.education.edit') : t('profile.education.add')}
          <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignContent="stretch">
            <Grid item xs={4}>
              <DatePicker
                name="dateStart"
                control={control}
                label={t('profile.education.startDate')}
                views={['month', 'year']}
                format="MM/YYYY"
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                name="dateEnd"
                control={control}
                label={t('profile.education.endDate')}
                views={['month', 'year']}
                format="MM/YYYY"
              />
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Checkbox sx={{ mt: '20px' }} name="isPresent" control={control} label={t('present')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="schoolName"
                control={control}
                label={t('profile.education.school')}
                placeholder="Enter a school"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="diploma"
                control={control}
                label={t('profile.education.diploma')}
                placeholder="Enter a diploma"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="subject"
                control={control}
                label={t('profile.education.subject')}
                placeholder="Enter a field of study"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="location"
                control={control}
                label={t('profile.education.location')}
                placeholder={t('placeholder.location')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="outlined" onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button type="submit">{t('button.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
