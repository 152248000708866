import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Fab, Typography, useMediaQuery } from '@mui/material';
import { Chat } from './Chat';
import { Drawer } from './Drawer';
import { CreateOrganization } from 'Admin';
import { useAppDispatch } from 'state/hooks';
import { getAppState, setDrawerOpen } from 'state/app/appSlice';

import { getOrganizationSelector, setOrgPending, setUserOrg } from 'state/organization/organizationSlice';
import MenuIcon from '@mui/icons-material/Menu';
import { getApiURL } from 'utils';
import { theme } from 'theme';

export const AdminLayout = () => {
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { drawerOpen, drawerWidth } = getAppState();
  const organization = getOrganizationSelector();

  const isDesktop = useMediaQuery('(min-width:900px)');

  const fetchOrganization = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        getApiURL(`/OrganizationAccount/GetOrganizationAccountByIdRequest/${user?.sub}`, {
          IncludeOrganization: true,
          IncludeInterviewInvitations: true,
        }),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        dispatch(setUserOrg(data));
        dispatch(setOrgPending(false));
      }
    } catch {
      dispatch(setOrgPending(false));
    }
  };

  // retrieve organization informations
  useEffect(() => {
    if (user && !isLoading && !organization.userOrg.organization?.created) {
      fetchOrganization();
    }
  }, [user, isLoading, getAccessTokenSilently, dispatch]);

  if (!organization.userOrg.organization?.created && !organization.pending) {
    return <CreateOrganization />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ width: { md: `calc(100% - ${drawerWidth}px)` }, ml: { md: `${drawerWidth}px` } }}>
        <Drawer />
        <Box minHeight="calc(100vh - 34px)">
          {/* <SmartActionFeed /> */}
          <Outlet />
        </Box>
        <Typography
          sx={{ color: theme.palette.grey[700] }}
          fontSize="12px"
          textAlign={{ xs: 'center', md: 'start' }}
          ml={{ xs: 0, md: 3 }}
          my={1}
        >
          {t('footerRight')}
        </Typography>
      </Box>
      {!window.location.pathname.includes('message') && <Chat />}
      {!drawerOpen && !isDesktop && (
        <Fab
          sx={{ position: 'fixed', bottom: 30, right: 30 }}
          color="primary"
          onClick={() => dispatch(setDrawerOpen(true))}
        >
          <MenuIcon />
        </Fab>
      )}
    </Box>
  );
};
