import { Button } from '@mui/material';
import { Section } from 'components';
import DoneIcon from '@mui/icons-material/Done';

export const Training = () => {
  return (
    <Section flexDirection="column" gap={2}>
      <Button
        sx={{ justifyContent: 'space-between' }}
        color="inherit"
        onClick={() => console.log('')}
        disableFocusRipple
        disableElevation
      >
        Getting started in
      </Button>
      <Button
        sx={{ justifyContent: 'space-between' }}
        color="inherit"
        onClick={() => console.log('')}
        endIcon={<DoneIcon />}
        disableFocusRipple
        disableElevation
      >
        Working from home
      </Button>
      <Button
        sx={{ justifyContent: 'space-between' }}
        color="inherit"
        onClick={() => console.log('')}
        disableFocusRipple
        disableElevation
      >
        Principles of Secure Coding
      </Button>
      <Button
        sx={{
          justifyContent: 'flex-start',
          '&:hover': {
            background: 'transparent',
          },
        }}
        variant="text"
        size="small"
        color="inherit"
        onClick={() => console.log('')}
        disableFocusRipple
        disableRipple
        disableElevation
      >
        3 more trainings...
      </Button>
    </Section>
  );
};
