import { useTranslation } from 'react-i18next';
import { Avatar, Stack, Typography } from '@mui/material';
import { Tag } from 'components/Tag';
import { UserProfile } from 'types/profileType';

interface SwipeJobProps {
  resume: UserProfile;
}

export const SwipeResume = ({ resume }: SwipeJobProps) => {
  const { t } = useTranslation();

  const skills = String(resume.skills);
  const skillList = skills.split(' ');

  return (
    <>
      <Avatar
        sx={{
          width: '80px',
          height: '80px',
        }}
        // alt={user.name}
        // src={user.picture}
      />
      <Stack alignItems="center">
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
          {resume.firstName} {resume.lastName}
        </Typography>
        <Typography variant="subtitle1">{resume.title}</Typography>
      </Stack>
      <Stack alignItems="center">
        <Typography maxHeight={100} textOverflow="ellipsis" overflow="hidden" align="center">
          {resume.description}
        </Typography>
        <Typography>[...]</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="center" flexWrap="wrap" gap={1} mt={1}>
        {skillList.slice(0, 6).map((skill, i) => skill && <Tag key={i} label={skill} />)}
        {skillList.length > 6 && <Tag label="..." />}
      </Stack>
    </>
  );
};
