import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  GET_CONVERSATIONS,
  GET_CONVERSATION_MESSAGES,
  GET_CONVERSATION_NEXT_PAGE_MESSAGES,
  CREATE_CONVERSATION,
  SEND_MESSAGE,
  RECEIVE_MESSAGE,
  LOOK_FOR_CONVERSATION_BY_RECIPIENT_ID,
  DOWNLOAD_FILE,
  DELETE_FILE_MESSAGE,
  DELETE_MESSAGE
} from './actionTypes';
import { UserProfile } from 'types/profileType';
import {
  fetchConversations,
  fetchMessages,
  fetchNextPageMessages,
  addConversation,
  sendMessage,
  uploadChatFiles,
  updateMessagesData,
  getFile,
  deleteMessageApi,
  deleteFile,
  sendMessageNotification
} from '../../components/Chat/apis';
import {
  FetchConversationsRequest,
  FetchConversationMessagesRequest,
  FetchConversationNextPageMessagesRequest,
  CreateConversationRequest,
  ListConversationsQueryResponse,
  FetchConversationMessageResponse,
  AddMessageResponse,
  CreateConversationResponse,
  LookForConversationByRecipientIdRequest,
  ReadMessagesRequest,
  SendMessageRequest,
  ReceiveMessageRequest,
  DownloadFileRequest,
  DeleteFileMessageRequest,
  DeleteMessageResponse
} from './types';
import { Conversation, Message } from 'types/chatTypes';
import {
  getConversationMessagesData,
  appendMessage,
  removeMessage,
  setConversations,
  setMessages,
  setNextPageMessages,
  getConversationsData,
  setSenderId,
  setCurrentConversationId,
  creatConversation,
  setContactUsers,
  setCurrentRecipientId,
  setMessagesLoading,
  updateMessage
} from './chatSlice';
import { getOtherUserProfiles } from 'state/profile/apis';

function* getConversationsSaga(action: FetchConversationsRequest) {
  try {
    const response: ListConversationsQueryResponse = yield call(fetchConversations, action.payload.userId);

    let chatUserIds: string[] = [];
    const userProfile: UserProfile = yield select((state) => state.profile.userProfile);
    const conversations: Conversation[] = response?.data?.listConversations?.items || [];
    let userIdsToFetch: string[] = [];
    const conversationIds: string[] = [];
    conversations.forEach((conversation) => {
      if (Array.isArray(conversation.userIds)) {
        userIdsToFetch = [...userIdsToFetch, ...conversation.userIds];
      }

      conversationIds.push(conversation.conversationId);

      const recipientId = conversation.userIds?.find((id) => id !== userProfile.id);
      conversation.recipientId = recipientId || '';

      chatUserIds = [...chatUserIds, ...(conversation.userIds?.filter((id) => id !== userProfile.id) || [])];
      chatUserIds = Array.from(new Set(chatUserIds));
    });

    const otherUserProfiles: Record<string, UserProfile> = yield call(getOtherUserProfiles, {
      userIds: chatUserIds,
      token: action.payload.token,
    });

    let initials = "";
    for (const userProfile of Object.values(otherUserProfiles)) {
      initials = (userProfile.firstName ? userProfile.firstName[0] : "") + (userProfile.lastName ? userProfile.lastName[0] : "");
      userProfile.initials = initials;
    }

    yield put(setContactUsers(otherUserProfiles));

    const conversationData: Record<string, Conversation> = {};
    conversations.forEach((conversation) => {
      conversationData[conversation.conversationId] = conversation;
    });

    yield put(setConversations(conversationData));

    // fetch messages for each conversation
    for (const id of conversationIds) {
      const response: FetchConversationMessageResponse = yield call(fetchMessages, id);
      const nextToken = response?.data?.listMessages.nextToken;

      const messages: Message[] = response?.data?.listMessages?.items || [];

      messages.forEach((message) => {
        if (message.messageType === 'file') {
          message.metadata = (message.metadata && JSON.parse((message.metadata as string).replaceAll('\'', '"')));
        }
      });

      yield put(setMessages({ conversationId: id, messages, nextToken }))
    }
  } catch (e) {
    console.log(e);
  }
}

function* createConversationSaga(action: CreateConversationRequest) {
  try {
    const response: CreateConversationResponse = yield call(addConversation, action.payload.userIds);
    const conversationToAdd: Conversation = response?.data?.createConversation;

    const senderId: string | undefined = yield select((state) => state.chat.senderId);

    if (conversationToAdd && senderId) {
      yield put(getConversationsData({ userId: senderId, token: action.payload.token }));

      yield put(setCurrentConversationId(conversationToAdd.conversationId));
      const recipientId = conversationToAdd.userIds?.find((member) => member !== senderId);
      if (recipientId) {
        yield put(setCurrentRecipientId(recipientId));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getConversationMessagesSaga(action: FetchConversationMessagesRequest) {
  try {
    const limit = action.payload.limit;
    const response: FetchConversationMessageResponse = yield call(fetchMessages, action.payload.conversationId, limit);

    const messages: Message[] = response?.data?.listMessages?.items || [];

    messages.forEach((message) => {
      if (message.messageType === 'file') {
        message.metadata = (message.metadata && JSON.parse((message.metadata as string).replaceAll('\'', '"')));
      }
    });


    const nextToken = response?.data?.listMessages.nextToken;

    yield put(setMessages({ conversationId: action.payload.conversationId, messages, nextToken }));
  } catch (e) {
    console.log(e);
  }
}

function* getConversationNextPageMessagesSaga(action: FetchConversationNextPageMessagesRequest) {
  try {
    yield put(setMessagesLoading(true));
    const response: FetchConversationMessageResponse = yield call(
      fetchNextPageMessages,
      action.payload.conversationId,
      action.payload.nextToken
    );

    const messages: Message[] = response?.data?.listMessages?.items || [];

    messages.forEach((message) => {
      if (message.messageType === 'file') {
        message.metadata = (message.metadata && JSON.parse((message.metadata as string).replaceAll('\'', '"')));
      }
    });

    const nextToken = response?.data?.listMessages.nextToken;

    yield put(setNextPageMessages({ conversationId: action.payload.conversationId, messages, nextToken }));
    yield put(setMessagesLoading(false));
  } catch (e) {
    console.log(e);
  }
}


function* sendMessageSaga(action: SendMessageRequest) {
  try {

    const messagePayload = action.payload.messagePayload;
    const email = action.payload.recipientEmail;
    const files = action.payload.files;
    const token = action.payload.token;

    if (messagePayload && messagePayload.content) {
      const response: AddMessageResponse = yield call(sendMessage, messagePayload);
      const message: Message = response?.data?.createMessage;


      // TODO: Need a mechanism to know if the user is online or not, only sends the notification when the user is offline
      if (message.content && email) {
        yield call(sendMessageNotification, { token, email, message: message.content });
      }

      yield put(appendMessage(message));
    }



    const fileMessageIds: string[] = [];
    if (files && files.length > 0) {
      const fileMessages = files.map((file) => {
        const messageId = `${messagePayload.conversationId}-${messagePayload.senderId}-${messagePayload.recipientId}-${Date.now()}`;
        fileMessageIds.push(messageId);
        return {
          messageId,
          conversationId: messagePayload.conversationId as string,
          senderId: messagePayload.senderId,
          recipientId: messagePayload.recipientId,
          content: file.name,
          messageType: "file",
          metadata: {
            key: "",
            title: "",
            size: 0,
            mimetype: "",
            type: "",
          },
          isSending: true

        };
      });

      for (let i = 0; i < fileMessages.length; i++) {
        yield put(appendMessage(fileMessages[i]));
      }

      /* eslint-disable-next-line */
      // @ts-ignore
      const filesInfo: {
        key: string
        mimetype: string
        size: number
        title: string,
        type: string
      }[] = yield call(uploadChatFiles, { files, token });

      for (let i = 0; i < filesInfo.length; i++) {
        const fileMessagePayload = {
          conversationId: messagePayload.conversationId,
          senderId: messagePayload.senderId,
          recipientId: messagePayload.recipientId,
          content: filesInfo[i].key,
          messageType: "file",
          metadata: JSON.stringify(filesInfo[i]).replaceAll('"', '\'')
        };


        const response: AddMessageResponse = yield call(sendMessage, fileMessagePayload);


        const message: Message = response?.data?.createMessage;

        if (message.messageType === 'file') {
          message.metadata = (message.metadata && JSON.parse((message.metadata as string).replaceAll('\'', '"')));
        }



        yield put(updateMessage({
          messageId: fileMessageIds[i],
          conversationId: messagePayload.conversationId as string,
          messageData: { ...message, isSending: false }
        }));
      }
    }



  } catch (e) {
    console.log(e);
  }
}

function* receiveMessageSaga(action: ReceiveMessageRequest) {
  try {
    const message: Message = action.payload.message;
    const token: string = action.payload.token;
    const actionType = action.payload.message.actionType;
    const conversations: Record<string, Conversation> = yield select(state => state.chat.conversations);
    const userProfile: UserProfile = yield select(state => state.profile.userProfile);
    const conversation: Conversation = conversations[message.conversationId];

    if (actionType === 'create') {
      if (!conversation && userProfile.id) {
        yield put(getConversationsData({ userId: userProfile.id, token: token }));
      } else {
        if (message.messageType === 'file') {
          message.metadata = (message.metadata && JSON.parse((message.metadata as string).replaceAll('\'', '"')));
        }
        yield put(appendMessage(message));
      }
    }

    if (actionType === 'delete' && message.senderId !== userProfile.id) {
      yield put(removeMessage(message));
    }
  } catch (e) {
    console.log(e);
  }
}

type UpdateSenderIdAction = {
  type: string,
  payload: UserProfile
};

function* updateSenderIdSaga(action: UpdateSenderIdAction) {
  try {
    const senderId: string = action.payload.id || '';
    yield put(setSenderId(senderId));
  } catch (e) {
    console.log(e);
  }
}

function* lookForConversationByRecipientIdSaga(action: LookForConversationByRecipientIdRequest) {
  try {
    const recipientId: string = action.payload.recipientId;

    const senderId: string | undefined = yield select((state) => state.chat.senderId);
    const conversations: Record<string, Conversation> = yield select((state) => state.chat.conversations);
    const conversationsFetched: boolean = yield select((state) => state.chat.fetched);

    if (recipientId && senderId && conversationsFetched) {
      const conversation = Object.values(conversations).find((conversation) => {
        return conversation.userIds?.includes(recipientId);
      });

      if (conversation) {
        yield put(setCurrentConversationId(conversation.conversationId));
      } else {
        yield put(creatConversation({ userIds: [recipientId, senderId], token: action.payload.token }));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* switchCurrentConversationSaga(action: ReadMessagesRequest) {
  try {
    const conversationId: string = action.payload;

    const messages: Message[] = yield select((state) => state.chat.conversations[conversationId]?.messages || []);

    const currentUserId: string | undefined = yield select((state) => state.chat.senderId);

    const messagesToRead: Message[] = messages.filter((message) => {
      return message.recipientId === currentUserId && !message.isRead;
    });

    const payload = messagesToRead.map((message) => {
      return {
        messageId: message.messageId,
        conversationId: message.conversationId,
        content: message.content,
        createdAt: message.createdAt,
        messageType: message.messageType,
        recipientId: message.recipientId,
        senderId: message.senderId,
        isRead: true,
      };
    });

    if (payload.length > 0) {
      /* eslint-disable-next-line */
      // @ts-ignore
      yield call(updateMessagesData, payload);
      yield put(getConversationMessagesData({ conversationId: conversationId, limit: messages.length }));
    }
  } catch (e) {
    console.log(e);
  }
}

function* downloadFileSaga(action: DownloadFileRequest) {
  try {
    const { key, token, name, messageId, createdAt } = action.payload;
    const response: Response = yield call(getFile, { key, token, messageId, createdAt });

    response.blob().then((blob: BlobPart) => {
      const encodedUri = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');

      link.setAttribute('href', encodedUri);
      link.setAttribute('download', name);

      link.click();
    });

  } catch (e) {
    console.log(e);
  }

}

function* deleteFileMessageSaga(action: DeleteFileMessageRequest) {
  try {
    const { messageId, createdAt, token, key } = action.payload;
    yield put(setMessagesLoading(true));
    yield call(deleteFile, { key, token, messageId, createdAt });
    const response: DeleteMessageResponse = yield call(deleteMessageApi, { messageId, createdAt });
    yield put(removeMessage(response.data.deleteMessage));
    yield put(setMessagesLoading(false));

  } catch (e) {
    console.log(e);
  }
}

function* deleteMessageSaga(action: DeleteFileMessageRequest) {
  try {
    const { messageId, createdAt } = action.payload;
    yield put(setMessagesLoading(true));
    const response: DeleteMessageResponse = yield call(deleteMessageApi, { messageId, createdAt });
    yield put(removeMessage(response.data.deleteMessage));
    yield put(setMessagesLoading(false));
  } catch (e) {
    console.log(e);
  }
}

export function* chatSaga() {
  yield all([
    takeLatest(GET_CONVERSATIONS, getConversationsSaga),
    takeLatest(GET_CONVERSATION_MESSAGES, getConversationMessagesSaga),
    takeLatest(GET_CONVERSATION_NEXT_PAGE_MESSAGES, getConversationNextPageMessagesSaga),
    takeLatest(CREATE_CONVERSATION, createConversationSaga),
    takeLatest(SEND_MESSAGE, sendMessageSaga),
    takeLatest(RECEIVE_MESSAGE, receiveMessageSaga),
    takeLatest("profile/setUserProfile", updateSenderIdSaga),
    takeLatest(LOOK_FOR_CONVERSATION_BY_RECIPIENT_ID, lookForConversationByRecipientIdSaga),
    takeLatest('chat/setCurrentConversationId', switchCurrentConversationSaga),
    takeLatest(DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(DELETE_FILE_MESSAGE, deleteFileMessageSaga),
    takeLatest(DELETE_MESSAGE, deleteMessageSaga)
  ]);
}
