import { Section } from 'components';
import { BarChart } from '@mui/x-charts/BarChart';

export const JobChart = () => {
  return (
    <Section title="Applicant Numbers by Job Position">
      <BarChart
        series={[
          { data: [35, 44, 24, 34] },
          { data: [51, 6, 49, 30] },
          { data: [15, 25, 30, 50] },
          { data: [60, 50, 15, 25] },
        ]}
        height={290}
        xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
        margin={{ top: 20, bottom: 30, left: 40, right: 10 }}
      />
    </Section>
  );
};
