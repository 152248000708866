import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Stack, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Loading, Section } from 'components';
import { ConfirmationDialog } from 'components/Dialog';
import { useSnackbar } from 'components/Snackbar';
import { useBanner } from 'components/Banner';
import { Options, Contact, About, Experiences, Educations, Review } from './Sections';
import { useAppDispatch } from 'state/hooks';
import {
  createProfileData,
  getFileSelector,
  getProfileGenerateSelector,
  getProfilePendingSelector,
  getProfileUploadSelector,
  setProfileGenerate,
  setProfileUpload,
} from 'state/profile/profileSlice';
import { toggleConfirmationDialog } from 'state/dialogs/dialogsSlice';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import DoneIcon from '@mui/icons-material/Done';
import { UserProfile } from 'types/profileType';
import { getApiURL } from 'utils';
import moment from 'moment';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    background: theme.palette.grey[500],
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  zIndex: 1,
  color: 'white',
  borderRadius: '50%',
  background: theme.palette.grey[500],
  ...(ownerState.active && {
    background: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    background: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AccessibilityNewIcon />,
    2: <PersonIcon />,
    3: <DescriptionIcon />,
    4: <WorkIcon />,
    5: <SchoolIcon />,
    6: <DoneIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const CreateProfile = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { closeBanner } = useBanner();

  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pending = getProfilePendingSelector();
  const generate = getProfileGenerateSelector();
  const upload = getProfileUploadSelector();
  const file = getFileSelector();

  useEffect(() => {
    console.log('file save from redux: ', file);
  }, [file]);

  const defaultValues = {
    title: '',
    firstName: user ? user.given_name : '',
    lastName: user ? user.family_name : '',
    birthdate: null,
    email: user ? user.email : '',
    phoneNumber: '',
    location: '',
    description: '',
    skills: [],
    languages: [],
    educations: [],
    experiences: [],
    matchJob: true,
    sexe: 0,
  };

  const { handleSubmit, control, setValue, watch, reset } = useForm<UserProfile>({ defaultValues });

  const steps = [
    t('createProfile.step1'),
    t('createProfile.step2'),
    t('createProfile.step3'),
    t('createProfile.step4'),
    t('createProfile.step5'),
    t('createProfile.step6'),
  ];

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isReview, setIsReview] = useState<boolean>(false);

  useEffect(() => {
    if (generate) {
      setValue('title', upload.title);
      setValue('firstName', upload.firstName);
      setValue('lastName', upload.lastName);
      setValue('email', user?.email);
      setValue('phoneNumber', upload.phoneNumber);
      setValue('birthdate', upload.birthdate);
      setValue('location', upload.location);
      setValue('description', upload.aboutMe);
      setValue('skills', upload.skills);
      setValue('languages', upload.languages);
      setValue('educations', upload.educations);
      setValue('experiences', upload.experiences);
      setActiveStep(1);
    }
  }, [generate]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    if (activeStep === steps.length - 1) {
      setIsReview(true);
    }

    if (activeStep === 1) {
      dispatch(
        toggleConfirmationDialog({
          open: true,
          message: t('dialog.question.lost'),
          onConfirmation: () => {
            dispatch(setProfileGenerate(false));
            dispatch(setProfileUpload({}));
            setIsReview(false);
            setActiveStep(0);
            window.scrollTo(0, 0);
          },
        })
      );
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const uploadCV = async () => {
    const formData = new FormData();
    // formData.append('file', resume, resume.name);
    formData.append('type', 'Resume');

    const token = await getAccessTokenSilently();
    const res = await fetch(getApiURL('/File/UploadFileRequest'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }).then((res) => res.json());

    console.log('res: ', res);
  };

  const onSubmit = handleSubmit(async (data: UserProfile) => {
    if (data.birthdate) {
      data.birthdate = moment(data.birthdate).format('YYYY-MM-DD');
    }

    user &&
      getAccessTokenSilently().then((token) =>
        dispatch(createProfileData({ userId: user.sub as string, token, user: data, navigate, showSnackbar }))
      );

    closeBanner();
  });

  if (pending) {
    return <Loading condition={pending} />;
  }

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}
      maxWidth="md"
    >
      <Typography variant="h6" textTransform="uppercase">
        {t('pages.createProfile')}
      </Typography>
      <Typography variant="h4" mb={5}>
        {t('step')} {activeStep + 1} :{' '}
        {steps.map((item, index) => {
          if (index === activeStep) return item;
        })}
      </Typography>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps} sx={{ mb: 8 }}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && <Options handleNext={handleNext} reset={reset} />}
      <form onSubmit={onSubmit}>
        {activeStep === 1 && (
          <Section>
            <Contact control={control} />
          </Section>
        )}
        {activeStep === 2 && (
          <Section>
            <About control={control} watch={watch} />
          </Section>
        )}
        {activeStep === 3 && <Experiences control={control} />}
        {activeStep === 4 && <Educations control={control} />}
        {activeStep === 5 && <Review watch={watch} setActiveStep={setActiveStep} setIsReview={setIsReview} />}
        {activeStep !== 0 && (
          <Stack flexDirection="row" justifyContent="space-between" mt={6}>
            <Button type="button" variant="outlined" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              {t('button.back')}
            </Button>
            <Stack flexDirection="row" gap={1}>
              {activeStep < steps.length - 1 && (
                <Button type="button" onClick={handleNext}>
                  {t('button.next')}
                </Button>
              )}
              {isReview && activeStep !== steps.length - 1 && (
                <Button type="button" onClick={() => setActiveStep(steps.length - 1)}>
                  Review
                </Button>
              )}
            </Stack>
            {activeStep === steps.length - 1 && <Button type="submit">{t('button.create')}</Button>}
          </Stack>
        )}
      </form>
      <ConfirmationDialog />
    </Container>
  );
};
