import { useContext } from 'react';
import { BannerContext, BannerContextActions } from '.';

export const useBanner = (): BannerContextActions => {
  const context = useContext(BannerContext);

  if (!context) {
    throw new Error('useBanner must be used within a BannerProvider');
  }

  return context;
};
