import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Section } from 'components/Section';
import { EducationEditDialog } from 'components/Dialog/ProfileEditDialog/EducationEditDialog';
import { useAppDispatch } from 'state/hooks';
import { getVisitProfileSelector } from 'state/profile/profileSlice';
import { toggleEducationEditDialog } from 'state/dialogs/dialogsSlice';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getApiURL } from 'utils';
import { Education } from 'types/profileType';

interface EducationsProps {
  editable: boolean;
}

export const Educations = ({ editable }: EducationsProps) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const onOpen = () => dispatch(toggleEducationEditDialog(true));
  const visitProfile = getVisitProfileSelector();

  const [isEditable, setIsEditable] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState<Education>({});

  const deleteEducation = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/UserProfile/DeleteUserEducationRequest/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Section
      title={t('profile.education.title')}
      onAdd={onOpen}
      edit={isEditable}
      onEdit={() => visitProfile.educations && setIsEditable(!isEditable)}
      editable={editable}
      marginBottom={3}
    >
      <Stack spacing={1.5} divider={<Divider />}>
        {visitProfile.educations?.map((education: Education) => (
          <Grid key={education.id} container flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography fontWeight={600}>{education.subject}</Typography>
              <Typography>{education.diploma}</Typography>
              <Typography>{education.schoolName}</Typography>
              <Typography>{education.location}</Typography>
              <Stack flexDirection="row" gap={0.5}>
                {education.dateStart && <Typography>{moment(education.dateStart).format('YYYY')} </Typography>}
                {(education.dateStart && education.dateEnd) || (education.dateStart && education.isPresent)
                  ? '-'
                  : null}
                {education.dateEnd && <Typography>{moment(education.dateEnd).format('YYYY')}</Typography>}
                {education.isPresent && <Typography>{t('present')}</Typography>}
              </Stack>
            </Grid>
            {isEditable && (
              <Grid item xs={1} display="flex" justifyContent="end">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedEducation(education);
                    onOpen();
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="error" onClick={() => deleteEducation(education.id as string)}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Stack>
      <EducationEditDialog education={selectedEducation} setEducation={setSelectedEducation} />
    </Section>
  );
};
