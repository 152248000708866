import { useState } from 'react';
import moment from 'moment';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Fab, IconButton, Stack, Typography } from '@mui/material';
import { Section } from 'components';
import { EducationCreateDialog } from 'components/Dialog/ProfileCreateDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleEducationCreateDialog } from 'state/dialogs/dialogsSlice';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UserProfile } from 'types/profileType';

interface EducationsProps {
  control: Control<UserProfile, any>;
}

export const Educations = ({ control }: EducationsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onOpen = () => dispatch(toggleEducationCreateDialog(true));

  const educations = useWatch({
    name: 'educations',
    control,
  });

  const {
    append: educationsAppend,
    remove: educationsRemove,
    update: educationsUpdate,
  } = useFieldArray({
    control,
    name: 'educations',
  });

  const [selectedEducationIndex, setSelectedEducationIndex] = useState<number | undefined>(undefined);

  return (
    <Stack flexDirection="column">
      {educations && educations.length === 0 && (
        <Button sx={{ alignSelf: 'center' }} onClick={onOpen} type="button" startIcon={<AddIcon />}>
          {t('profile.education.add')}
        </Button>
      )}
      <Stack gap={3}>
        {educations?.map((education, index) => (
          <Section key={index} justifyContent="space-between" flexDirection="row">
            <Stack>
              <Typography fontWeight={600}>{education.subject}</Typography>
              <Typography>{education.diploma}</Typography>
              <Typography>{education.schoolName}</Typography>
              <Typography>{education.location}</Typography>
              <Stack flexDirection="row" gap={0.5}>
                {education.dateStart && <Typography>{moment(education.dateStart).format('YYYY')} </Typography>}
                {(education.dateStart && education.dateEnd) || (education.dateStart && education.isPresent) ? (
                  <Typography>-</Typography>
                ) : null}
                {education.dateEnd && <Typography>{moment(education.dateEnd).format('YYYY')}</Typography>}
                {education.isPresent && <Typography>{t('present')}</Typography>}
              </Stack>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedEducationIndex(index);
                  onOpen();
                }}
                type="button"
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton color="error" onClick={() => educationsRemove(index)} type="button">
                <CloseOutlinedIcon />
              </IconButton>
            </Stack>
          </Section>
        ))}
      </Stack>
      {educations && educations.length > 0 && (
        <Fab sx={{ alignSelf: 'center', mt: 3 }} size="medium" color="primary" onClick={onOpen}>
          <AddIcon />
        </Fab>
      )}
      <EducationCreateDialog
        index={selectedEducationIndex}
        setIndex={setSelectedEducationIndex}
        educations={educations}
        educationsUpdate={educationsUpdate}
        educationsAppend={educationsAppend}
      />
    </Stack>
  );
};
