import { all, fork } from 'redux-saga/effects';
import { profileSaga } from './profile/saga';
import { jobSaga } from './job/saga';
import { swipeJobSaga } from './swipeJob/saga';
import { swipeUserSaga } from './swipeUser/saga';
import { donationSaga } from './donation/saga';
import { chatSaga } from './chat/saga';

export function* rootSaga() {
  yield all([fork(profileSaga)]);
  yield all([fork(jobSaga)]);
  yield all([fork(swipeJobSaga)]);
  yield all([fork(swipeUserSaga)]);
  yield all([fork(donationSaga)]);
  yield all([fork(chatSaga)]);
}
