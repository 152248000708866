import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getApiURL, getRoleNameById } from 'utils';
import { UserOrg } from 'types/organizationType';
import { useAuth0 } from '@auth0/auth0-react';
import { Tag } from './Tag';

interface UserOrgRowProps {
  data: UserOrg;
}

export const UserOrgRow = ({ data }: UserOrgRowProps) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const onDeactivateUserOrg = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/OrganizationAccount/DeactivateOrganizationAccountsRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ OrganizationAccountIds: [data.id] }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  const onActivateUserOrg = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/OrganizationAccount/ActivateOrganizationAccountsRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ OrganizationAccountIds: [data.id] }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  // for dev purpose only
  const onDeleteUserOrg = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/OrganizationAccount/DeleteOrganizationAccountRequest/${data.id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Box sx={{ background: 'white', borderRadius: '20px' }} p={2} mb={2}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Typography>
            {data.firstName} {data.lastName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{data.email}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{getRoleNameById(data.roleId)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Tag label={data.isActive ? 'Active' : 'Deactivate'} />
        </Grid>
        {user?.email !== data.email && (
          <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                data.isActive ? onDeactivateUserOrg() : onDeleteUserOrg();
              }}
              disableRipple
            >
              {data.isActive ? (
                <DeleteOutlineOutlinedIcon sx={{ color: '#d32f2f' }} />
              ) : (
                <Tooltip title="Delete Forever">
                  <DeleteOutlineOutlinedIcon sx={{ color: '#d32f2f' }} />
                </Tooltip>
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
