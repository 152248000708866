import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Fab, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch } from 'state/hooks';
import { getProfileSelector, profileRequestData } from 'state/profile/profileSlice';
import { getAppState, setDrawerOpen } from 'state/app/appSlice';
import { getJobState } from 'state/job/jobSlice';
import { Chat } from './Chat';
import { Drawer } from './Drawer';
import { useBanner } from './Banner';
import { useChat } from './Chat/useChat';
import { theme } from 'theme';

export const Layout = () => {
  const { isAuthenticated, user, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  const { showBanner } = useBanner();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { init, getConversations } = useChat();

  const { drawerOpen, drawerWidth } = getAppState();
  const { pending: jobPending } = getJobState();
  const { generate, pending, userProfile } = getProfileSelector();

  const isDesktop = useMediaQuery('(min-width:900px)');

  // initiate user for the chat
  useEffect(() => {
    if (userProfile.id) {
      init(userProfile.id);
      getConversations(userProfile.id);
    }
  }, [userProfile]);

  // retrieve profile informations
  useEffect(() => {
    if (user && !isLoading && !userProfile.created) {
      getAccessTokenSilently().then((token) => {
        dispatch(profileRequestData({ userId: user.sub as string, token }));
        // dispatch(setPermissions(decodeToken(token).permissions));
      });
    }
  }, [user, isLoading, userProfile, getAccessTokenSilently, dispatch]);

  // banner to insist user to signup/login
  useEffect(() => {
    if (!isAuthenticated && !isLoading && !jobPending) {
      showBanner(
        'Unlock your career potential with Workinder—sign up today!',
        'The easiest way to find your dream job, powered by our cutting-edge AI. Experience a seamless job search tailored just for you!',
        <>
          <Button
            onClick={() =>
              loginWithRedirect({ authorizationParams: { screen_hint: 'signup', redirect_uri: window.location.href } })
            }
            size="small"
          >
            Sign up
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: window.location.href,
                },
              })
            }
            size="small"
          >
            Log in
          </Button>
        </>
      );
    } else if (isAuthenticated && !isLoading && !userProfile.created && !pending && !generate && !jobPending) {
      showBanner(
        'Unlock your full experience—create your profile today!',
        'Let our smart technology connect you with jobs that match your skills and aspirations. Simplify your job search and find your perfect fit faster.',
        <Button onClick={() => navigate('/CreateProfile')} size="small">
          Start
        </Button>
      );
    }
  }, [isAuthenticated, userProfile, isLoading, pending, generate, jobPending]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ width: { md: `calc(100% - ${drawerWidth}px)` }, ml: { md: `${drawerWidth}px` } }}>
        <Drawer />
        <Box minHeight="calc(100vh - 34px)">
          <Outlet />
        </Box>
        <Typography
          sx={{ color: theme.palette.grey[700] }}
          fontSize="12px"
          textAlign={{ xs: 'center', md: 'start' }}
          ml={{ xs: 0, md: 3 }}
          my={1}
        >
          {t('footerRight')}
        </Typography>
      </Box>
      {!window.location.pathname.includes('message') && <Chat />}
      {!drawerOpen && !isDesktop && (
        <Fab
          sx={{ position: 'fixed', bottom: 30, right: 30 }}
          color="primary"
          onClick={() => dispatch(setDrawerOpen(true))}
        >
          <MenuIcon />
        </Fab>
      )}
    </Box>
  );
};
