import { useTranslation } from 'react-i18next';
import { UseFormReset } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import { Section } from 'components';
import { ResumeUploadDialog } from 'components/Dialog';
import { useAppDispatch } from 'state/hooks';
import { toggleResumeUploadDialog } from 'state/dialogs/dialogsSlice';
import { ReactComponent as Option1Svg } from '../../assets/svg/cv.svg';
import { ReactComponent as Option2Svg } from '../../assets/svg/building.svg';
import { UserProfile } from 'types/profileType';

interface OptionsProps {
  handleNext: () => void;
  reset: UseFormReset<UserProfile>;
}

export const Options = ({ handleNext, reset }: OptionsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Grid container spacing={5} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <Section>
          <Option1Svg />
          <Typography mt={4} mb={3} textAlign="center">
            {t('createProfile.text.option1')}
          </Typography>
          <Button onClick={() => dispatch(toggleResumeUploadDialog(true))} fullWidth>
            {t('button.upload')}
          </Button>
        </Section>
      </Grid>
      <Grid item xs={12} md={6}>
        <Section>
          <Option2Svg />
          <Typography mt={4} mb={3} textAlign="center">
            {t('createProfile.text.option2')}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              handleNext();
            }}
            fullWidth
          >
            {t('button.begin')}
          </Button>
        </Section>
      </Grid>
      <ResumeUploadDialog />
    </Grid>
  );
};
