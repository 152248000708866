import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { profileRequestData } from 'state/profile/profileSlice';
import { showAboutEditDialog, toggleAboutEditDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';

interface AboutEditDialogProps {
  description?: string;
}

export const AboutEditDialog = ({ description }: AboutEditDialogProps) => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showAboutEditDialog();
  const onClose = () => {
    setAbout('');
    dispatch(toggleAboutEditDialog(false));
  };

  const [about, setAbout] = useState(description);

  useEffect(() => {
    if (description) {
      setAbout(description);
    }
  }, [open, description]);

  const updateDescription = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/UserProfile/UpdateUserProfileRequest'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ description: about }),
    });

    if (response.ok) {
      user &&
        getAccessTokenSilently().then((token) => dispatch(profileRequestData({ userId: user.sub as string, token })));
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('profile.about.edit')}
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <TextField value={about} onChange={(e) => setAbout(e.target.value)} multiline rows={7} />
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button onClick={updateDescription}>{t('button.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};
