type ExperienceRangeProps = {
  experienceMin?: string; 
  experienceMax?: string;
};

export const formatExperienceRange = ({ experienceMin, experienceMax }: ExperienceRangeProps): string => {
  if (!experienceMin && !experienceMax) {
    return '';
  }

  let result = '';

  if (experienceMin && !experienceMax) {
    result = `Minimum ${experienceMin}`;
  } else if (experienceMin && experienceMax) {
    result = `${experienceMin} - ${experienceMax}`;
  } else if (!experienceMin && experienceMax) {
    result = `Up to ${experienceMax}`;
  }

  return `${result} years`;
};
