import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Container, Typography } from '@mui/material';
import { TextField } from 'components/Form';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

type SupportMessage = {
  name?: string;
  subject?: string;
  message?: string;
};

export const Support = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm<SupportMessage>({
    defaultValues: {
      name: '',
      subject: '',
      message: '',
    },
  });

  const isAdmin = window.location.pathname.includes('admin');

  const onSubmit = handleSubmit((data: SupportMessage) => {
    console.log(data);
    reset();
  });

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}
      maxWidth="md"
    >
      <Typography sx={{ mb: '15px' }} variant="h4">
        Contact Us
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField
          name="name"
          label={t('name')}
          control={control}
          placeholder={t('placeholder.name')}
          marginBottom={3}
          required
        />
        <TextField name="subject" label="Subject" control={control} placeholder="Subject" marginBottom={3} required />
        <TextField
          name="message"
          label="Message"
          control={control}
          placeholder={t('placeholder.message')}
          rows={5}
          multiline
          marginBottom={3}
          required
        />
        <Button type="submit" fullWidth>
          Send
        </Button>
      </form>
      {!isAdmin && (
        <Button
          sx={{ mt: 10 }}
          id="donation-btn"
          startIcon={<VolunteerActivismIcon />}
          onClick={() => navigate('/donation')}
        >
          Donation
        </Button>
      )}
    </Container>
  );
};
