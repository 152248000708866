import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Select, TextField, ToggleButton } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { getJobDescription, getJobDescriptionData } from 'state/job/jobSlice';
import { showOverviewEditDialog, toggleOverviewEditDialog } from 'state/dialogs/jobDialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { JobDescription } from 'types/jobType';

export const OverviewEditDialog = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const open = showOverviewEditDialog();
  const jobDescription = getJobDescription();
  const onClose = () => dispatch(toggleOverviewEditDialog(false));

  const [job, setJob] = useState<JobDescription>({});

  useEffect(() => {
    if (jobDescription) {
      setJob({
        title: jobDescription.title,
        companyName: jobDescription.companyName,
        salaryMin: jobDescription.salaryMin,
        salaryMax: jobDescription.salaryMax,
        salaryType: jobDescription.salaryType,
        isOnSite: jobDescription.isOnSite,
        isRemote: jobDescription.isRemote,
        isHybrid: jobDescription.isHybrid,
        isFullTime: jobDescription.isFullTime,
        isPartTime: jobDescription.isPartTime,
        isContract: jobDescription.isContract,
        isInternship: jobDescription.isInternship,
        experienceMin: jobDescription.experienceMin,
        experienceMax: jobDescription.experienceMax,
      });
    }
  }, [open, jobDescription]);

  const updateOverview = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Job/UpdateJobRequest/${jobDescription.id}`), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(job),
    });

    if (response.ok) {
      jobDescription.id && dispatch(getJobDescriptionData({ jobId: jobDescription.id }));
      setJob({});
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setJob({});
        onClose();
      }}
      PaperProps={{ style: { borderRadius: '20px' } }}
      fullWidth
    >
      <DialogTitle>
        {t('jobInfo.overview')}
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <TextField
            label={t('jobLabel.title')}
            value={job.title}
            onChange={(e) => setJob({ ...job, title: e.target.value })}
            required
          />
          <TextField
            label={t('jobLabel.company')}
            value={job.companyName}
            onChange={(e) => setJob({ ...job, companyName: e.target.value })}
            disabled
          />
          <Stack flexDirection="row" alignItems="end" gap={2}>
            <TextField
              label={t('jobLabel.salary')}
              value={job.salaryMin}
              onChange={(e) => setJob({ ...job, salaryMin: e.target.value })}
              startAdornment={
                <InputAdornment position="start">
                  <Typography>$</Typography>
                </InputAdornment>
              }
              placeholder="Min"
              textAlign="right"
              required
            />
            <TextField
              value={job.salaryMax}
              onChange={(e) => setJob({ ...job, salaryMax: e.target.value })}
              startAdornment={
                <InputAdornment position="start">
                  <Typography>$</Typography>
                </InputAdornment>
              }
              placeholder="Max"
              textAlign="right"
              required
            />
            <Select
              value={job.salaryType}
              onChange={(e) => setJob({ ...job, salaryType: e.target.value as number })}
              options={[
                { value: 1, label: t('perHour') },
                { value: 2, label: t('perYear') },
              ]}
              required
            />
          </Stack>
          <Typography mb="5px">Workplace Type</Typography>
          <Stack direction="row" gap={1}>
            <ToggleButton
              value={job.isOnSite}
              onChange={() => setJob({ ...job, isOnSite: !job.isOnSite })}
              label={t('workplaceType.onSite')}
            />
            <ToggleButton
              value={job.isRemote}
              onChange={() => setJob({ ...job, isRemote: !job.isRemote })}
              label={t('workplaceType.remote')}
            />
            <ToggleButton
              value={job.isHybrid}
              onChange={() => setJob({ ...job, isHybrid: !job.isHybrid })}
              label={t('workplaceType.hybrid')}
            />
          </Stack>
          <Typography mb="5px">Job Type</Typography>
          <Stack direction="row" gap={1}>
            <ToggleButton
              value={job.isFullTime}
              onChange={() => setJob({ ...job, isFullTime: !job.isFullTime })}
              label={t('jobType.fullTime')}
            />
            <ToggleButton
              value={job.isPartTime}
              onChange={() => setJob({ ...job, isPartTime: !job.isPartTime })}
              label={t('jobType.partTime')}
            />
            <ToggleButton
              value={job.isContract}
              onChange={() => setJob({ ...job, isContract: !job.isContract })}
              label={t('jobType.contract')}
            />
            <ToggleButton
              value={job.isInternship}
              onChange={() => setJob({ ...job, isInternship: !job.isInternship })}
              label={t('jobType.internship')}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="end" gap={2}>
            <TextField
              label="Experience"
              value={job.experienceMin}
              onChange={(e) => setJob({ ...job, experienceMin: e.target.value })}
              placeholder="Min"
              required
            />
            <TextField
              value={job.experienceMax}
              onChange={(e) => setJob({ ...job, experienceMax: e.target.value })}
              placeholder="Max"
              required
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button onClick={updateOverview}>{t('button.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};
