import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { TextField } from 'components/Form';
import { useSnackbar } from 'components/Snackbar';
import { useAppDispatch } from 'state/hooks';
import { showInviteDialog, toggleInviteDialog } from 'state/dialogs/jobDialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { ROLES, validateEmail } from 'utils';

type Recipient = {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
};

export const InviteDialog = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const open = showInviteDialog();
  const onClose = () => dispatch(toggleInviteDialog(false));

  const [recipient, setRecipient] = useState<Recipient>({
    firstName: '',
    lastName: '',
    email: '',
    roleId: '',
  });
  const [isError, setIsError] = useState(false);

  const inviteSubordinate = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Organization/SendOrganizationInvitationRequest/'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(recipient),
    });

    if (response.ok) {
      showSnackbar('Invitation sent!', 'success');
      window.location.reload();
    } else {
      showSnackbar('This service may not work at the moment, try again later!', 'error');
    }
  };

  const onSubmit = () => {
    const isValid = validateEmail(recipient.email);
    setIsError(!isValid);

    if (isValid) {
      console.log('recipient: ', recipient);
      inviteSubordinate();
      setRecipient({
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
      });
      onClose();
    }
  };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setRecipient((prevRecipient) => ({
      ...prevRecipient,
      roleId: value,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        Invitation
        <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('profile.firstName')}
              value={recipient.firstName}
              onChange={(e) => setRecipient({ ...recipient, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('profile.lastName')}
              value={recipient.lastName}
              onChange={(e) => setRecipient({ ...recipient, lastName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('email')}
              value={recipient.email}
              onChange={(e) => setRecipient({ ...recipient, email: e.target.value })}
              error={isError}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography mb={0.5}>Role</Typography>
            <Select value={recipient.roleId} onChange={handleRoleChange} fullWidth>
              {ROLES.map((role) => (
                <MenuItem key={role?.id} value={role?.id}>
                  {role?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="button" fullWidth disabled={!recipient} onClick={onSubmit}>
          {t('button.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
