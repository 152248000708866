import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { getUserProfileSelector } from 'state/profile/profileSlice';

interface UserAvatarProps {
  size: number;
}

export const UserAvatar = ({ size }: UserAvatarProps) => {
  const navigate = useNavigate();
  const userProfile = getUserProfileSelector();

  if (!userProfile) return null;

  return (
    <Avatar
      sx={{
        m: 1,
        width: `${size}px`,
        height: `${size}px`,
        cursor: 'pointer',
        borderRadius: '50%',
        alignSelf: 'center',
      }}
      alt={userProfile.firstName}
      src={userProfile.avatarLink}
      onClick={() => navigate(`/profile?profileId=${userProfile.id}`)}
      variant="rounded"
    />
  );
};
