import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextField, UploadFile } from 'components/Form';
import { useSnackbar } from 'components/Snackbar';
import { useAppDispatch } from 'state/hooks';
import { showApplyDialog, toggleApplyDialog } from 'state/dialogs/dialogsSlice';
import { getProfileSelector } from 'state/profile/profileSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { Applier } from 'types/profileType';

export const ApplyDialog = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const open = showApplyDialog();
  const onClose = () => dispatch(toggleApplyDialog(false));
  const { userProfile } = getProfileSelector();

  const currentUrl = new URL(window.location.href);
  const jobId = currentUrl.searchParams.get('jobId');

  const { handleSubmit, control, setValue, watch, reset } = useForm<Applier>({
    defaultValues: {
      jobId: jobId ?? '',
      userName: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : user?.name ?? '',
      email: userProfile?.email ?? '',
      message: '',
      resume: userProfile.resumeLink,
    },
  });

  const postData = async (data: FormData) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL('/Application/CreateApplicationRequest'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }

    showSnackbar('Apply successfully!', 'success');
    reset();
  };

  const onSubmit = handleSubmit((data: Applier) => {
    const formData = new FormData();
    jobId && formData.append('jobId', jobId);
    formData.append('userName', data.userName as string);
    formData.append('email', data.email as string);
    !data.message && formData.append('message', data.message as string);
    !userProfile.resumeLink && formData.append('resume', data.resume, data.resume.name);

    postData(formData).catch(console.error);
    onClose();
    navigate(`/job?jobId=${jobId}`);
  });

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {t('apply.title')}
          <CloseIcon sx={{ cursor: 'pointer' }} type="button" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <TextField
            name="userName"
            label={t('name')}
            control={control}
            placeholder={t('placeholder.name')}
            marginBottom={3}
            required
          />
          <TextField
            name="email"
            label={t('email')}
            control={control}
            placeholder={t('placeholder.email')}
            marginBottom={3}
            required
          />
          <TextField
            name="message"
            label="Message"
            control={control}
            placeholder={t('placeholder.message')}
            multiline
            rows={5}
            marginBottom={3}
          />
          {!userProfile.resumeLink && (
            <UploadFile name="resume" label={t('apply.upload')} control={control} setValue={setValue} watch={watch} />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" fullWidth>
            {t('button.apply')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
