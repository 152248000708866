import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Stack } from '@mui/material';
import { Section, Tag } from 'components';
import { SkillEditDialog } from 'components/Dialog/JobEditDialog';
import { useAppDispatch } from 'state/hooks';
import { toggleSkillEditDialog } from 'state/dialogs/dialogsSlice';
import { getJobDescription } from 'state/job/jobSlice';
import CloseIcon from '@mui/icons-material/Close';
import { getApiURL } from 'utils';
import { Skill } from 'types/profileType';

export const Skills = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const jobDescription = getJobDescription();
  const editable: boolean = location.pathname.startsWith('/admin');

  const [isEditable, setIsEditable] = useState(false);

  const deleteSkill = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/Job/DeleteJobSkillRequest/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobId: jobDescription.id }),
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Section
      title={t('profile.skill.title')}
      editable={editable}
      edit={isEditable}
      onAdd={() => dispatch(toggleSkillEditDialog(true))}
      onEdit={() => setIsEditable(!isEditable)}
      marginBottom={3}
    >
      <Stack flexDirection="row" flexWrap="wrap" gap={1}>
        {jobDescription?.skills &&
          jobDescription.skills.length !== 0 &&
          (jobDescription.skills as Skill[]).map((skill) => (
            <Tag
              key={skill.id}
              label={skill.name}
              icon={
                isEditable ? (
                  <IconButton
                    type="button"
                    size="small"
                    color="error"
                    onClick={() => deleteSkill(skill.id as string)}
                    disableRipple
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                ) : undefined
              }
            />
          ))}
      </Stack>
      <SkillEditDialog />
    </Section>
  );
};
