import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { TextField } from 'components/Form';
import { Organization } from 'types/organizationType';

interface CompanyProps {
  control: Control<Organization, any>;
}

export const Company = ({ control }: CompanyProps) => {
  const { t } = useTranslation();

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row' }} container spacing={3}>
      <Grid item xs={12}>
        <TextField name="name" control={control} label="Company Name *" required />
      </Grid>
      <Grid item xs={6}>
        <TextField name="location" control={control} label={t('location')} />
      </Grid>
      <Grid item xs={6}>
        <TextField name="phoneNumber" control={control} label="Phone Number" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="industry" control={control} label="Industry" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="website" control={control} label="Website" />
      </Grid>
    </Grid>
  );
};
