import { useEffect, useState } from 'react';
import moment from 'moment';
import { UseFieldArrayAppend, UseFieldArrayUpdate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { DatePicker, TextField } from 'components/Form';
import { useAppDispatch } from 'state/hooks';
import { showExperienceCreateDialog, toggleExperienceCreateDialog } from 'state/dialogs/dialogsSlice';
import CloseIcon from '@mui/icons-material/Close';
import { Experience, UserProfile } from 'types/profileType';

interface ExperienceCreateDialogDialogProps {
  index?: number;
  setIndex?: (val: number | undefined) => void;
  experiences?: Experience[];
  experiencesAppend: UseFieldArrayAppend<UserProfile, 'experiences'>;
  experiencesUpdate: UseFieldArrayUpdate<UserProfile, 'experiences'>;
}

export const ExperienceCreateDialog = ({
  index,
  setIndex,
  experiences,
  experiencesAppend,
  experiencesUpdate,
}: ExperienceCreateDialogDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = showExperienceCreateDialog();
  const onClose = () => {
    setExperience({});
    setIndex && setIndex(undefined);
    dispatch(toggleExperienceCreateDialog(false));
  };
  const [experience, setExperience] = useState<Experience>({});

  useEffect(() => {
    if (experiences && typeof index === 'number') {
      setExperience(experiences[index]);
    }
  }, [open]);

  const handleSave = () => {
    if (experience) {
      if (typeof index === 'number') {
        experiencesUpdate(index, experience);
      } else {
        experiencesAppend(experience);
      }
    }
    onClose();
  };

  return (
    <Dialog open={open} PaperProps={{ style: { borderRadius: '20px' } }} fullWidth>
      <DialogTitle>
        {t('profile.experience.add')} <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DatePicker
              label={t('profile.experience.startDate')}
              value={experience?.dateStart ? moment(experience?.dateStart) : null}
              onChange={(date) => setExperience({ ...experience, dateStart: date })}
              views={['month', 'year']}
              format="MM/YYYY"
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              label={t('profile.experience.endDate')}
              value={experience?.dateEnd ? moment(experience?.dateEnd) : null}
              onChange={(date) => setExperience({ ...experience, dateEnd: date, isPresent: false })}
              views={['month', 'year']}
              format="MM/YYYY"
            />
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <FormControlLabel
              sx={{ mt: '20px' }}
              label="Present"
              control={
                <Checkbox
                  checked={experience?.isPresent ?? false}
                  onChange={(e) => setExperience({ ...experience, isPresent: e.target.checked, dateEnd: null })}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={experience?.companyName}
              onChange={(e) => setExperience({ ...experience, companyName: e.target.value })}
              label={t('profile.experience.company')}
              placeholder="Enter a company"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={experience?.role}
              onChange={(e) => setExperience({ ...experience, role: e.target.value })}
              label={t('profile.experience.role')}
              placeholder="Enter a role"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={experience?.taskDescription}
              onChange={(e) => setExperience({ ...experience, taskDescription: e.target.value })}
              label={t('profile.experience.task')}
              placeholder="Enter a field of task"
              multiline
              minRows={2}
              maxRows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={experience?.location}
              onChange={(e) => setExperience({ ...experience, location: e.target.value })}
              label={t('profile.experience.location')}
              placeholder="Enter a location"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button type="button" onClick={handleSave}>
          {t('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
