import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Breakpoint, Container, ContainerProps } from '@mui/material';
import { Loading } from './Loading';
import { getProfilePendingSelector, getUserProfileSelector } from 'state/profile/profileSlice';
import { CreateProfile } from 'CreateProfile';

interface ProfileGuardProps extends ContainerProps {
  children: React.ReactNode;
  maxWidth?: Breakpoint;
}

export const ProfileGuard = ({ children, maxWidth = 'xl', ...props }: ProfileGuardProps) => {
  const { user, isLoading } = useAuth0();
  const userProfile = getUserProfileSelector();
  const pending = getProfilePendingSelector();
  const isAdmin = window.location.pathname.includes('admin');

  if (!isAdmin && user && typeof userProfile === 'string' && !pending && !isLoading) {
    return <CreateProfile />;
  }

  if (user && userProfile && !pending && !isLoading) {
    return (
      <Container sx={{ py: 4 }} {...props} maxWidth={maxWidth}>
        {children}
      </Container>
    );
  }

  return <Loading condition={!user && !userProfile && pending && isLoading} />;
};
