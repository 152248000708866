import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'state/hooks';

type ConfirmatioDialog = {
  open: boolean;
  message: string;
  onConfirmation?: () => void;
};

type MatchDialog = {
  open: boolean;
  jobId: string;
  userProfileId: string;
};

export interface DialogsState {
  showApplyDialog: boolean;
  showJobUploadDialog: boolean;
  showResumeUploadDialog: boolean;
  showResumeReuploadDialog: boolean;
  showEducationCreateDialog: boolean;
  showExperienceCreateDialog: boolean;
  showAboutEditDialog: boolean;
  showContactEditDialog: boolean;
  showEducationEditDialog: boolean;
  showExperienceEditDialog: boolean;
  showLanguageEditDialog: boolean;
  showSkillEditDialog: boolean;
  showMatchDialog: MatchDialog;
  confirmationDialog: ConfirmatioDialog;
  showSubtmitGPTDialog: boolean;
  showNoteDialog: boolean;
  showNotesDialog: boolean;
}

const initialState: DialogsState = {
  showApplyDialog: false,
  showJobUploadDialog: false,
  showResumeUploadDialog: false,
  showResumeReuploadDialog: false,
  showEducationCreateDialog: false,
  showExperienceCreateDialog: false,
  showAboutEditDialog: false,
  showContactEditDialog: false,
  showEducationEditDialog: false,
  showExperienceEditDialog: false,
  showLanguageEditDialog: false,
  showSkillEditDialog: false,
  showMatchDialog: {
    open: false,
    jobId: '',
    userProfileId: '',
  },
  confirmationDialog: {
    open: false,
    message: '',
  },
  showSubtmitGPTDialog: false,
  showNoteDialog: false,
  showNotesDialog: false,
};

export const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    toggleApplyDialog: (state, action: PayloadAction<boolean>) => {
      state.showApplyDialog = action.payload;
    },
    toggleJobUploadDialog: (state, action: PayloadAction<boolean>) => {
      state.showJobUploadDialog = action.payload;
    },
    toggleResumeUploadDialog: (state, action: PayloadAction<boolean>) => {
      state.showResumeUploadDialog = action.payload;
    },
    toggleResumeReuploadDialog: (state, action: PayloadAction<boolean>) => {
      state.showResumeReuploadDialog = action.payload;
    },
    toggleEducationCreateDialog: (state, action: PayloadAction<boolean>) => {
      state.showEducationCreateDialog = action.payload;
    },
    toggleExperienceCreateDialog: (state, action: PayloadAction<boolean>) => {
      state.showExperienceCreateDialog = action.payload;
    },
    toggleAboutEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showAboutEditDialog = action.payload;
    },
    toggleContactEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showContactEditDialog = action.payload;
    },
    toggleEducationEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showEducationEditDialog = action.payload;
    },
    toggleExperienceEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showExperienceEditDialog = action.payload;
    },
    toggleLanguageEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showLanguageEditDialog = action.payload;
    },
    toggleSkillEditDialog: (state, action: PayloadAction<boolean>) => {
      state.showSkillEditDialog = action.payload;
    },
    toggleMatchDialog: (state, action: PayloadAction<MatchDialog>) => {
      state.showMatchDialog = action.payload;
    },
    toggleConfirmationDialog: (state, action: PayloadAction<ConfirmatioDialog>) => {
      state.confirmationDialog = action.payload;
    },
    toggleSubmitGPTDialog: (state, action: PayloadAction<boolean>) => {
      state.showSubtmitGPTDialog = action.payload;
    },
    toggleNoteDialog: (state, action: PayloadAction<boolean>) => {
      state.showNoteDialog = action.payload;
    },
    toggleNotesDialog: (state, action: PayloadAction<boolean>) => {
      state.showNotesDialog = action.payload;
    },
  },
});

// Actions
export const {
  toggleApplyDialog,
  toggleMatchDialog,
  toggleJobUploadDialog,
  toggleResumeUploadDialog,
  toggleResumeReuploadDialog,
  toggleEducationCreateDialog,
  toggleExperienceCreateDialog,
  toggleAboutEditDialog,
  toggleContactEditDialog,
  toggleEducationEditDialog,
  toggleExperienceEditDialog,
  toggleLanguageEditDialog,
  toggleSkillEditDialog,
  toggleConfirmationDialog,
  toggleSubmitGPTDialog,
  toggleNoteDialog,
  toggleNotesDialog,
} = dialogsSlice.actions;

// Selectors
export const showApplyDialog = (): boolean => useAppSelector((state) => state.dialogs.showApplyDialog);
export const showJobUploadDialog = (): boolean => useAppSelector((state) => state.dialogs.showJobUploadDialog);
export const showResumeUploadDialog = (): boolean => useAppSelector((state) => state.dialogs.showResumeUploadDialog);
export const showResumeReuploadDialog = (): boolean =>
  useAppSelector((state) => state.dialogs.showResumeReuploadDialog);
export const showEducationCreateDialog = (): boolean =>
  useAppSelector((state) => state.dialogs.showEducationCreateDialog);
export const showExperienceCreateDialog = (): boolean =>
  useAppSelector((state) => state.dialogs.showExperienceCreateDialog);
export const showAboutEditDialog = (): boolean => useAppSelector((state) => state.dialogs.showAboutEditDialog);
export const showContactEditDialog = (): boolean => useAppSelector((state) => state.dialogs.showContactEditDialog);
export const showEducationEditDialog = (): boolean => useAppSelector((state) => state.dialogs.showEducationEditDialog);
export const showExperienceEditDialog = (): boolean =>
  useAppSelector((state) => state.dialogs.showExperienceEditDialog);
export const showLanguageEditDialog = (): boolean => useAppSelector((state) => state.dialogs.showLanguageEditDialog);
export const showSkillEditDialog = (): boolean => useAppSelector((state) => state.dialogs.showSkillEditDialog);
export const showMatchDialog = (): MatchDialog => useAppSelector((state) => state.dialogs.showMatchDialog);
export const showConfirmationDialog = (): ConfirmatioDialog =>
  useAppSelector((state) => state.dialogs.confirmationDialog);
export const showSubmitGPTDialog = (): boolean => useAppSelector((state) => state.dialogs.showSubtmitGPTDialog);
export const showNoteDialog = (): boolean => useAppSelector((state) => state.dialogs.showNoteDialog);
export const showNotesDialog = (): boolean => useAppSelector((state) => state.dialogs.showNotesDialog);
