import React, { createContext, useState } from 'react';
import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material';

export type SnackbarContextActions = {
  showSnackbar: (content: React.ReactNode, severity: AlertColor, title?: string) => void;
};

export const SnackbarContext = createContext({} as SnackbarContextActions);

export interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string | undefined>('');
  const [content, setContent] = useState<React.ReactNode>(null);
  const [severity, setSeverity] = useState<AlertColor>('success');

  const showSnackbar = (content: React.ReactNode, severity: AlertColor, title?: string) => {
    setTitle(title);
    setContent(content);
    setSeverity(severity);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
      >
        <Alert onClose={onClose} severity={severity}>
          {title && <AlertTitle>{title}</AlertTitle>} {content}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
