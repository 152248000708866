import { useEffect } from 'react';

import { Grid, Container, Button } from '@mui/material';
import { StripePayment } from 'components/StripePayment';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  getDonateOptionsData,
  getDonateOptions,
  getSelectedDonateOption,
  setSelectedDonateOption,
} from 'state/donation/donationSlice';
import { getUserProfileSelector } from 'state/profile/profileSlice';
import { useAuth0 } from '@auth0/auth0-react';

export const Donation = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const donateOptions = useAppSelector(getDonateOptions);
  const selectedDonateOption = useAppSelector(getSelectedDonateOption);
  const userProfile = useAppSelector(getUserProfileSelector);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(getDonateOptionsData({ token }));
    });
  }, []);

  return (
    <Container
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}
      maxWidth="md"
    >
      <Grid container direction="column" rowSpacing={2}>
        <Grid container item spacing={2}>
          {donateOptions.map((option) => {
            let isSelected = false;
            if (selectedDonateOption) {
              isSelected = option.id === selectedDonateOption.id;
            }

            return (
              <Grid sm={4} xs={12} container item justifyContent="center" key={option.id}>
                <Button
                  onClick={() => dispatch(setSelectedDonateOption(option))}
                  variant={isSelected ? 'contained' : 'outlined'}
                  fullWidth
                >
                  {option.title}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid item>
          {!!donateOptions?.length && !!selectedDonateOption && (
            <StripePayment paymentTypeId={selectedDonateOption.id} email={userProfile.email} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
