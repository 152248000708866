import React, { createContext, useState, ReactNode, ReactElement } from 'react';
import { Box, IconButton, Paper, Slide, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getAppState } from 'state/app/appSlice';

export type BannerContextActions = {
  showBanner: (title: React.ReactNode, description?: React.ReactNode, button?: React.ReactNode) => void;
  closeBanner: () => void;
};

export const BannerContext = createContext<BannerContextActions | undefined>(undefined);

interface BannerProviderProps {
  children: ReactNode;
}

export const BannerProvider = ({ children }: BannerProviderProps): ReactElement => {
  const { drawerWidth } = getAppState();

  const [title, setTitle] = useState<React.ReactNode>('');
  const [description, setDescription] = useState<React.ReactNode>('');
  const [button, setButton] = useState<React.ReactNode>(null);
  const [bannerOpen, setBannerOpen] = useState(false);

  const closeBanner = () => {
    setBannerOpen(false);
  };

  const showBanner = (title: React.ReactNode, description: React.ReactNode, button?: ReactNode) => {
    setTitle(title);
    setDescription(description);
    setButton(button);
    setBannerOpen(true);
  };

  return (
    <BannerContext.Provider value={{ showBanner, closeBanner }}>
      {children}
      <Slide appear={false} in={bannerOpen}>
        <Paper
          role="dialog"
          square
          variant="outlined"
          sx={{
            position: 'fixed',
            top: 0,
            left: { xs: 0, md: drawerWidth },
            right: 0,
            p: 2,
            borderWidth: 0,
            borderBottomWidth: 1,
            zIndex: 10,
          }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={2}>
            <Box
              sx={{
                flexShrink: 1,
                alignSelf: { xs: 'flex-start', sm: 'center' },
              }}
            >
              {title && <Typography fontWeight="bold">{title}</Typography>}
              {description && <Typography variant="body2">{description}</Typography>}
            </Box>
            <Stack gap={2} direction="row" alignItems="center">
              {button}
              <IconButton onClick={closeBanner} size="small" aria-label="close">
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Paper>
      </Slide>
    </BannerContext.Provider>
  );
};
