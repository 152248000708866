import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Badge } from '@mui/material';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { getProfilePendingSelector, getUserProfileSelector } from 'state/profile/profileSlice';
import { useChat } from 'components/Chat/useChat';
import { theme } from 'theme';

interface NavigationDrawerProps {
  open: boolean;
}

export const NavigationDrawer = ({ open }: NavigationDrawerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAdmin = window.location.pathname.includes('admin');
  const userProfile = getUserProfileSelector();
  const pending = getProfilePendingSelector();
  const { totalUnreadMessages } = useChat();

  const candidatePages = [
    { label: t('pages.dashboard'), path: '/', icon: <DashboardRoundedIcon />, disabled: false },
    {
      label: t('pages.knowledge'),
      path: '/smartknowledgehub',
      icon: <AutoAwesomeRoundedIcon />,
      disabled: false,
    },
    {
      label: 'Smart Job Hub',
      path: '/smartjobhub',
      icon: <WorkRoundedIcon />,
      disabled: pending,
    },
    // { label: t('pages.people'), icon: <HandshakeRoundedIcon />, disabled: true },
    {
      label: t('pages.profile'),
      path: `/profile?profileId=${userProfile.id}`,
      icon: <PersonRoundedIcon />,
      disabled: pending,
    },
    // {
    //   label: t('pages.notification'),
    //   icon: (
    //     <Badge color="secondary" badgeContent={totalUnreadMessages}>
    //       <NotificationsOutlinedIcon />
    //     </Badge>
    //   ),
    //   disabled: true,
    // },
    {
      label: t('pages.message'),
      path: '/message',
      icon: (
        <Badge color="secondary" badgeContent={totalUnreadMessages}>
          <MailRoundedIcon />
        </Badge>
      ),
      disabled: pending,
    },
  ];

  const recruiterPages = [
    { label: t('pages.dashboard'), path: '/admin', icon: <DashboardRoundedIcon />, disabled: false },
    { label: t('pages.jobs'), path: '/admin/jobs', icon: <WorkRoundedIcon />, disabled: false },
    {
      label: t('pages.calendar'),
      path: '/admin/calendar',
      icon: <CalendarMonthRoundedIcon />,
      disabled: false,
    },
    // { label: t('jobInfo.applicant'), path: '/admin/applicant', icon: <GroupRoundedIcon />, disabled: false },
    {
      label: t('pages.organization'),
      path: '/admin/organization',
      icon: <CorporateFareRoundedIcon />,
      disabled: false,
    },

    {
      label: t('pages.message'),
      path: '/admin/message',
      icon: (
        <Badge color="secondary" badgeContent={totalUnreadMessages}>
          <MailRoundedIcon />
        </Badge>
      ),
      disabled: pending,
    },
  ];

  const pages = isAdmin ? recruiterPages : candidatePages;

  return (
    <List sx={{ pl: open ? 2 : 1 }}>
      {pages.map((item) => {
        return (
          <ListItem key={item.label} sx={{ p: 0.5, pr: 0 }}>
            <ListItemButton
              sx={{
                p: 1.5,
                pr: 0,
                color:
                  window.location.pathname === item.path
                    ? theme.palette.primary.main
                    : isAdmin
                    ? 'white'
                    : theme.palette.grey[600],
                borderRight:
                  window.location.pathname === item.path
                    ? isAdmin
                      ? '3px solid white'
                      : `3px solid ${theme.palette.primary.main}`
                    : 'inherit',
                '&:hover': {
                  backgroundColor: 'inherit',
                  borderRight: isAdmin ? '3px solid white' : `3px solid ${theme.palette.primary.main}`,
                },
              }}
              onClick={() => item.path && navigate(item.path)}
              disabled={item.disabled}
              disableRipple
            >
              <ListItemIcon
                sx={{
                  minWidth: 'fit-content',
                  mr: 1.5,
                  color:
                    window.location.pathname === item.path
                      ? isAdmin
                        ? 'white'
                        : theme.palette.primary.main
                      : 'inherit',
                }}
              >
                {item.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: 0,
                    color: isAdmin ? 'white' : 'inherit',
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
