/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribe = /* GraphQL */ `
  subscription Subscribe($recipientId: String) {
    subscribe(recipientId: $recipientId) {
      conversationId
      messageId
      senderId
      recipientId
      createdAt
      content
      messageType
      isRead
      metadata
      actionType
      __typename
    }
  }
`;
