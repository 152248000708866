import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { JobChart } from './Section/JobChart';
import { JobRow, Section } from 'components';
import { Calendar, Celebrations, Notes, Training } from './Section';
import { getAllJobsByIdData, getJobShortList } from 'state/job/jobSlice';
import { useAppDispatch } from 'state/hooks';
import { getUserOrgSelector } from 'state/organization/organizationSlice';
import { JobShort } from 'types/jobType';
import { getApiURL } from 'utils';

export const Admin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const jobShortList = getJobShortList();
  const userOrg = getUserOrgSelector();

  const [memberLength, setMemberLength] = useState<number>(1);

  const fetchOrganizationAccount = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      getApiURL(`/OrganizationAccount/GetOrganizationAccountByOrganizationRequest`, {
        PageNumber: 1,
        PageSize: 5,
        ShowInactive: false,
      }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then((res) => res.json());

    setMemberLength(response.count);
  };

  useEffect(() => {
    fetchOrganizationAccount();

    getAccessTokenSilently().then((token) =>
      dispatch(
        getAllJobsByIdData({
          token,
          organizationId: userOrg?.organizationId || '',
          pageNumber: 1,
          pageSize: 4,
        })
      )
    );
  }, [userOrg.organizationId]);

  const quotes = {
    text: '“I am convinced that nothing we do is more important than hiring and developing people. At the end of the day, you bet on people, not on strategies.”',
    by: 'Lawrence Bossidy',
  };

  return (
    <Container sx={{ py: 3, minHeight: '100vh' }} maxWidth="xl">
      <Grid container spacing={3}>
        {/* {[
          { title: 'Company', icon: <BusinessOutlinedIcon />, content: <Company /> },
          { title: 'Calendar', icon: <CalendarMonthOutlinedIcon />, content: <Calendar /> },
          { title: 'Notes', icon: <NotesOutlinedIcon />, content: <Notes /> },
          { title: 'Status', icon: <PendingActionsOutlinedIcon />, content: <Absence /> },
          { title: 'Trainings', icon: <SchoolOutlinedIcon />, content: <Training /> },
          { title: 'Celebrations', icon: <CelebrationOutlinedIcon />, content: <Celebrations /> },
        ].map(
          (item) =>
            // <Grid key={item.title} item xs={12} sm={6} md={4}>
            item.content
            // </Grid>
        )} */}
        <Grid item xs={12} sm={6} md={3}>
          <Section sx={{ height: '150px', justifyContent: 'center' }}>
            <Typography fontSize={32}>10</Typography>
            <Typography fontSize={18}>{t('admin.totalApplicant')}</Typography>
          </Section>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Section sx={{ height: '150px', justifyContent: 'center' }}>
            <Typography fontSize={32}>{memberLength}</Typography>
            <Typography fontSize={18}>{t('admin.teamMember')}</Typography>
          </Section>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Section sx={{ height: '150px', justifyContent: 'center' }}>
            <Typography fontSize={32}>{jobShortList.count}</Typography>
            <Typography fontSize={18}>{t('admin.jobPosition')}</Typography>
          </Section>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Section sx={{ height: '150px', justifyContent: 'center' }}>
            <Typography>{quotes.text}</Typography>
            <Typography textAlign="end">- {quotes.by}</Typography>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <JobChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '150px', justifyContent: 'center' }}>
            {jobShortList.results.map((data: JobShort, index: number) => (
              <JobRow key={index} data={data} showActions={false} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Notes />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Calendar />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Training />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Section sx={{ height: '275px', justifyContent: 'center' }}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 10 },
                    { id: 1, value: 15 },
                    { id: 2, value: 20 },
                  ],
                },
              ]}
              width={400}
              height={200}
            />
          </Section>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Celebrations />
        </Grid>
      </Grid>
    </Container>
  );
};
