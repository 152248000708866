import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Section } from 'components/Section';
import { ExperienceEditDialog } from 'components/Dialog/ProfileEditDialog';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAppDispatch } from 'state/hooks';
import { getVisitProfileSelector } from 'state/profile/profileSlice';
import { toggleExperienceEditDialog } from 'state/dialogs/dialogsSlice';
import { getApiURL } from 'utils';
import { Experience } from 'types/profileType';

interface ExperiencesProps {
  editable: boolean;
}

export const Experiences = ({ editable }: ExperiencesProps) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const onOpen = () => dispatch(toggleExperienceEditDialog(true));
  const visitProfile = getVisitProfileSelector();

  const [isEditable, setIsEditable] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState<Experience>({});

  const deleteExperience = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(getApiURL(`/UserProfile/DeleteUserExperienceRequest/${id}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  return (
    <Section
      title={t('profile.experience.title')}
      onAdd={onOpen}
      edit={isEditable}
      onEdit={() => visitProfile.experiences && setIsEditable(!isEditable)}
      editable={editable}
      marginBottom={3}
    >
      <Stack spacing={1.5} divider={<Divider />}>
        {visitProfile.experiences?.map((experience: Experience) => (
          <Grid
            key={experience.id}
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={10}>
              <Typography fontWeight={600}>{experience.role}</Typography>
              <Typography>{experience.companyName}</Typography>
              <Typography sx={{ wordWrap: 'break-word' }}>{experience.taskDescription}</Typography>
              <Typography>{experience.location}</Typography>
              <Stack flexDirection="row" gap={0.5}>
                {experience.dateStart && <Typography>{moment(experience.dateStart).format('MMM YYYY')} </Typography>}
                {(experience.dateStart && experience.dateEnd) || (experience.dateStart && experience.isPresent)
                  ? '-'
                  : null}
                {experience.dateEnd && <Typography>{moment(experience.dateEnd).format('MMM YYYY')}</Typography>}
                {experience.isPresent && <Typography>Present</Typography>}
              </Stack>
            </Grid>
            {isEditable && (
              <Grid item xs={1} display="flex" justifyContent="end">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedExperience(experience);
                    onOpen();
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="error" onClick={() => deleteExperience(experience.id as string)}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Stack>
      <ExperienceEditDialog experience={selectedExperience} setExperience={setSelectedExperience} />
    </Section>
  );
};
